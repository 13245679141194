import { createContext } from 'react';

export interface IAuth {
	Authenticated: boolean;
	Token: string;
	Selected: string;
	user: any;
}
interface IContext {
	storeData: IAuth;
	setStoreData: (val: IAuth) => void;
}

export const LS_KEY_AUTH = 'IAuth';

export const initializeData = (): IAuth => {
	const storedAuth = localStorage.getItem(LS_KEY_AUTH);
	if (!storedAuth) {
		const init: IAuth = {
			Authenticated: false,
			Selected: 'approve_band',
			Token: '',
			user: {}
		};
		return init;
	}
	return JSON.parse(storedAuth);
};

export const StateContext = createContext<IContext>({
	setStoreData: val => undefined,
	storeData: {
		Authenticated: false,
		Selected: 'approve_band',
		Token: '',
		user: {}
	}
});

export const storeState = (state: IAuth) => {
	localStorage.setItem(LS_KEY_AUTH, JSON.stringify(state));
};

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

interface IDeleteDialogProps {
	open: boolean;
	id: string;
	message?: string;
	onClose: (action: string, id: string) => void;
}

const DeleteDialog = (props: IDeleteDialogProps) => {
	const { onClose, open, id, message } = props;

	const handleClose = (action: string) => {
		onClose(action, id);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{message ? message : 'Are you sure you want to continue?'}</DialogTitle>
			<DialogActions>
				<Button onClick={() => handleClose('delete')} color="primary">
					Ok
				</Button>
				<Button onClick={() => handleClose('')} color="primary" autoFocus>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteDialog;

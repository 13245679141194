import * as firebase from 'firebase';
import { config } from './firebase_config';

let messaging: firebase.messaging.Messaging;

try {
	firebase.initializeApp(config);
	messaging = firebase.messaging();
	const key = process.env.REACT_APP_PublicVapidKey;
	messaging.usePublicVapidKey(key ? key : '');
} catch (error) {}

export { messaging, firebase };

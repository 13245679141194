import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import SimpleDialog from '../basic/dialoges/simpleDialog';
import Table from '../basic/tables/table';
import { StateContext } from '../generalFunctions/context';
import { setHeaders } from '../generalFunctions/requestheaders';

const BENEFIT_MAX_LENGTH = 255;

const TalentBenefits: React.FC = () => {
	const { storeData } = useContext(StateContext);
	const [listall, setListall] = useState(false);
	const [operation, setOperation] = useState(false);
	const [buttonLabel, setButtonLabel] = useState('create');
	const [values, setValues] = useState({ id: '', body: '' });
	const [error, setError] = useState({ error: false, message: '', disable: true });
	const [submitrequest, setSubmitRequest] = useState('post');
	const [open, setOpen] = React.useState(false);
	const [message, setMessage] = React.useState('Done');
	const [data, setData] = useState([{ id: '0', body: '' }]);
	const [totalCount, setTotalCount] = useState(0);
	const [tableContent, setTableContent] = useState([{ id: '', data: '' }]);
	const [page, setPage] = useState(1);

	useEffect(() => {
		axios
			.post(
				process.env.REACT_APP_API_URL + 'benefits/list?role=talent',
				{ page },
				{
					headers: setHeaders(storeData.Token)
				}
			)
			.then(response => {
				if (response.data.items.length === 0 && page > 1) {
					setPage(prevState => prevState - 1);
				}
				setData(response.data.items);
				const tabledata = response.data.items.map((item: any) => {
					return { id: item.id, data: item.body.slice(0, 100) };
				});
				setTableContent(tabledata);
				setTotalCount(response.data.total_items);
			})
			.catch(e => {
				setMessage("Something went wrong. can't get the data");
				handleClickOpen();
			});
	}, [listall, page, storeData.Token]);

	const handladd = () => {
		setOperation(true);
		setButtonLabel('create');
		setSubmitRequest('post');
		setTimeout(() => {
			window.scrollTo({
				behavior: 'smooth',
				top: 10000
			});
		}, 1);
	};
	const handleEdite = (id: string) => {
		setOperation(true);
		setButtonLabel('edit');
		const index = data.findIndex(item => item.id === id);
		const newval = {
			body: data[index].body,
			id: data[index].id
		};
		setValues(newval);
		setSubmitRequest('put');
		setError({ ...error, disable: false });
		setTimeout(() => {
			window.scrollTo({
				behavior: 'smooth',
				top: 10000
			});
		}, 1);
	};
	const handleChange = (value: string) => {
		const newvals = { ...values, body: value };
		setValues(newvals);
		let newerror = { ...error, error: false, message: '', disable: false };
		setError(newerror);
		if (value.length > BENEFIT_MAX_LENGTH) {
			newerror = {
				...newerror,
				disable: true,
				error: true,
				message: `value is too long (can't be more than ${BENEFIT_MAX_LENGTH} characters)`
			};
			setError(newerror);
		} else if (value.trim().length === 0) {
			newerror = {
				...newerror,
				disable: true,
				error: true,
				message: 'required'
			};
			setError(newerror);
		}
	};
	const handldelete = (id: string) => {
		axios
			.delete(process.env.REACT_APP_API_URL + `benefits/${id}`, {
				headers: setHeaders(storeData.Token)
			})
			.then(response => {
				setListall(!listall);
			})
			.catch(e => {
				setMessage("That document doesn't exist ");
				handleClickOpen();
			});
	};
	const handleClick = () => {
		const requestData = {
			body: values.body,
			role: 'TALENT'
		};
		if (submitrequest === 'post') {
			axios
				.post(process.env.REACT_APP_API_URL + `benefits`, requestData, {
					headers: setHeaders(storeData.Token)
				})
				.then(response => {
					setListall(!listall);
				})
				.catch(e => {
					setMessage("Something went wrong. can't add new document");
					handleClickOpen();
				});
		} else {
			axios
				.put(process.env.REACT_APP_API_URL + `benefits/${values.id}`, requestData, {
					headers: setHeaders(storeData.Token)
				})
				.then(response => {
					setListall(!listall);
				})
				.catch(e => {
					setMessage('Something went wrong.');
					handleClickOpen();
				});
		}
		setOperation(false);
		setValues({ body: '', id: '' });
		setTimeout(() => {
			window.scrollTo({
				behavior: 'smooth',
				top: 0
			});
		}, 100);
	};
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const handleCancel = () => {
		setOperation(false);
		setValues({ body: '', id: '' });
		setTimeout(() => {
			window.scrollTo({
				behavior: 'smooth',
				top: 0
			});
		}, 100);
		setError({ error: false, message: '', disable: true });
	};
	return (
		<Box>
			<Typography align="center" variant="h4">
				Talent Benefits
			</Typography>
			<Box display="flex" justifyContent="flex-end">
				<Button color="secondary" onClick={handladd}>
					<AddCircleIcon fontSize="large" />
				</Button>
			</Box>
			<Table
				title="Talent Benefits"
				page={page - 1}
				rows={tableContent}
				handleDelete={handldelete}
				handleEdit={handleEdite}
				total={totalCount}
				pagedata={setPage}
			/>
			{operation ? (
				<>
					<Divider />
					<Box display={'flex'} flexDirection={'column'} alignItems={'center'} paddingTop={'10px'} paddingBottom="5px">
						<TextField
							id="Body"
							multiline
							label="Body"
							variant="outlined"
							placeholder="Body"
							rows="2"
							error={error.error}
							helperText={error.message}
							value={values.body}
							onChange={e => handleChange(e.target.value)}
							fullWidth
							required
						/>
						<Box display="flex">
							<Button variant="contained" color="primary" disabled={error.disable} onClick={handleClick}>
								{buttonLabel}
							</Button>
							<Button variant="contained" onClick={handleCancel}>
								cancel
							</Button>
						</Box>
					</Box>
				</>
			) : (
				<></>
			)}
			<SimpleDialog open={open} onClose={handleClose} message={message} />
		</Box>
	);
};

export default TalentBenefits;

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { StateContext } from '../generalFunctions/context';
import { setHeaders } from '../generalFunctions/requestheaders';
import Dialoge from '../basic/dialoges/simpleDialog';

export default function Page() {
	const history = useHistory();
	const { storeData } = useContext(StateContext);
	const [data, setData] = useState({
		response: '',
		amount: '',
		description: '',
		method: { type: '', brand: '', bank_name: '', username: '', account_type: '', exp_month: '', exp_year: '' },
		request: {
			created_by: { fname: '', lname: '' },
			band: { name: '', rating: '', price_to: '', price_from: '' },
			event: { title: '', description: '', location: '', date: '', start_time: '' }
		}
	});
	const [dialogOpen, setDialogOpen] = useState(false);
	const [message, setMessage] = React.useState('');
	const { id } = useParams();

	useEffect(() => {
		axios
			.get(process.env.REACT_APP_API_URL + `payment/show/${id}`, {
				headers: setHeaders(storeData.Token)
			})
			.then(response => {
				setData(response.data.item);
			})
			.catch((e: any) => {
				setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
				setDialogOpen(true);
			});
	}, [id, storeData.Token]);

	const handleBackButtonClick = () => {
		history.push('/payments');
	};
	const response = data ? data.response.split(' ') : '';

	return (
		<Box paddingLeft="10px">
			<Box>
				<IconButton onClick={handleBackButtonClick} aria-label="previous page">
					<KeyboardArrowLeft />
				</IconButton>
			</Box>
			{data ? (
				<Box paddingLeft="20px">
					<Typography variant="h5">
						Amount:
						<Typography paragraph>${data.amount}</Typography>
					</Typography>
					<Typography variant="h5">
						Method:
						<Box paddingLeft="10px">
							<Typography paragraph>Type: {data.method.type}</Typography>
							<Typography paragraph>User Name: {data.method.username}</Typography>
							<Typography paragraph>Brand: {data.method.brand}</Typography>
							<Typography paragraph>
								Exp-Date: {data.method.exp_year} - {data.method.exp_month}
							</Typography>
							<Typography paragraph>Bank Name: {data.method.bank_name}</Typography>
							<Typography paragraph>Account Type: {data.method.account_type}</Typography>
						</Box>
					</Typography>
					<Typography variant="h5">
						Response:
						<Typography paragraph>
							<a href={response[3]} target="_blank" rel="noopener noreferrer">
								{response[0]}
							</a>
						</Typography>
					</Typography>
					<Typography variant="h5">
						Description:
						<Typography paragraph>{data.description}</Typography>
					</Typography>
					<Typography variant="h5">
						Booker:
						<Typography paragraph>{data.request.created_by.fname + ' ' + data.request.created_by.lname}</Typography>
					</Typography>
					<Typography variant="h5">
						Talent:
						<Box paddingLeft="10px">
							<Typography paragraph>Name: {data.request.band.name}</Typography>
							<Typography paragraph>Rating: {data.request.band.rating}</Typography>
							<Typography paragraph>
								Price: ${data.request.band.price_from} - ${data.request.band.price_to}
							</Typography>
						</Box>
					</Typography>
					<Typography variant="h5">
						Event:
						<Box paddingLeft="10px">
							<Typography paragraph>Title: {data.request.event.title}</Typography>
							<Typography paragraph>Description: {data.request.event.description}</Typography>
							<Typography paragraph>Location: {data.request.event.location}</Typography>
							<Typography paragraph>Date: {data.request.event.date}</Typography>
							<Typography paragraph>Time: {data.request.event.start_time}</Typography>
						</Box>
					</Typography>
				</Box>
			) : (
				<> </>
			)}
			<Dialoge message={message} onClose={() => setDialogOpen(false)} open={dialogOpen} />
		</Box>
	);
}

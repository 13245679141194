import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import SimpleDialog from '../basic/dialoges/simpleDialog';
import Table from '../basic/tables/table';
import { StateContext } from '../generalFunctions/context';
import { setHeaders } from '../generalFunctions/requestheaders';
export const useStyles = makeStyles(theme => ({
	menu: {
		backgroundColor: 'unset',
		color: 'black'
	}
}));
const NAME_MAX_LENGTH = 100;
const DESCRIPTION_MAX_LENGTH = 1000;

const ManageTestimonials: React.FC = () => {
	const { storeData } = useContext(StateContext);
	const [addDisable, setAddDisable] = useState(false);
	const [operation, setOperation] = useState(false);
	const [open, setOpen] = React.useState(false);
	const [message, setMessage] = React.useState('Done');
	const [buttonLabel, setButtonLabel] = useState('create');
	const [submitrequest, setSubmitRequest] = useState('post');
	const [listall, setListall] = useState(false);
	const [data, setData] = useState([{ id: '0', name: '', description: '', status: true }]);
	const [totalCount, setTotalCount] = useState(0);
	const [tableContent, setTableContent] = useState([{ id: '', data: '' }]);
	const [page, setPage] = useState(1);
	const [error, setError] = useState({
		name: {
			disable: true,
			error: false,
			message: ''
		},
		description: {
			disable: true,
			error: false,
			message: ''
		}
	});
	const [values, setValues] = useState({
		id: '0',
		name: '',
		description: '',
		status: 1
	});
	const classes = useStyles();
	useEffect(() => {
		axios
			.get(
				process.env.REACT_APP_API_URL + `testimonial?page=${page}`,
				// { page },
				{
					headers: setHeaders(storeData.Token)
				}
			)
			.then(response => {
				setData(response.data.items);
				// if (response.data.items.length >= 3) {
				// 	setAddDisable(true);
				// }
				const tabledata = response.data.items.map((item: any) => {
					return { id: item.id, data: item.name };
				});
				setTableContent(tabledata);
				setTotalCount(response.data.total_items);
			})
			.catch(e => {
				setMessage('Something went wrong.');
				setOpen(true);
			});
	}, [listall, page, storeData.Token]);

	const handladd = () => {
		setOperation(true);
		setButtonLabel('create');
		setSubmitRequest('post');
	};

	const handleEdite = (id: string) => {
		setOperation(true);
		setButtonLabel('edit');
		const index = data.findIndex(item => item.id === id);
		const newval = {
			id: data[index].id,
			name: data[index].name,
			description: data[index].description,
			status: Number(data[index].status)
		};
		setValues(newval);
		const newerror = {
			name: {
				disable: false,
				error: false,
				message: ''
			},
			description: {
				disable: false,
				error: false,
				message: ''
			}
		};
		setError(newerror);
		setSubmitRequest('put');
	};

	const handleChange = (id: string, value: string) => {
		let newvalues = { ...values };
		let newerror = { ...error };
		switch (id) {
			case 'name':
				newvalues = { ...newvalues, name: value };
				setValues(newvalues);
				newerror = { ...newerror, name: { error: false, message: '', disable: false } };
				setError(newerror);
				if (value.length > NAME_MAX_LENGTH) {
					newerror = {
						...newerror,
						name: {
							disable: true,
							error: true,
							message: `value is too long (can't be more than ${NAME_MAX_LENGTH} characters)`
						}
					};
					setError(newerror);
				} else if (value.trim().length === 0) {
					newerror = {
						...newerror,
						description: { error: true, message: 'required', disable: true }
					};
					setError(newerror);
				}
				break;
			case 'description':
				newvalues = { ...newvalues, description: value };
				setValues(newvalues);
				newerror = { ...newerror, description: { error: false, message: '', disable: false } };
				setError(newerror);
				if (value.length > DESCRIPTION_MAX_LENGTH) {
					newerror = {
						...newerror,
						description: {
							disable: true,
							error: true,
							message: `value is too long (can't be more than ${DESCRIPTION_MAX_LENGTH} characters)`
						}
					};
					setError(newerror);
				} else if (value.trim().length === 0) {
					newerror = {
						...newerror,
						description: { error: true, message: 'required', disable: true }
					};
					setError(newerror);
				}
				break;
		}
	};

	const handldelete = (id: string) => {
		axios
			.delete(process.env.REACT_APP_API_URL + `testimonial/${id}`, {
				headers: setHeaders(storeData.Token)
			})
			.then(response => {
				setListall(!listall);
				setAddDisable(false);
			})
			.catch(e => {
				setMessage('Something went wrong.');
				setOpen(true);
			});
	};

	const handleClick = () => {
		const requestData = {
			name: values.name,
			description: values.description,
			status: values.status
		};
		if (submitrequest === 'post') {
			axios
				.post(process.env.REACT_APP_API_URL + `testimonial`, requestData, {
					headers: setHeaders(storeData.Token)
				})
				.then(response => {
					setListall(!listall);
				})
				.catch(e => {
					setMessage('Something went wrong.');
					setOpen(true);
				});
		} else {
			axios
				.put(process.env.REACT_APP_API_URL + `testimonial/${values.id}`, requestData, {
					headers: setHeaders(storeData.Token)
				})
				.then(response => {
					setListall(!listall);
				})
				.catch(e => {
					setMessage('Something went wrong.');
					setOpen(true);
				});
		}
		setOperation(false);
		setValues({ name: '', description: '', status: 0, id: '' });
		setTimeout(() => {
			window.scrollTo({
				behavior: 'smooth',
				top: 0
			});
		}, 100);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCancel = () => {
		setOperation(false);
		setValues({ name: '', description: '', status: 0, id: '' });
		setTimeout(() => {
			window.scrollTo({
				behavior: 'smooth',
				top: 0
			});
		}, 100);
		const newerror = {
			name: {
				disable: true,
				error: false,
				message: ''
			},
			description: {
				disable: true,
				error: false,
				message: ''
			}
		};
		setError(newerror);
	};

	return (
		<Box>
			<Typography align="center" variant="h4">
				Testimonials
			</Typography>
			<Box display="flex" justifyContent="flex-end">
				<Button disabled={addDisable} color="secondary" onClick={handladd}>
					<AddCircleIcon fontSize="large" />
				</Button>
			</Box>
			<Table
				title="Name"
				page={page - 1}
				rows={tableContent}
				handleDelete={handldelete}
				handleEdit={handleEdite}
				total={totalCount}
				pagedata={setPage}
			/>
			{operation ? (
				<>
					<Divider />
					<Box display={'flex'} flexDirection={'column'} alignItems={'center'} paddingTop={'10px'} paddingLeft={'10px'}>
						<TextField
							id="name"
							multiline
							label="name"
							variant="outlined"
							placeholder="name"
							rows="2"
							error={error.name.error}
							helperText={error.name.message}
							value={values.name}
							onChange={e => handleChange('name', e.target.value)}
							fullWidth
							required
						/>
						<TextField
							id="description"
							multiline
							label="description"
							variant="outlined"
							placeholder="description"
							rows="2"
							error={error.description.error}
							helperText={error.description.message}
							value={values.description}
							onChange={e => handleChange('description', e.target.value)}
							fullWidth
							required
						/>
						<InputLabel htmlFor="status">Is Active *</InputLabel>
						<Select
							value={values.status}
							fullWidth={true}
							variant="outlined"
							MenuProps={{
								anchorOrigin: {
									horizontal: 'left',
									vertical: 'bottom'
								},
								getContentAnchorEl: null,
								transformOrigin: {
									horizontal: 'left',
									vertical: 'top'
								}
							}}
							onChange={e => setValues({ ...values, status: Number(e.target.value) })}
						>
							<MenuItem className={classes.menu} key="1" value={1}>
								True
							</MenuItem>
							<MenuItem className={classes.menu} key="2" value={0}>
								False
							</MenuItem>
						</Select>
						<Box display="flex">
							<Button
								variant="contained"
								color="primary"
								disabled={error.name.disable || error.description.disable}
								onClick={handleClick}
							>
								{buttonLabel}
							</Button>
							<Button variant="contained" onClick={handleCancel}>
								cancel
							</Button>
						</Box>
					</Box>
				</>
			) : (
				<></>
			)}
			<SimpleDialog open={open} onClose={handleClose} message={message} />
		</Box>
	);
};

export default ManageTestimonials;

import { Grid, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SimpleDialog from '../../basic/dialoges/simpleDialog';
import Profile from '../../basic/tables/profileTable';
import { StateContext } from '../../generalFunctions/context';
import { setHeaders } from '../../generalFunctions/requestheaders';

const Band: React.FC = () => {
	const { storeData } = useContext(StateContext);
	const [listall, setListall] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [tableContent, setTableContent] = useState([{ id: '', data: '', status: '' }]);
	const [page, setPage] = useState(1);
	const [open, setOpen] = useState(false);
	const [pending, setPending] = useState(false);
	const [message, setMessage] = useState('');
	const [search_keyword, setSearchKeyword] = useState('');
	const history = useHistory();
	const [timeoutData, setTimeoutData] = useState(0); //timeout data

	const timeOut = useRef<any>();

	useEffect(() => {
		getBookerList();
	}, [listall, page, pending, storeData.Token]);

	useEffect(() => {
		timeOut.current = setTimeout(() => {
			//search function
			getBookerList();
		}, 400);
		return () => clearTimeout(timeOut.current);
	}, [search_keyword]);

	const getBookerList = () => {
		axios
			.post(
				process.env.REACT_APP_API_URL + 'pending-users/details',
				{ page, pending, search_keyword },
				{
					headers: setHeaders(storeData.Token)
				}
			)
			.then(response => {
				if (response.data.items.length === 0 && page > 1) {
					setPage(prevState => prevState - 1);
				}
				const data = response.data;
				setTotalCount(data.total_items);
				const tabledata = data.items.map((item: any) => {
					return {
						id: item.id,
						data: `${item.fname} ${item.lname}`,
						email: item.email,
						created_at: item.created_at + '000',
						status: item.status
					};
				});
				setTableContent(tabledata);
			})
			.catch((e: any) => {
				setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
				setOpen(true);
			});
	};

	const handleApprove = (id: string) => {
		axios
			.post(
				process.env.REACT_APP_API_URL + `user/update-status/${id}`,
				{ status: 'approved' },
				{
					headers: setHeaders(storeData.Token)
				}
			)
			.then(response => {
				setListall(!listall);
			})
			.catch((e: any) => {
				setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
				setOpen(true);
			});
	};

	const handleDecline = (id: string, reasons: string) => {
		axios
			.post(
				process.env.REACT_APP_API_URL + `user/update-status/${id}`,
				{
					reason: reasons,
					status: 'declined'
				},
				{
					headers: setHeaders(storeData.Token)
				}
			)
			.then(response => {
				setListall(!listall);
			})
			.catch((e: any) => {
				setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
				setOpen(true);
			});
	};

	const handleView = (id: string) => {
		history.push({
			pathname: history.location.pathname + `/${id}`
		});
	};

	return (
		<Box>
			<Typography align="center" variant="h4">
				Bookers
			</Typography>
			<FormControlLabel
				control={
					<Checkbox
						checked={pending}
						onChange={() => setPending(prevstate => !prevstate)}
						name="pending"
						color="primary"
					/>
				}
				label="List Pending Approvals Only."
			/>

			<Grid container spacing={1} justify="flex-end">
				<Grid item xs={12} md={12} lg={12}>
					<TextField
						autoComplete="off"
						id="search"
						name="search"
						variant="outlined"
						color="secondary"
						fullWidth={true}
						placeholder="Search"
						value={search_keyword}
						onChange={(e: any) => {
							setSearchKeyword(e.target.value);
						}}
					/>
					{/* <Box display="flex" justifyContent="flex-end" paddingRight="50px"> */}
					{/* <Button variant="contained" color="secondary" onClick={getBookerList}>
						Search
					</Button> */}
					{/* </Box> */}
				</Grid>
			</Grid>

			{tableContent ? (
				<Profile
					role="BOOKER"
					title="Name"
					page={page - 1}
					rows={tableContent}
					total={totalCount}
					pagedata={setPage}
					handleApprove={handleApprove}
					handleDecline={handleDecline}
					handleView={handleView}
				/>
			) : (
				<> </>
			)}
			<SimpleDialog open={open} onClose={() => setOpen(false)} message={message} />
		</Box>
	);
};

export default Band;

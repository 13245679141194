import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { useStyles } from './notificationTable';

export interface ITable {
	rows: Array<{ id: string; data: string }>;
	title: string;
	handleEdit: (id: string) => void;
}

export default function CustomizedTables(props: ITable) {
	const classes = useStyles();

	const { title, rows, handleEdit } = props;

	return (
		<Box marginTop="10px" width="100%">
			<Table aria-label="customized table">
				<TableHead>
					<TableRow>
						<TableCell align="left">{title}</TableCell>
						<TableCell align="right"></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map(row => (
						<TableRow key={row.id}>
							<TableCell align="left" component="th" scope="row">
								{row.data}
							</TableCell>
							<TableCell align="right">
								<Button
									className={classes.controls}
									variant="contained"
									color="secondary"
									onClick={() => handleEdit(row.id)}
								>
									Edit
								</Button>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Box>
	);
}

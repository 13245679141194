import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import SimpleDialog from '../basic/dialoges/simpleDialog';
import { StateContext } from '../generalFunctions/context';
import { setHeaders } from '../generalFunctions/requestheaders';

const TITLE_MAX_LENGTH = 30;
const SUBTITLE_MAX_LENGTH = 1000;

const HowITWorks: React.FC = () => {
	const { storeData } = useContext(StateContext);
	const [error, setError] = useState({
		bookerSubtitle: {
			error: false,
			message: ''
		},
		bookerTitle: {
			error: false,
			message: ''
		},
		disable: false,
		talentSubtitle: {
			error: false,
			message: ''
		},
		talentTitle: {
			error: false,
			message: ''
		}
	});
	const [bookerValues, setBookerValues] = useState({
		id: 0,
		subtitle: '',
		title: ''
	});
	const [talentValues, setTalentValues] = useState({
		id: 0,
		subtitle: '',
		title: ''
	});
	const [open, setOpen] = React.useState(false);
	const [message, setMessage] = React.useState('Done');
	const [bookerRequest, setBookerRequest] = useState('post');
	const [talentRequest, setTalentRequest] = useState('post');

	useEffect(() => {
		axios
			.post(process.env.REACT_APP_API_URL + 'how-it-works/list', {
				headers: setHeaders(storeData.Token)
			})
			.then(response => {
				response.data.items.map((item: any) => {
					if (item.role === 'BOOKER') {
						setBookerValues({
							id: item.id,
							subtitle: item.subtitle ? item.body : '',
							title: item.title ? item.title : ''
						});
						setBookerRequest('put');
					} else if (item.role === 'TALENT') {
						setTalentValues({
							id: item.id,
							subtitle: item.subtitle ? item.body : '',
							title: item.title ? item.title : ''
						});
						setTalentRequest('put');
					}
					return 0;
				});
			})
			.catch(e => {
				setOpen(true);
				setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
			});
	}, [storeData.Token]);

	const handleChange = (id: string, value: string) => {
		let newvalues;
		let newerror = { ...error };
		switch (id) {
			case 'booker title':
				newvalues = { ...bookerValues, title: value };
				setBookerValues(newvalues);
				newerror = { ...newerror, bookerTitle: { error: false, message: '' }, disable: false };
				setError(newerror);
				if (value.length > TITLE_MAX_LENGTH) {
					newerror = {
						...newerror,
						bookerTitle: {
							error: true,
							message: `value is too long (can't be more than ${TITLE_MAX_LENGTH} characters)`
						},
						disable: true
					};
					setError(newerror);
				}
				break;
			case 'booker subtitle':
				newvalues = { ...bookerValues, subtitle: value };
				setBookerValues(newvalues);
				newerror = { ...newerror, bookerSubtitle: { error: false, message: '' }, disable: false };
				setError(newerror);
				if (value.length > SUBTITLE_MAX_LENGTH) {
					newerror = {
						...newerror,
						bookerSubtitle: {
							error: true,
							message: `value is too long (can't be more than ${SUBTITLE_MAX_LENGTH} characters)`
						},
						disable: true
					};
					setError(newerror);
				}
				break;
			case 'talent title':
				newvalues = { ...talentValues, title: value };
				setTalentValues(newvalues);
				newerror = { ...newerror, talentTitle: { error: false, message: '' }, disable: false };
				setError(newerror);
				if (value.length > TITLE_MAX_LENGTH) {
					newerror = {
						...newerror,
						disable: true,
						talentTitle: {
							error: true,
							message: `value is too long (can't be more than ${TITLE_MAX_LENGTH} characters)`
						}
					};
					setError(newerror);
				}
				break;
			case 'talent subtitle':
				newvalues = { ...talentValues, subtitle: value };
				setTalentValues(newvalues);
				newerror = { ...newerror, talentSubtitle: { error: false, message: '' }, disable: false };
				setError(newerror);
				if (value.length > SUBTITLE_MAX_LENGTH) {
					newerror = {
						...newerror,
						disable: true,
						talentSubtitle: {
							error: true,
							message: `value is too long (can't be more than ${SUBTITLE_MAX_LENGTH} characters)`
						}
					};
					setError(newerror);
				}
				break;
		}
	};

	const handleClickBooker = () => {
		if (bookerRequest === 'post') {
			axios
				.post(
					process.env.REACT_APP_API_URL + 'how-it-works',
					{
						body: bookerValues.subtitle,
						page_name: '',
						role: 'BOOKER',
						subtitle: '',
						title: bookerValues.title
					},
					{
						headers: setHeaders(storeData.Token)
					}
				)
				.then(response => {
					setOpen(true);
					setMessage(response.data.user_message);
				})
				.catch(e => {
					setOpen(true);
					setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
				});
		} else {
			axios
				.put(
					process.env.REACT_APP_API_URL + `how-it-works/${bookerValues.id}`,
					{
						body: bookerValues.subtitle,
						page_name: '',
						role: 'BOOKER',
						subtitle: '',
						title: bookerValues.title
					},
					{
						headers: setHeaders(storeData.Token)
					}
				)
				.then(response => {
					setOpen(true);
					setMessage(response.data.user_message);
				})
				.catch(e => {
					setOpen(true);
					setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
				});
		}
	};

	const handleClickTalent = () => {
		if (talentRequest === 'post') {
			axios
				.post(
					process.env.REACT_APP_API_URL + 'how-it-works',
					{
						body: talentValues.subtitle,
						page_name: '',
						role: 'TALENT',
						subtitle: '',
						title: talentValues.title
					},
					{
						headers: setHeaders(storeData.Token)
					}
				)
				.then(response => {
					setOpen(true);
					setMessage(response.data.user_message);
				})
				.catch(e => {
					setOpen(true);
					setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
				});
		} else {
			axios
				.put(
					process.env.REACT_APP_API_URL + `how-it-works/${talentValues.id}`,
					{
						body: talentValues.subtitle,
						page_name: '',
						role: 'TALENT',
						subtitle: '',
						title: talentValues.title
					},
					{
						headers: setHeaders(storeData.Token)
					}
				)
				.then(response => {
					setOpen(true);
					setMessage(response.data.user_message);
				})
				.catch(e => {
					setOpen(true);
					setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
				});
		}
	};

	return (
		<Grid container direction="column">
			<Grid item>
				<Grid container>
					<InputLabel style={{ marginBottom: '10px', color: 'black' }} htmlFor="university">
						Booker
					</InputLabel>
					<TextField
						id="page title"
						label="Title"
						variant="outlined"
						placeholder="Title"
						error={error.bookerTitle.error}
						helperText={error.bookerTitle.message}
						value={bookerValues.title}
						onChange={e => handleChange('booker title', e.target.value)}
						fullWidth
						color="secondary"
					/>
					<TextField
						id="bookerSubtitle"
						label="Subtitle"
						variant="outlined"
						placeholder="Subtitle"
						error={error.bookerSubtitle.error}
						helperText={error.bookerSubtitle.message}
						value={bookerValues.subtitle}
						onChange={e => handleChange('booker subtitle', e.target.value)}
						fullWidth
						color="secondary"
					/>
					<Grid item xs={12}>
						<Grid container justify="center">
							<Button variant="contained" color="secondary" disabled={error.disable} onClick={handleClickBooker}>
								Update
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container>
					<InputLabel style={{ marginBottom: '10px', color: 'black' }} htmlFor="university">
						Talent
					</InputLabel>
					<TextField
						id="talentTitle"
						label="Title"
						variant="outlined"
						placeholder="Title"
						error={error.talentTitle.error}
						helperText={error.talentTitle.message}
						value={talentValues.title}
						onChange={e => handleChange('talent title', e.target.value)}
						fullWidth
						color="secondary"
					/>
					<TextField
						id="talentSubtitle"
						label="Subtitle"
						variant="outlined"
						placeholder="Subtitle"
						error={error.talentSubtitle.error}
						helperText={error.talentSubtitle.message}
						value={talentValues.subtitle}
						onChange={e => handleChange('talent subtitle', e.target.value)}
						fullWidth
						color="secondary"
					/>
					<Grid item xs={12}>
						<Grid container justify="center">
							<Button variant="contained" color="secondary" disabled={error.disable} onClick={handleClickTalent}>
								Update
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<SimpleDialog onClose={() => setOpen(false)} open={open} message={message} />
		</Grid>
	);
};

export default HowITWorks;

import DateFnsUtils from '@date-io/date-fns';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import axios from 'axios';
import 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Dialoge from '../basic/dialoges/simpleDialog';
import Table from '../basic/tables/suggestionTable';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { StateContext } from '../generalFunctions/context';
import { setHeaders } from '../generalFunctions/requestheaders';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
	searchText: {
		'& div': {
			margin: '0px'
		}
	},
	ListItemButton: {
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 1)'
		}
	}
}));

const Blogs: React.FC = () => {
	const { storeData } = useContext(StateContext);
	const [totalBlogs, setTotalBlogs] = useState(0);

	const [type, setType] = useState('');
	const [filter_text, setFilterText] = useState('');

	const [filterBlogs, setFilterBlog] = useState([{ id: '', title: '' }]);
	const [textFilters, setTextFilters] = useState('');
	const [filter, setFilter] = useState({
		search_string: '',
		date_from: '',
		date_to: ''
	});
	const [tableContent, setTableContent] = useState([
		//{ id: '', data: { title: '', author: '', status: '', publish_date: '' } }
		{
			id: '',
			data: {
				first_name: '',
				last_name: '',
				email: '',
				type: '',
				artist_name: '',
				artist_contact: '',
				event_date: '',
				created_at: '',
				description: ''
			}
		}
	]);
	const [page, setPage] = useState(1);

	const [OrderData, setOrderData] = useState({
		orderBy: 'event_date',
		order: 'desc'
	});

	const [dialogOpen, setDialogOpen] = useState(false);
	const [message, setMessage] = React.useState('');
	const history = useHistory();

	const [selectedDateStart, setSelectedDateStart] = React.useState<Date | null>(null);
	const [selectedDateEnd, setSelectedDateEnd] = React.useState<Date | null>(null);
	const [open, setOpen] = useState({ from: false, to: false });

	const classes = useStyles();

	useEffect(() => {
		console.log(OrderData);
		console.log(filter_text);
		console.log(type);

		axios
			.post(
				process.env.REACT_APP_API_URL + 'get_suggestion',
				{ page, type, OrderData, filter_text },
				{
					headers: setHeaders(storeData.Token)
				}
			)
			.then(response => {
				const data = response.data;
				setTotalBlogs(data.total_items);
				const tabledata = data.items.map((item: any) => {
					return {
						data: {
							//publish_date: new Date(item.publish_date).toLocaleString(),
							first_name: item.first_name,
							last_name: item.last_name,
							email: item.email,
							type: item.type,
							artist_name: item.artist_name,
							artist_contact: item.artist_contact,
							event_date: item.event_date
								? moment(item.event_date)
										.format('MM-DD-YYYY')
										.toString()
								: '---',
							//created_at: new Date(item.created_at).toLocaleString(),

							created_at: moment(item.created_at)
								.format('MM-DD-YYYY')
								.toString(),

							description: item.description
						},
						id: item.id
					};
				});
				setTableContent(tabledata);
			})
			.catch((e: any) => {
				setMessage(`Error: ${+JSON.stringify(e)}`);
				setDialogOpen(true);
			});
	}, [page, filter_text, type, OrderData, storeData.Token, totalBlogs, filter]);

	const handleDateChange = (date: Date | null, type: string) => {
		if (type === 'start') {
			setSelectedDateStart(date);
		} else {
			setSelectedDateEnd(date);
		}
	};

	const handleFilterChange = (val: string) => {
		setTextFilters(val);
	};

	const handleView = (id: string) => {
		history.push({
			pathname: history.location.pathname + `/edit-blog/${id}`
		});
	};

	const handleAddBlogView = () => {
		history.push({
			pathname: history.location.pathname + `/add-blog`
		});
	};

	const handleSearch = () => {
		const filterdata = {
			date_from: selectedDateStart
				? selectedDateStart.getFullYear() + '-' + (selectedDateStart.getMonth() + 1) + '-' + selectedDateStart.getDate()
				: '',
			date_to: selectedDateEnd
				? selectedDateEnd.getFullYear() + '-' + (selectedDateEnd.getMonth() + 1) + '-' + selectedDateEnd.getDate()
				: '',
			search_string: textFilters
		};
		setFilter(filterdata);
	};

	const handleClear = () => {
		setTextFilters('');
		setSelectedDateStart(null);
		setSelectedDateEnd(null);
		setFilter({
			search_string: '',
			date_from: '',
			date_to: ''
		});
	};

	const handleSelectTypeChange = (value: any) => {
		setType(value);
	};

	const handleFilterTextChange = (value: any) => {
		setFilterText(value);
	};

	const exportToCSV = () => {
		//setcsvLoading(true);
		var export_field = ['Name', 'Email', 'Type', 'Artist Name', 'Artist Contact', 'Event Date', 'Description'];
		axios
			.get(process.env.REACT_APP_API_URL + 'exportSuggestionToCSV', {
				headers: setHeaders(storeData.Token)
			})
			.then(response => {
				//setcsvLoading(false);
				const data = response.data;

				if (response.status === 200) {
					if (data.items) {
						data.items.unshift(export_field);
						var jsonObject = JSON.stringify(data.items);

						var csvContent = convertToCSV(jsonObject);
						//let csvContent = "email \n" + data.data.join("\n");
						//DONWLOAD FILE
						var dvDownloadCSVElement = document.getElementsByTagName('body')[0];
						var anchor = document.createElement('a');
						anchor.id = 'application-csv';
						anchor.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
						anchor.download = 'Suggestions.csv';
						dvDownloadCSVElement.appendChild(anchor);
						anchor.click();
						dvDownloadCSVElement.removeChild(anchor);
					}
				} else {
					//setcsvLoading(false);
					setMessage(`Error: ${response.data.message}`);
					setDialogOpen(true);
				}
			})
			.catch((e: any) => {
				//setcsvLoading(false);
				console.log('error: ', e);
				setMessage(`Error: ${+e.response.data.messge ? e.response.data.user_message : e.response.data.message}`);
				setDialogOpen(true);
			});
	};

	const convertToCSV = (objArray: any) => {
		var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
		var str = '';

		for (var i = 0; i < array.length; i++) {
			var line = '';
			for (var index in array[i]) {
				if (line != '') line += ',';

				line += array[i][index];
			}

			str += line + '\r\n';
		}

		return str;
	};

	return (
		<Box display="flex" flexDirection="column">
			<Typography align="center" variant="h4" style={{ marginBottom: '30px' }}>
				Suggestions
			</Typography>

			<Grid container>
				<Grid item xs={3} md={3}>
					<Box className={classes.searchText}>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="author"
							label="Search Text"
							name="filter_text"
							value={filter_text}
							onChange={e => handleFilterTextChange(e.target.value)}
							style={{ margin: '0px' }}
						/>
					</Box>
				</Grid>

				<Grid item xs={2} md={2}>
					<Box ml={4} style={{ width: '100%' }}>
						<FormControl>
							<InputLabel id="demo-simple-select-filled-label">Type</InputLabel>
							<Select
								labelId="type"
								id="type"
								value={type}
								onChange={e => handleSelectTypeChange(e.target.value)}
								style={{ width: '155px' }}
							>
								<MenuItem value="all">All</MenuItem>
								<MenuItem value="artist">Artist</MenuItem>
								<MenuItem value="genre">Genre</MenuItem>
							</Select>
						</FormControl>
					</Box>
				</Grid>

				<Grid item xs={2} md={2} style={{ textAlign: 'left' }}>
					<Button variant="contained" color="secondary" style={{ float: 'left', width: '135px', display: 'none' }}>
						Filter
					</Button>
				</Grid>

				<Grid item xs={5} md={5} style={{ textAlign: 'right' }}>
					<Button
						variant="contained"
						color="secondary"
						onClick={exportToCSV}
						style={{ float: 'right', width: '135px' }}
					>
						Export csv
					</Button>
				</Grid>
			</Grid>

			{tableContent ? (
				<Table
					page={page - 1}
					sortdata={setOrderData}
					rows={tableContent}
					total={totalBlogs}
					pagedata={setPage}
					handleView={handleView}
				/>
			) : (
				<> </>
			)}
			<Dialoge message={message} onClose={() => setDialogOpen(false)} open={dialogOpen} />
		</Box>
	);
};

export default Blogs;

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { StateContext, storeState } from './generalFunctions/context';
import { setHeaders } from './generalFunctions/requestheaders';

const MainMenu: React.FC = props => {
	const { storeData, setStoreData } = useContext(StateContext);
	const [docexpanded, setdocexpanded] = useState<boolean>(false);
	const [fieldexpanded, setFieldexpanded] = useState<boolean>(false);
	const [reviewexpanded, setReviewexpanded] = useState<boolean>(false);
	const history = useHistory();

	const setdata = (route: string) => {
		setStoreData({ ...storeData, Selected: route });
		storeState({ ...storeData, Selected: route });
		history.push(`/${route}`);
	};

	const handleClick = (type: string) => {
		if (type === 'docs') {
			setdocexpanded(!docexpanded);
		} else if (type === 'fields') {
			setFieldexpanded(!fieldexpanded);
		} else if (type === 'reviews') {
			setReviewexpanded(!reviewexpanded);
		}
	};
	return (
		<>
			<AppBar position="fixed">
				<Typography variant="h4" noWrap>
					Repsy Admin panel
				</Typography>
				<Button
					variant="contained"
					onClick={() => {
						axios({
							headers: setHeaders(storeData.Token),
							method: 'POST',
							url: process.env.REACT_APP_API_URL + 'users/signout'
						}).then(res => {
							localStorage.clear();
							history.replace('/');
							window.location.reload();
						});
					}}
				>
					logout
				</Button>
			</AppBar>
			<Drawer variant="permanent" anchor="left">
				<List>
					<MenuItem button selected={storeData.Selected === 'approve_band'} onClick={() => setdata('approve_band')}>
						<ListItemText primary="Talent Profiles" />
					</MenuItem>
					<MenuItem button selected={storeData.Selected === 'approve_booker'} onClick={() => setdata('approve_booker')}>
						<ListItemText primary="Booker Profiles" />
					</MenuItem>
					<MenuItem button selected={storeData.Selected === 'approve_agent'} onClick={() => setdata('approve_agent')}>
						<ListItemText primary="Agent Profiles" />
					</MenuItem>
					<MenuItem button selected={storeData.Selected === 'requests'} onClick={() => setdata('requests')}>
						<ListItemText primary="Requests" />
					</MenuItem>
					<MenuItem button selected={storeData.Selected === 'payments'} onClick={() => setdata('payments')}>
						<ListItemText primary="Payments" />
					</MenuItem>
					<MenuItem button selected={storeData.Selected === 'notifications'} onClick={() => setdata('notifications')}>
						<ListItemText primary="Notifications" />
					</MenuItem>
					<MenuItem button selected={storeData.Selected === 'tracking'} onClick={() => setdata('tracking')}>
						<ListItemText primary="Personal Tracking" />
					</MenuItem>
					<MenuItem button selected={storeData.Selected === 'testimonial'} onClick={() => setdata('testimonial')}>
						<ListItemText primary="Testimonials" />
					</MenuItem>
					<MenuItem button onClick={() => handleClick('reviews')}>
						<ListItemText primary="Reviews" />
						{reviewexpanded ? <ExpandLess /> : <ExpandMore />}
					</MenuItem>
					<Collapse in={reviewexpanded} timeout="auto" unmountOnExit>
						<List style={{ paddingLeft: '25px' }}>
							<MenuItem
								button
								selected={storeData.Selected === 'bookerreviews'}
								onClick={() => setdata('bookerreviews')}
							>
								<ListItemText primary="Booker Reviews" />
							</MenuItem>
							<MenuItem
								button
								selected={storeData.Selected === 'talentreviews'}
								onClick={() => setdata('talentreviews')}
							>
								<ListItemText primary="Talent Reviews" />
							</MenuItem>
							<MenuItem button selected={storeData.Selected === 'repsyreviews'} onClick={() => setdata('repsyreviews')}>
								<ListItemText primary="Repsy Reviews" />
							</MenuItem>
						</List>
					</Collapse>
					<MenuItem button onClick={() => handleClick('fields')}>
						<ListItemText primary="Static Fields" />
						{fieldexpanded ? <ExpandLess /> : <ExpandMore />}
					</MenuItem>
					<Collapse in={fieldexpanded} timeout="auto" unmountOnExit>
						<List style={{ paddingLeft: '25px' }}>
							<MenuItem button selected={storeData.Selected === 'genres'} onClick={() => setdata('genres')}>
								<ListItemText primary="Genres" />
							</MenuItem>
							<MenuItem button selected={storeData.Selected === 'booker_types'} onClick={() => setdata('booker_types')}>
								<ListItemText primary="Booker Types" />
							</MenuItem>
							<MenuItem button selected={storeData.Selected === 'states'} onClick={() => setdata('states')}>
								<ListItemText primary="States" />
							</MenuItem>
							<MenuItem button selected={storeData.Selected === 'gigs'} onClick={() => setdata('gigs')}>
								<ListItemText primary="Gigs" />
							</MenuItem>
							<MenuItem button selected={storeData.Selected === 'venues'} onClick={() => setdata('venues')}>
								<ListItemText primary="Venue Descriptions" />
							</MenuItem>
							<MenuItem button selected={storeData.Selected === 'tutorial'} onClick={() => setdata('tutorial')}>
								<ListItemText primary="Tutorial" />
							</MenuItem>
						</List>
					</Collapse>
					<MenuItem button onClick={() => handleClick('docs')}>
						<ListItemText primary="Documents" />
						{docexpanded ? <ExpandLess /> : <ExpandMore />}
					</MenuItem>
					<Collapse in={docexpanded} timeout="auto" unmountOnExit>
						<List style={{ paddingLeft: '25px' }}>
							<MenuItem button selected={storeData.Selected === 'home'} onClick={() => setdata('home')}>
								<ListItemText primary="Home" />
							</MenuItem>
							<MenuItem button selected={storeData.Selected === 'about'} onClick={() => setdata('about')}>
								<ListItemText primary="About" />
							</MenuItem>
							<MenuItem button selected={storeData.Selected === 'faqs'} onClick={() => setdata('faqs')}>
								<ListItemText primary="FAQs" />
							</MenuItem>
							<MenuItem
								button
								selected={storeData.Selected === 'booker_benefits'}
								onClick={() => setdata('booker_benefits')}
							>
								<ListItemText primary="Booker Benefits" />
							</MenuItem>
							<MenuItem
								button
								selected={storeData.Selected === 'talent_benefits'}
								onClick={() => setdata('talent_benefits')}
							>
								<ListItemText primary="Talent Benefits" />
							</MenuItem>
							<MenuItem button selected={storeData.Selected === 'quotes'} onClick={() => setdata('quotes')}>
								<ListItemText primary="Quotes" />
							</MenuItem>
							<MenuItem button selected={storeData.Selected === 'terms'} onClick={() => setdata('terms')}>
								<ListItemText primary="Terms And Conditions" />
							</MenuItem>
							<MenuItem button selected={storeData.Selected === 'howitworks'} onClick={() => setdata('howitworks')}>
								<ListItemText primary="How It Works" />
							</MenuItem>
						</List>
					</Collapse>
					<MenuItem button selected={storeData.Selected === 'support'} onClick={() => setdata('support')}>
						<ListItemText primary="Support" />
					</MenuItem>
					<MenuItem button selected={storeData.Selected === 'blogs'} onClick={() => setdata('blogs')}>
						<ListItemText primary="Blogs" />
					</MenuItem>

					<MenuItem button selected={storeData.Selected === 'suggestions'} onClick={() => setdata('suggestions')}>
						<ListItemText primary="Suggestions" />
					</MenuItem>
				</List>
			</Drawer>
		</>
	);
};

export default MainMenu;

import { Grid, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SimpleDialog from '../../basic/dialoges/simpleDialog';
import Profile from '../../basic/tables/profileTable';
import { StateContext } from '../../generalFunctions/context';
import { setHeaders } from '../../generalFunctions/requestheaders';

const Band: React.FC = () => {
	const { storeData } = useContext(StateContext);
	const [listall, setListall] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [tableContent, setTableContent] = useState([{ id: '', data: '', status: '' }]);
	const [page, setPage] = useState(1);
	const [open, setOpen] = useState(false);
	const [pending, setPending] = useState(false);
	const [message, setMessage] = useState('');
	const [search_keyword, setSearchKeyword] = useState('');
	const history = useHistory();
	const [timeoutData, setTimeoutData] = useState(0); //timeout data

	const timeOut = useRef<any>();

	const [allBands, setAllBands] = useState([{ id: 0, name: '' }]);

	const [featuredBands, setFeaturedBands] = useState([{ id: 0, name: '', order_number: 0 }]);
	useEffect(() => {
		getBandDataForfeaturedBands();
	}, [storeData.Token]);

	const getPendingBandList = () => {
		let search_text = search_keyword == '' ? undefined : search_keyword;
		axios
			.post(
				process.env.REACT_APP_API_URL + 'pending-bands/list',
				{ page: page, pending: pending, search_keyword: search_text },
				{
					headers: setHeaders(storeData.Token)
				}
			)
			.then(response => {
				if (response.data.items.length === 0 && page > 1) {
					setPage(prevState => prevState - 1);
				}
				const data = response.data;
				setTotalCount(data.total_items);
				const tabledata = data.items.map((item: any) => {
					return { id: item.id, data: item.name, status: item.status };
				});
				setTableContent(tabledata);
			})
			.catch((e: any) => {
				setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
				setOpen(true);
			});
	};

	useEffect(() => {
		getPendingBandList();
	}, [listall, page, pending, storeData.Token]);

	useEffect(() => {
		timeOut.current = setTimeout(() => {
			//search function
			getPendingBandList();
		}, 400);
		return () => clearTimeout(timeOut.current);
	}, [search_keyword]);

	const getBandDataForfeaturedBands = () => {
		axios
			.get(process.env.REACT_APP_API_URL + 'get-all-bands', {
				headers: setHeaders(storeData.Token)
			})
			.then(response => {
				setAllBands(response.data.items);
				getfeaturedBands();
			})
			.catch((e: any) => {
				setMessage(
					'Error: ' + e?.response?.data?.user_message ? e?.response?.data?.user_message : e?.response?.data?.message
				);
				setOpen(true);
			});
	};

	const getfeaturedBands = () => {
		axios
			.get(process.env.REACT_APP_API_URL + 'get-all-featured-bands', {
				headers: setHeaders(storeData.Token)
			})
			.then(featuredRes => {
				setFeaturedBands(featuredRes.data.items);
			})
			.catch((e: any) => {
				setMessage(
					'Error: ' + e?.response?.data?.user_message ? e?.response?.data?.user_message : e?.response?.data?.message
				);
				setOpen(true);
			});
	};

	const handleApprove = (id: string) => {
		axios
			.post(
				process.env.REACT_APP_API_URL + `band/update-status/${id}`,
				{ status: 'approved' },
				{
					headers: setHeaders(storeData.Token)
				}
			)
			.then(response => {
				setListall(!listall);
			})
			.catch(e => {
				setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
				setOpen(true);
			});
	};

	const handleDecline = (id: string, reasons: string) => {
		axios
			.post(
				process.env.REACT_APP_API_URL + `band/update-status/${id}`,
				{
					reason: reasons,
					status: 'declined'
				},
				{
					headers: setHeaders(storeData.Token)
				}
			)
			.then(response => {
				setListall(!listall);
			})
			.catch(e => {
				setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
				setOpen(true);
			});
	};

	const handleView = (id: string) => {
		history.push({
			pathname: history.location.pathname + `/${id}`
		});
	};

	const returnFeaturedData = (order_number: Number) => {
		let featuredBand = featuredBands.find(value => value.order_number == order_number);
		if (featuredBand)
			console.log(
				order_number,
				featuredBand,
				allBands.find(value => value.id == featuredBand?.id)
			);

		return allBands.find(value => value.id == featuredBand?.id);
	};

	const handleUpdateOrderNumber = (event: any, selectedBand: any, order_number: any) => {
		console.log('handleUpdateOrderNumber', selectedBand);
		//setDisabled(true);
		axios
			.post(process.env.REACT_APP_API_URL + `band/update-order/${selectedBand.id}/${order_number}`, null, {
				headers: setHeaders(storeData.Token)
			})
			.then(response => {
				getfeaturedBands();
				//setDisabled(false);
			})
			.catch((e: any) => {
				//setDisabled(false);
			});
	};

	return (
		<Box>
			{allBands.length > 1 && featuredBands.length > 1 && (
				<Grid container>
					<Grid xs={12} md={12}>
						<Box>
							<Typography align="center" variant="h5">
								Featured Bands
							</Typography>
						</Box>
					</Grid>
					<Grid container xs={12} md={12}>
						<Grid container direction="row" justify="space-between" alignItems="stretch">
							<Grid xs={4} md={4} style={{ padding: '8px' }}>
								<Box>
									<Autocomplete
										id="featured_1"
										options={allBands}
										defaultValue={returnFeaturedData(1)}
										getOptionLabel={option => option.name}
										onChange={(e, newval) => handleUpdateOrderNumber(e, newval, 1)}
										renderInput={params => <TextField {...params} label="Featured Band 1" variant="outlined" />}
									/>
								</Box>
							</Grid>
							<Grid direction="column" xs={4} md={4} style={{ padding: '8px' }}>
								<Box>
									<Autocomplete
										id="featured_2"
										options={allBands}
										defaultValue={returnFeaturedData(2)}
										getOptionLabel={option => option.name}
										onChange={(e, newval) => handleUpdateOrderNumber(e, newval, 2)}
										renderInput={params => <TextField {...params} label="Featured Band 2" variant="outlined" />}
									/>
								</Box>
							</Grid>
							<Grid direction="column" xs={4} md={4} style={{ padding: '8px' }}>
								<Box>
									<Autocomplete
										id="featured_3"
										options={allBands}
										defaultValue={returnFeaturedData(3)}
										getOptionLabel={option => option.name}
										onChange={(e, newval) => handleUpdateOrderNumber(e, newval, 3)}
										renderInput={params => <TextField {...params} label="Featured Band 3" variant="outlined" />}
									/>
								</Box>
							</Grid>
						</Grid>
						<Grid container direction="row" justify="space-between" alignItems="stretch">
							<Grid xs={4} md={4} style={{ padding: '8px' }}>
								<Box>
									<Autocomplete
										id="featured_4"
										options={allBands}
										defaultValue={returnFeaturedData(4)}
										getOptionLabel={option => option.name}
										onChange={(e, newval) => handleUpdateOrderNumber(e, newval, 4)}
										renderInput={params => <TextField {...params} label="Featured Band 4" variant="outlined" />}
									/>
								</Box>
							</Grid>
							<Grid direction="column" xs={4} md={4} style={{ padding: '8px' }}>
								<Box>
									<Autocomplete
										id="featured_5"
										options={allBands}
										defaultValue={returnFeaturedData(5)}
										getOptionLabel={option => option.name}
										onChange={(e, newval) => handleUpdateOrderNumber(e, newval, 5)}
										renderInput={params => <TextField {...params} label="Featured Band 5" variant="outlined" />}
									/>
								</Box>
							</Grid>
							<Grid direction="column" xs={4} md={4} style={{ padding: '8px' }}>
								<Box>
									<Autocomplete
										id="featured_6"
										options={allBands}
										defaultValue={returnFeaturedData(6)}
										getOptionLabel={option => option.name}
										onChange={(e, newval) => handleUpdateOrderNumber(e, newval, 6)}
										renderInput={params => <TextField {...params} label="Featured Band 6" variant="outlined" />}
									/>
								</Box>
							</Grid>
						</Grid>
						<Grid container direction="row" justify="space-between" alignItems="stretch">
							<Grid xs={4} md={4} style={{ padding: '8px' }}>
								<Box>
									<Autocomplete
										id="featured_7"
										options={allBands}
										defaultValue={returnFeaturedData(7)}
										getOptionLabel={option => option.name}
										onChange={(e, newval) => handleUpdateOrderNumber(e, newval, 7)}
										renderInput={params => <TextField {...params} label="Featured Band 7" variant="outlined" />}
									/>
								</Box>
							</Grid>
							<Grid direction="column" xs={4} md={4} style={{ padding: '8px' }}>
								<Box>
									<Autocomplete
										id="featured_8"
										options={allBands}
										defaultValue={returnFeaturedData(8)}
										getOptionLabel={option => option.name}
										onChange={(e, newval) => handleUpdateOrderNumber(e, newval, 8)}
										renderInput={params => <TextField {...params} label="Featured Band 8" variant="outlined" />}
									/>
								</Box>
							</Grid>
							<Grid direction="column" xs={4} md={4} style={{ padding: '8px' }}>
								<Box>
									<Autocomplete
										id="featured_9"
										options={allBands}
										defaultValue={returnFeaturedData(9)}
										getOptionLabel={option => option.name}
										onChange={(e, newval) => handleUpdateOrderNumber(e, newval, 9)}
										renderInput={params => <TextField {...params} label="Featured Band 9" variant="outlined" />}
									/>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}

			<Typography align="center" variant="h4">
				Talents
			</Typography>
			<FormControlLabel
				control={
					<Checkbox
						checked={pending}
						onChange={() => setPending(prevstate => !prevstate)}
						name="pending"
						color="primary"
					/>
				}
				label="List Pending Approvals Only."
			/>

			<Grid container spacing={1} justify="flex-end">
				<Grid item xs={12} md={12} lg={12}>
					<TextField
						autoComplete="off"
						id="search"
						name="search"
						variant="outlined"
						color="secondary"
						fullWidth={true}
						placeholder="Search"
						value={search_keyword}
						onChange={(e: any) => {
							setSearchKeyword(e.target.value);
						}}
					/>
					{/* <Box display="flex" justifyContent="flex-end" paddingRight="50px"> */}
					{/* <Button variant="contained" color="secondary">
						Search
					</Button> */}
					{/* </Box> */}
				</Grid>
			</Grid>
			{tableContent ? (
				<Profile
					title="Talent Profiles"
					page={page - 1}
					rows={tableContent}
					total={totalCount}
					pagedata={setPage}
					handleApprove={handleApprove}
					handleDecline={handleDecline}
					handleView={handleView}
				/>
			) : (
				<> </>
			)}
			<SimpleDialog open={open} onClose={() => setOpen(false)} message={message} />
		</Box>
	);
};

export default Band;

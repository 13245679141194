import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Dialoge from '../basic/dialoges/simpleDialog';
import Table from '../basic/tables/userReviewTable';
import { StateContext } from '../generalFunctions/context';
import { setHeaders } from '../generalFunctions/requestheaders';
import { useStyles } from './bookerreview';

const Band: React.FC = () => {
	const { storeData } = useContext(StateContext);
	const classes = useStyles();

	const [totalreviews, setTotalReviews] = useState(0);
	const [filter, setFilter] = useState({ name: '', band_name: '' });
	const [textFilters, setTextFilters] = useState({ name: '', band_name: '' });
	const [tableContent, setTableContent] = useState([
		{ id: '', data: { from: '', event: '', to: '', seen: true, review: '', rating: '' } }
	]);
	const [page, setPage] = useState(1);
	const [listall, setListall] = useState(false);
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState('Done');

	useEffect(() => {
		const ids: any = [];
		axios
			.post(
				process.env.REACT_APP_API_URL + 'reviews/list-admin-reviews',
				{ page, name: filter.name, band_name: filter.band_name },
				{
					headers: setHeaders(storeData.Token)
				}
			)
			.then(response => {
				const data = response.data;
				setTotalReviews(data.total_items);
				const tabledata = data.items.map((item: any) => {
					ids.push(item.id);
					return {
						data: {
							event: item.event.title,
							from: item.event.created_by.fname + item.event.created_by.lname,
							rating: item.repsy_rating,
							review: item.repsy_review,
							seen: item.isSeen,
							to: item.band.name
						},
						id: item.id
					};
				});
				setTableContent(tabledata);
				return axios.post(
					process.env.REACT_APP_API_URL + 'reviews/mark-seen',
					{ review_ids: ids },
					{
						headers: setHeaders(storeData.Token)
					}
				);
			})
			.then(res => console.log('done'))
			.catch((e: any) => {
				setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
				setOpen(true);
			});
	}, [page, storeData.Token, totalreviews, filter, listall]);

	const handleFilterChange = (val: string, type: string) => {
		let newFilter;
		if (type === 'band') {
			newFilter = { ...textFilters, band_name: val };
			setTextFilters(newFilter);
		} else {
			newFilter = { ...textFilters, name: val };
			setTextFilters(newFilter);
		}
	};

	const handleSearch = () => {
		const filterdata = {
			...filter,
			band_name: textFilters.band_name,
			name: textFilters.name
		};
		setFilter(filterdata);
	};

	const handleDelete = (id: string) => {
		axios
			.delete(process.env.REACT_APP_API_URL + `reviews/${id}`, {
				headers: setHeaders(storeData.Token)
			})
			.then(res => setListall(!listall))
			.catch(e => {
				setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
				setOpen(true);
			});
	};

	const handleClear = () => {
		setTextFilters({ name: '', band_name: '' });
		setFilter({ name: '', band_name: '' });
	};

	return (
		<Box display="flex" flexDirection="column">
			<Typography align="center" variant="h4">
				Repsy Reviews
			</Typography>
			<Box className={classes.container}>
				<TextField
					value={textFilters.band_name}
					onChange={e => handleFilterChange(e.target.value, 'band')}
					label="Band Name"
					variant="outlined"
				/>
				<TextField
					onChange={e => handleFilterChange(e.target.value, 'event')}
					value={textFilters.name}
					label="Booker Name"
					variant="outlined"
				/>
				<Box display="flex" justifyContent="flex-end" paddingRight="50px">
					<Button variant="contained" color="secondary" onClick={handleSearch}>
						Filter
					</Button>
					<Button variant="contained" onClick={handleClear}>
						Clear
					</Button>
				</Box>
			</Box>

			{tableContent ? (
				<Table page={page - 1} rows={tableContent} total={totalreviews} pagedata={setPage} deletee={handleDelete} />
			) : (
				<> </>
			)}
			<Dialoge open={open} message={message} onClose={() => setOpen(false)} />
		</Box>
	);
};

export default Band;

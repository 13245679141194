import CardHeader from '@material-ui/core/CardHeader';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SimpleDialog from '../../basic/dialoges/simpleDialog';
import { StateContext } from '../../generalFunctions/context';
import { setHeaders } from '../../generalFunctions/requestheaders';
import {
	Grid,
	Box,
	Card,
	CardContent,
	Typography,
	IconButton,
	Avatar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	makeStyles,
	createStyles,
	Theme,
	withStyles
} from '@material-ui/core';
const StyledTableCell = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: theme.palette.common.black,
			color: theme.palette.common.white
		},
		body: {
			fontSize: 14
		}
	})
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover
			}
		}
	})
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		small: {
			width: theme.spacing(5),
			height: theme.spacing(5),
			backgroundColor: 'transparent'
		}
	})
);
const Page: React.FC = () => {
	const history = useHistory();
	const { storeData } = useContext(StateContext);
	const [profile, setProfile] = useState({
		photo: { thumbnail: '' },
		fname: '',
		lname: '',
		username: '',
		email: '',
		phone_number: '',
		status: '',
		promo_code: ''
	});
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState('');
	const { id } = useParams();
	const classes = useStyles();

	let rowdataList = Array<{
		band_id: string;
		name: string;
		location: string;
		bio: string;
		size: string;
		deposit: string;
		avatar: string;
	}>();
	const [bandList, setBandList] = useState(rowdataList);

	useEffect(() => {
		axios
			.get(process.env.REACT_APP_API_URL + `agent/show/${id}`, {
				headers: setHeaders(storeData.Token)
			})
			.then(response => {
				setProfile(response.data.item);
			})
			.catch((e: any) => {
				setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
				setOpen(true);
			});

		axios
			.get(process.env.REACT_APP_API_URL + `list/agent-bands/${id}`, {
				headers: setHeaders(storeData.Token)
			})
			.then((resBandList: any) => {
				console.log('resBandList: ', resBandList);
				if (resBandList.status === 200) {
					if (resBandList.data) {
						let resBandListData = resBandList.data;
						if (resBandListData.items.length > 0) {
							const tabledata = resBandListData.items.map((item: any) => {
								return {
									band_id: item.band_id,
									name: item.agent_band.name,
									location: item.agent_band.location,
									size: item.agent_band.size,
									deposit: item.agent_band.deposit,
									bio: item.agent_band.bio != null ? `${item.agent_band.bio.substring(0, 50)}...` : '',
									avatar: item.agent_band.photo ? item.agent_band.photo.thumbnail : ''
								};
							});
							setBandList(tabledata);

							console.log('bandList: ', bandList);
						}
					}
				}
			})
			.catch((e: any) => {
				setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
				setOpen(true);
			});
	}, [id, storeData.Token]);

	const handleBackButtonClick = () => {
		history.push('/approve_agent');
	};

	return (
		<Box paddingBottom="20px">
			<Box>
				<IconButton onClick={handleBackButtonClick} aria-label="previous page" color="secondary">
					<KeyboardArrowLeft />
				</IconButton>
			</Box>
			{profile ? (
				<>
					<Card style={{ marginTop: '20px', padding: '20px', backgroundColor: '#fafafa' }}>
						<CardContent>
							<Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
								<Grid item xs={3} sm={4}>
									<Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
										<Grid item xs={4} sm={5} style={{ margin: '20px 0px' }}>
											<Avatar
												src={profile.photo ? profile.photo.thumbnail : ''}
												style={{
													marginLeft: 'auto',
													marginRight: '15px',
													border: '2px solid #ffffff',
													boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)'
												}}
											>
												{profile.fname[0] + profile.lname[0]}
											</Avatar>
										</Grid>
										<Grid item xs={8} sm={7} style={{ marginBottom: '20px' }}>
											<Typography variant="h4" color="primary" style={{ marginLeft: '0px', marginTop: '20px' }}>
												{profile.fname}
											</Typography>
											<Typography variant="h6">{profile.lname}</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={9} sm={8}>
									<Grid
										container
										direction="row"
										justify="flex-start"
										alignItems="center"
										spacing={2}
										style={{ backgroundColor: '#ffffff' }}
									>
										<Grid
											item
											xs={2}
											sm={3}
											style={{ border: '1px solid #e2e2e2', paddingLeft: '15px' }}
											alignItems="center"
										>
											<Typography>
												<strong>User Name:</strong>
											</Typography>
										</Grid>
										<Grid item xs={10} sm={9} style={{ border: '1px solid #e2e2e2' }}>
											<Typography>{profile.username}</Typography>
										</Grid>
										<Grid
											item
											xs={2}
											sm={3}
											style={{
												border: '1px solid #e2e2e2',
												borderTop: '0px',
												paddingLeft: '15px',
												backgroundColor: 'rgb(239 239 239)'
											}}
											alignItems="center"
										>
											<Typography>
												<strong>Email:</strong>
											</Typography>
										</Grid>
										<Grid
											item
											xs={10}
											sm={9}
											style={{ border: '1px solid #e2e2e2', borderTop: '0px', backgroundColor: 'rgb(239 239 239)' }}
										>
											<Typography>{profile.email}</Typography>
										</Grid>
										<Grid
											item
											xs={2}
											sm={3}
											style={{ border: '1px solid #e2e2e2', borderTop: '0px', paddingLeft: '15px' }}
											alignItems="center"
										>
											<Typography>
												<strong>Phone Number:</strong>
											</Typography>
										</Grid>
										<Grid item xs={10} sm={9} style={{ border: '1px solid #e2e2e2', borderTop: '0px' }}>
											<Typography>{profile.phone_number}</Typography>
										</Grid>
										<Grid
											item
											xs={2}
											sm={3}
											style={{
												border: '1px solid #e2e2e2',
												borderTop: '0px',
												paddingLeft: '15px',
												backgroundColor: 'rgb(239 239 239)'
											}}
											alignItems="center"
										>
											<Typography>
												<strong>Status:</strong>
											</Typography>
										</Grid>
										<Grid
											item
											xs={10}
											sm={9}
											style={{ border: '1px solid #e2e2e2', borderTop: '0px', backgroundColor: 'rgb(239 239 239)' }}
										>
											<Typography>{profile.status}</Typography>
										</Grid>
										<Grid
											item
											xs={2}
											sm={3}
											style={{ border: '1px solid #e2e2e2', borderTop: '0px', paddingLeft: '15px' }}
											alignItems="center"
										>
											<Typography>
												<strong>Promo Code:</strong>
											</Typography>
										</Grid>
										<Grid item xs={10} sm={9} style={{ border: '1px solid #e2e2e2', borderTop: '0px' }}>
											<Typography>{profile.promo_code}</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
					{bandList && (
						<Card style={{ marginTop: '20px' }}>
							<CardContent style={{ padding: '0px' }}>
								<TableContainer
									style={{
										backgroundColor: '#ffffff',
										borderRadius: '5px',
										boxShadow:
											'0px 0px 4px 0px rgba(0,0,0,0.1), 0px 0px 4px 0px rgba(0,0,0,0.1), 0px 0px 4px 0px rgba(0,0,0,0.1)'
									}}
								>
									<Table stickyHeader aria-label="sticky customized table">
										<TableHead>
											<StyledTableRow>
												<StyledTableCell style={{ width: '5%' }}></StyledTableCell>
												<StyledTableCell style={{ width: '15%' }} align="left">
													<strong>Band Name</strong>
												</StyledTableCell>
												<StyledTableCell style={{ width: '15%' }} align="left">
													<strong>Location</strong>
												</StyledTableCell>
												<StyledTableCell style={{ width: '5%' }} align="center">
													<strong>Size</strong>
												</StyledTableCell>
												<StyledTableCell style={{ width: '55%' }} align="left">
													<strong>Bio</strong>
												</StyledTableCell>
												<StyledTableCell style={{ width: '5%' }} align="right">
													<strong>Deposit</strong>
												</StyledTableCell>
											</StyledTableRow>
										</TableHead>
										<TableBody>
											{bandList.length > 0 ? (
												bandList.map(row => (
													<StyledTableRow>
														<StyledTableCell component="td" scope="row">
															<Avatar
																alt="Remy Sharp"
																src={row.avatar}
																className={classes.small}
																style={{
																	border: '2px solid #ffffff',
																	boxShadow: '0 0px 4px 0 rgba(0, 0, 0, 0.1), 0 0px 4px 0 rgba(0, 0, 0, 0.1)'
																}}
															/>
														</StyledTableCell>
														<StyledTableCell align="left" color="primary">
															<strong>{row.name}</strong>
														</StyledTableCell>
														<StyledTableCell align="left">{row.location}</StyledTableCell>
														<StyledTableCell align="center">{row.size}</StyledTableCell>
														<StyledTableCell align="left">{row.bio}</StyledTableCell>
														<StyledTableCell align="right" color="primary">
															<strong>${row.deposit}</strong>
														</StyledTableCell>
													</StyledTableRow>
												))
											) : (
												<StyledTableRow>
													<StyledTableCell colSpan={6} align="center" component="td">
														No band found!
													</StyledTableCell>
												</StyledTableRow>
											)}
										</TableBody>
									</Table>
								</TableContainer>
							</CardContent>
						</Card>
					)}
				</>
			) : (
				<> </>
			)}
			<SimpleDialog open={open} onClose={() => setOpen(false)} message={message} />
		</Box>
	);
};

export default Page;

import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Dialog from '../basic/dialoges/simpleDialog';
import Table from '../basic/tables/supportTable';
import { StateContext } from '../generalFunctions/context';
import { setHeaders } from '../generalFunctions/requestheaders';

const Support: React.FC = () => {
	const { storeData } = useContext(StateContext);
	const [loading, setLoading] = useState(false);
	const [tableContent, setTableContent] = useState([
		{ ticket_type: '', details: '', id: '', status: '', name: '', date: '', account_type: '' }
	]);
	const [page, setPage] = useState(1);
	const [totalItems, setTotalItems] = useState(0);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [message, setMessage] = useState('');
	const [listAll, setListAll] = useState(false);

	useEffect(() => {
		setLoading(true);
		axios
			.post(
				process.env.REACT_APP_API_URL + 'support/tickets',
				{ page },
				{
					headers: setHeaders(storeData.Token)
				}
			)
			.then(res => {
				setTotalItems(res.data.total_items);
				console.log(res.data.items);
				const tablecontent = res.data.items.map((item: any) => {
					return {
						account_type: item.created_by.role === 'TALENT' ? 'Talent' : item.created_by.booker_type?.type,
						date: item.created_by.created_at,
						details: item.details,
						id: item.id,
						name: item.created_by.fname + ' ' + item.created_by.lname,
						status: item.status,
						ticket_type: item.ticket_type
					};
				});
				setTableContent(tablecontent);
				setLoading(false);
			})
			.catch(e => {
				console.log(e);
				setMessage(`Error: ${e.response.data.user_message ? e.response.data.user_message : e.response.data.message}`);
				setDialogOpen(true);
				setLoading(false);
			});
	}, [page, storeData.Token, listAll]);

	const changeStatus = (status: any, id: string) => {
		setLoading(true);
		axios
			.put(
				process.env.REACT_APP_API_URL + `support/tickets/${id}`,
				{ status },
				{
					headers: setHeaders(storeData.Token)
				}
			)
			.then(res => {
				setLoading(false);
				setListAll(!listAll);
			})
			.catch(e => {
				setMessage(`Error: ${e.response.data.user_message ? e.response.data.user_message : e.response.data.message}`);
				setDialogOpen(true);
				setLoading(false);
			});
	};

	return (
		<>
			<Table
				loading={loading}
				rows={tableContent}
				changeStatus={changeStatus}
				page={page}
				pagedata={setPage}
				total={totalItems}
			/>
			<Dialog onClose={() => setDialogOpen(false)} open={dialogOpen} message={message} />
		</>
	);
};

export default Support;

import DateFnsUtils from '@date-io/date-fns';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import axios from 'axios';
import 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Dialoge from '../basic/dialoges/simpleDialog';
import Table from '../basic/tables/blogTable';
import { StateContext } from '../generalFunctions/context';
import { setHeaders } from '../generalFunctions/requestheaders';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import moment from 'moment';
import DeleteDialoge from '../../component/basic/dialoges/deleteDialoge';

const Blogs: React.FC = () => {
	const { storeData } = useContext(StateContext);
	const [totalBlogs, setTotalBlogs] = useState(0);
	const [filterBlogs, setFilterBlog] = useState([{ id: '', title: '' }]);
	const [textFilters, setTextFilters] = useState('');
	const [filter, setFilter] = useState({
		search_string: '',
		date_from: '',
		date_to: ''
	});
	const [tableContent, setTableContent] = useState([
		{ id: '', data: { title: '', author: '', status: '', publish_date: '' } }
	]);
	const [page, setPage] = useState(1);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [message, setMessage] = React.useState('');
	const history = useHistory();

	const [selectedDateStart, setSelectedDateStart] = React.useState<Date | null>(null);
	const [selectedDateEnd, setSelectedDateEnd] = React.useState<Date | null>(null);
	const [open, setOpen] = useState({ from: false, to: false });
	const [isAdmin, setIsAdmin] = useState(true);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

	const [randomNumber, setRandomNumber] = useState(0);
	const [deleteBlogId, setDeleteBlogId] = useState('');

	useEffect(() => {
		axios
			.post(
				process.env.REACT_APP_API_URL + 'blogs/list',
				{ page, filter, isAdmin },
				{
					headers: setHeaders(storeData.Token)
				}
			)
			.then(response => {
				const data = response.data;
				console.log('data: ', data);
				setTotalBlogs(data.total_items);
				const tabledata = data.items.map((item: any) => {
					return {
						data: {
							title: item.title,
							//publish_date: new Date(item.publish_date).toLocaleString(),
							publish_date: moment(item.publish_date)
								.format('MM-DD-YYYY')
								.toString(),
							author: item.author,
							status: item.status
						},
						id: item.id
					};
				});
				setTableContent(tabledata);
			})
			.catch((e: any) => {
				console.log('Error: ', e);
				setMessage(`Error: ${+JSON.stringify(e)}`);
				//setMessage(`Error: ${+e.response.data.user_message ? e.response.data.user_message : e.response.data.message}`);
				setDialogOpen(true);
			});
	}, [page, storeData.Token, totalBlogs, filter, setRandomNumber, randomNumber]);

	const handleDateChange = (date: Date | null, type: string) => {
		if (type === 'start') {
			setSelectedDateStart(date);
		} else {
			setSelectedDateEnd(date);
		}
	};

	const handleFilterChange = (val: string) => {
		setTextFilters(val);
	};

	const handleView = (id: string) => {
		history.push({
			pathname: history.location.pathname + `/edit-blog/${id}`
		});
	};

	const deleteBlog = (id: string) => {
		setDeleteDialogOpen(true);
		setDeleteBlogId(id);
	};

	const confirmDeleteBlog = (action: string, id: string) => {
		if (action === 'delete') {
			axios
				.delete(process.env.REACT_APP_API_URL + `blogs/${id}`, {
					headers: setHeaders(storeData.Token)
				})
				.then(response => {
					setDeleteDialogOpen(false);
					setRandomNumber(Math.random());
				});
		} else {
			setDeleteDialogOpen(false);
			setDeleteBlogId('');
		}
	};

	const handleAddBlogView = () => {
		history.push({
			pathname: history.location.pathname + `/add-blog`
		});
	};

	const handleSearch = () => {
		const filterdata = {
			date_from: selectedDateStart
				? selectedDateStart.getFullYear() + '-' + (selectedDateStart.getMonth() + 1) + '-' + selectedDateStart.getDate()
				: '',
			date_to: selectedDateEnd
				? selectedDateEnd.getFullYear() + '-' + (selectedDateEnd.getMonth() + 1) + '-' + selectedDateEnd.getDate()
				: '',
			search_string: textFilters
		};
		setFilter(filterdata);
	};

	const handleClear = () => {
		setTextFilters('');
		setSelectedDateStart(null);
		setSelectedDateEnd(null);
		setFilter({
			search_string: '',
			date_from: '',
			date_to: ''
		});
	};

	return (
		<Box display="flex" flexDirection="column">
			<Typography align="center" variant="h4">
				Blogs
			</Typography>
			<Grid container justify="flex-start" wrap="nowrap">
				<Grid container direction="row" style={{ width: '50%', display: 'none' }} justify="space-evenly" wrap="nowrap">
					<TextField
						value={textFilters}
						onChange={e => handleFilterChange(e.target.value)}
						label="Search"
						variant="outlined"
						style={{ width: 350 }}
					/>
				</Grid>
			</Grid>

			<Box display="flex" justifyContent="flex-end" paddingRight="50px">
				<Button variant="contained" color="secondary" onClick={handleAddBlogView} style={{ width: '120px' }}>
					Add Blog
				</Button>
			</Box>

			{tableContent ? (
				<Table
					page={page - 1}
					rows={tableContent}
					total={totalBlogs}
					pagedata={setPage}
					deleteBlog={deleteBlog}
					handleView={handleView}
				/>
			) : (
				<> </>
			)}
			<Dialoge message={message} onClose={() => setDialogOpen(false)} open={dialogOpen} />

			<DeleteDialoge
				id={deleteBlogId}
				onClose={(action, id) => confirmDeleteBlog(action, id)}
				open={deleteDialogOpen}
			/>
		</Box>
	);
};

export default Blogs;

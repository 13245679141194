import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';

interface IDeleteDialogProps {
	open: boolean;
	id: string;
	onClose: (action: { type: string; reasons: string }, id: string) => void;
}

const ReasonsDialog = (props: IDeleteDialogProps) => {
	const { onClose, open, id } = props;

	const [values, setValues] = useState('');

	const handleClose = (action: { type: string; reasons: string }) => {
		onClose(action, id);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{'Please provide reasons.'}</DialogTitle>
			<DialogContent>
				<TextField
					multiline
					label="Reason"
					variant="outlined"
					placeholder="Reason"
					value={values}
					onChange={e => setValues(e.target.value)}
					rows="20"
					fullWidth
					color="secondary"
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => handleClose({ type: 'delete', reasons: values })} color="primary">
					Ok
				</Button>
				<Button onClick={() => handleClose({ type: '', reasons: '' })} color="primary" autoFocus>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ReasonsDialog;

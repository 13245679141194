import { InputLabel, Box, Grid } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import MainMenu from '../MainMenu';
import React, { useContext, useEffect, useState } from 'react';
import SimpleDialog from '../basic/dialoges/simpleDialog';
import { StateContext } from '../generalFunctions/context';
import { setHeaders } from '../generalFunctions/requestheaders';
import { makeStyles } from '@material-ui/core/styles';

const EVENT_TITLE_MAX_LENGTH = 255;
const EVENT_DESCRIPTION_MAX_LENGTH = 1000;

const useStyles = makeStyles(theme => ({
	form: {
		marginTop: theme.spacing(1),
		width: '100%' // Fix IE 11 issue.
	},
	paper: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		marginTop: theme.spacing(8)
	},
	submit: {
		margin: '0 auto',
		padding: '11px',
		minWidth: '200px'
	},
	footer: {
		background: '#f7f7f7',
		textAlign: 'center',
		padding: '30px',
		marginTop: '15%',
		'& div': {
			textAlign: 'center',
			justifyContent: 'center'
		}
	},
	gridform: {
		margin: '0 auto',
		'& div': {
			marginBottom: '0'
		}
	},
	termsconditions: {
		marginTop: '15px',
		'& span': {
			padding: '0',
			paddingRight: '5px'
		}
	},
	fileinput: {
		marginTop: '15px',
		marginLeft: '6px'
	},
	textcenter: {
		textAlign: 'center'
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	validateError: {
		color: 'red',
		'font-size': '0.75rem'
	},

	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -15,
		marginLeft: -12
	}
}));
const Home: React.FC = () => {
	const classes = useStyles();
	const { storeData } = useContext(StateContext);
	const [open, setOpen] = React.useState(false);
	const [headerImage, setHeaderImage] = useState<{ file: any; headerImagePreviewUrl: any }>();
	const [eventImage, setEventImage] = useState<{ file: any; eventImagePreviewUrl: any }>();

	const [message, setMessage] = React.useState('Done');
	const [error, setError] = useState({
		disable: false,
		event_title: {
			error: false,
			message: ''
		},
		event_description: {
			error: false,
			message: ''
		}
	});
	const [values, setValues] = useState({
		id: 0,
		event_title: '',
		event_description: '',
		event_image: '',
		header_image: ''
	});
	useEffect(() => {
		axios
			.get(process.env.REACT_APP_API_URL + 'front-home', {
				headers: setHeaders(storeData.Token)
			})
			.then(response => {
				console.log('response', response);
				setValues(response.data.items);
			})
			.catch(e => {
				handleClickOpen();
				setMessage('no internet connection');
			});
	}, [storeData.Token]);

	const handleChange = (id: string, value: string) => {
		let newvalues = { ...values };
		let newerror = { ...error };
		switch (id) {
			case 'event_title':
				newvalues = { ...newvalues, event_title: value };
				setValues(newvalues);
				newerror = { ...newerror, event_title: { error: false, message: '' }, disable: false };
				setError(newerror);
				if (value.length > EVENT_TITLE_MAX_LENGTH) {
					newerror = {
						...newerror,
						disable: true,
						event_title: {
							error: true,
							message: `value is too long (can't be more than ${EVENT_TITLE_MAX_LENGTH} characters)`
						}
					};
					setError(newerror);
				} else if (value.trim().length === 0) {
					newerror = {
						...newerror,
						disable: true,
						event_title: {
							error: true,
							message: 'required'
						}
					};
					setError(newerror);
				}
				break;
			case 'event_description':
				newvalues = { ...newvalues, event_description: value };
				setValues(newvalues);
				newerror = { ...newerror, event_description: { error: false, message: '' }, disable: false };
				setError(newerror);
				if (value.length > EVENT_DESCRIPTION_MAX_LENGTH) {
					newerror = {
						...newerror,
						disable: true,
						event_description: {
							error: true,
							message: `value is too long (can't be more than ${EVENT_DESCRIPTION_MAX_LENGTH} characters)`
						}
					};
					setError(newerror);
				} else if (value.trim().length === 0) {
					newerror = {
						...newerror,
						disable: true,
						event_description: {
							error: true,
							message: 'required'
						}
					};
					setError(newerror);
				}
				break;
		}
	};
	const handleClick = async () => {
		let headerImagePhotoUrl = values.header_image ? values.header_image : '';
		if (headerImage?.file) {
			headerImagePhotoUrl = await uploadFile(headerImage?.file);
			values.header_image = headerImagePhotoUrl;
			console.log('headerImagePhotoUrl: ', headerImagePhotoUrl);
		}

		let eventImagePhotoUrl = values.event_image ? values.event_image : '';
		if (eventImage?.file) {
			eventImagePhotoUrl = await uploadFile(eventImage?.file);
			values.event_image = eventImagePhotoUrl;
			console.log('eventImagePhotoUrl: ', eventImagePhotoUrl);
		}

		const data = {
			event_title: values.event_title.length > 0 ? values.event_title : undefined,
			event_description: values.event_description.length > 0 ? values.event_description : undefined,
			event_image: values.event_image,
			header_image: values.header_image
		};
		axios
			.post(process.env.REACT_APP_API_URL + `front-home`, data, {
				headers: setHeaders(storeData.Token)
			})
			.then(response => {
				handleClickOpen();
			})
			.catch(e => {
				setMessage('Something went wrong.');
				handleClickOpen();
			});
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const uploadFile = async (file: any) => {
		// uploadHandler is a function
		console.log('handleImageUploadBefore: ', file);
		const bodyFormData = new FormData();
		bodyFormData.append('media', file);
		let res = await axios.post(process.env.REACT_APP_API_URL + `media/upload`, bodyFormData, {
			headers: setHeaders(storeData.Token)
		});

		return res.data.item.url;
	};

	const filechange = (e: any, name: string) => {
		const reader = new FileReader();
		const file = e.target.files[0];
		reader.onloadend = () => {
			switch (name) {
				case 'header':
					setHeaderImage({
						file,
						headerImagePreviewUrl: reader.result
					});
					break;
				case 'event':
					setEventImage({
						file,
						eventImagePreviewUrl: reader.result
					});
					break;
			}
		};
		reader.readAsDataURL(file);
	};

	return (
		<>
			<MainMenu />

			<Box display="flex" flexDirection="column">
				<CssBaseline />
				<div className={classes.paper}>
					<Typography component="h1" variant="h4">
						Home
					</Typography>
					<form className={classes.form} noValidate>
						<Grid container spacing={1} item xs={12} md={10} className={classes.gridform}>
							<InputLabel htmlFor="outlined-age-native">Header Image *</InputLabel>
							<Grid container item xs={12} md={12}>
								<Box
									display="flex"
									justifyContent="flex-end"
									alignItems="center"
									paddingRight="15px"
									marginTop="15px"
									height="110px"
								>
									<input
										id="header_image"
										name="header_image"
										type="file"
										accept="image/*"
										onChange={(e: any) => filechange(e, 'header')}
									/>
									<img
										width="200px"
										height="100px"
										src={headerImage?.headerImagePreviewUrl ? headerImage.headerImagePreviewUrl : values.header_image}
										alt=""
									/>
								</Box>
							</Grid>
							<InputLabel htmlFor="outlined-age-native">Event Details *</InputLabel>
							<Grid container item xs={12} md={12}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									error={error.event_title.error}
									helperText={error.event_title.message}
									id="event_title"
									label="Event Title"
									name="event_title"
									value={values.event_title}
									onChange={e => handleChange('event_title', e.target.value)}
								/>
							</Grid>
							<Grid container item xs={12} md={12}>
								<Box
									display="flex"
									justifyContent="flex-end"
									alignItems="center"
									paddingRight="15px"
									marginTop="15px"
									height="110px"
								>
									<input
										id="event_image"
										name="event_image"
										type="file"
										accept="image/*"
										onChange={(e: any) => filechange(e, 'event')}
									/>
									<img
										width="200px"
										height="100px"
										src={eventImage?.eventImagePreviewUrl ? eventImage.eventImagePreviewUrl : values.event_image}
										alt=""
									/>
								</Box>
							</Grid>
							<Grid container item xs={12} md={12}>
								<TextField
									id="event_description"
									name="event_description"
									multiline
									label="Event Description"
									variant="outlined"
									error={error.event_description.error}
									helperText={error.event_description.message}
									rows="5"
									defaultValue={values.event_description}
									onChange={e => handleChange('event_description', e.target.value)}
									fullWidth
									color="secondary"
								/>
							</Grid>
							<Grid container item xs={12} md={12} className={classes.textcenter}>
								<Button variant="contained" color="secondary" disabled={error.disable} onClick={handleClick}>
									Update
								</Button>
								<SimpleDialog open={open} onClose={handleClose} message={message} />
							</Grid>
						</Grid>
					</form>
				</div>
				<SimpleDialog open={open} onClose={() => setOpen(false)} message={message} />
			</Box>
		</>
	);
};

export default Home;

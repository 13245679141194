import { DialogContent, DialogContentText, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

interface ISuggestionDialogProps {
	open: boolean;
	onClose: () => void;
	message: string;
	data: any;
}

const SuggestionDialog = (props: ISuggestionDialogProps) => {
	const { onClose, open, message, data } = props;
	const handleClose = () => {
		onClose();
	};

	return (
		<Dialog onClose={handleClose} aria-labelledby="Suggestion-dialog-title" open={open}>
			<DialogTitle id="Suggestion-dialog-title" style={{ textAlign: 'center' }}>
				Suggestion Detail
				<hr />
			</DialogTitle>

			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					<Grid container>
						<Grid xs={4} md={6}>
							<b>Name:</b> {data?.first_name}
							{data?.last_name}
						</Grid>
						<Grid xs={4} md={6}>
							<b>Email:</b> {data?.email}
						</Grid>
						<Grid xs={4} md={6}>
							<b>Type:</b> {data?.type}
						</Grid>

						<Grid xs={4} md={6}>
							<b>Artist Name:</b> {data?.artist_name}
						</Grid>
						<Grid xs={4} md={6}>
							<b>Artist Contact:</b> {data?.artist_contact}
						</Grid>
						<Grid xs={4} md={6}>
							<b>Event Date:</b> {data?.event_date}
						</Grid>

						<Grid xs={4} md={12}>
							<b>Created Date:</b> {data?.created_at}
						</Grid>
						<Grid xs={4} md={12}>
							<hr />
						</Grid>

						<Grid xs={4} md={12}>
							<b>Description:</b> {data?.description}
						</Grid>
					</Grid>
				</DialogContentText>
			</DialogContent>

			<DialogActions>
				<Button onClick={handleClose}>OK</Button>
			</DialogActions>
		</Dialog>
	);
};
export default SuggestionDialog;

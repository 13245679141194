import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import SimpleDialog from '../basic/dialoges/simpleDialog';
import { StateContext } from '../generalFunctions/context';
import { setHeaders } from '../generalFunctions/requestheaders';

const NAME_MAX_LENGTH = 20;
const TITLE_MAX_LENGTH = 100;
const SUB_TITLE_MAX_LENGTH = 255;

const About: React.FC = () => {
	const { storeData } = useContext(StateContext);
	const [open, setOpen] = React.useState(false);
	const [message, setMessage] = React.useState('Done');
	const [submitrequest, setSubmitRequest] = useState('post');
	const [error, setError] = useState({
		disable: false,
		pageName: {
			error: false,
			message: ''
		},
		subtitle: {
			error: false,
			message: ''
		},
		title: {
			error: false,
			message: ''
		}
	});
	const [values, setValues] = useState({
		content: '',
		id: 0,
		pageName: '',
		subtitle: '',
		title: ''
	});
	useEffect(() => {
		axios
			.post(process.env.REACT_APP_API_URL + 'about/list', {
				headers: setHeaders(storeData.Token)
			})
			.then(response => {
				if (response.data.total_items > 0) {
					const data = {
						content: response.data.items[0].body === null ? '' : response.data.items[0].body,
						id: response.data.items[0].id,
						pageName: response.data.items[0].page_name === null ? '' : response.data.items[0].page_name,
						subtitle: response.data.items[0].subtitle === null ? '' : response.data.items[0].subtitle,
						title: response.data.items[0].title === null ? '' : response.data.items[0].title
					};
					setValues(data);
					setSubmitRequest('put');
				}
			})
			.catch(e => {
				handleClickOpen();
				setMessage('no internet connection');
			});
	}, [storeData.Token]);

	const handleChange = (id: string, value: string) => {
		let newvalues = { ...values };
		let newerror = { ...error };
		switch (id) {
			case 'page name':
				newvalues = { ...newvalues, pageName: value };
				setValues(newvalues);
				newerror = { ...newerror, pageName: { error: false, message: '' }, disable: false };
				setError(newerror);
				if (value.length > NAME_MAX_LENGTH) {
					newerror = {
						...newerror,
						disable: true,
						pageName: { error: true, message: `value is too long (can't be more than ${NAME_MAX_LENGTH} characters)` }
					};
					setError(newerror);
				}
				break;
			case 'page title':
				newvalues = { ...newvalues, title: value };
				setValues(newvalues);
				newerror = { ...newerror, title: { error: false, message: '' }, disable: false };
				setError(newerror);
				if (value.length > TITLE_MAX_LENGTH) {
					newerror = {
						...newerror,
						disable: true,
						title: { error: true, message: `value is too long (can't be more than ${TITLE_MAX_LENGTH} characters)` }
					};
					setError(newerror);
				}
				break;
			case 'page subtitle':
				newvalues = { ...newvalues, subtitle: value };
				setValues(newvalues);
				newerror = { ...newerror, subtitle: { error: false, message: '' }, disable: false };
				setError(newerror);
				if (value.length > SUB_TITLE_MAX_LENGTH) {
					newerror = {
						...newerror,
						disable: true,
						subtitle: {
							error: true,
							message: `value is too long (can't be more than ${SUB_TITLE_MAX_LENGTH} characters)`
						}
					};
					setError(newerror);
				}
				break;
			case 'page content':
				newvalues = { ...newvalues, content: value };
				setValues(newvalues);
				break;
		}
	};
	const handleClick = () => {
		const data = {
			body: values.content.length > 0 ? values.content : undefined,
			page_name: values.pageName.length > 0 ? values.pageName : undefined,
			subtitle: values.subtitle.length > 0 ? values.subtitle : undefined,
			title: values.title.length > 0 ? values.title : undefined
		};
		if (submitrequest === 'post') {
			axios
				.post(process.env.REACT_APP_API_URL + `about`, data, {
					headers: setHeaders(storeData.Token)
				})
				.then(response => {
					handleClickOpen();
				})
				.catch(e => {
					setMessage('Something went wrong.');
					handleClickOpen();
				});
		} else {
			axios
				.put(process.env.REACT_APP_API_URL + `about/${values.id}`, data, {
					headers: setHeaders(storeData.Token)
				})
				.then(response => {
					handleClickOpen();
				})
				.catch(e => {
					setMessage('Something went wrong.');
					handleClickOpen();
				});
		}
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Box display={'flex'} flexDirection={'column'} alignItems="center">
			<Typography align="center" variant="h4">
				About
			</Typography>
			<TextField
				id="page name"
				label="page name"
				variant="outlined"
				placeholder="page name"
				error={error.pageName.error}
				helperText={error.pageName.message}
				value={values.pageName}
				onChange={e => handleChange('page name', e.target.value)}
				fullWidth
				color="secondary"
				style={{ marginBottom: '16px' }}
			/>

			<TextField
				id="page title"
				multiline
				label="page title"
				variant="outlined"
				placeholder="page title"
				rows="2"
				error={error.title.error}
				helperText={error.title.message}
				value={values.title}
				onChange={e => handleChange('page title', e.target.value)}
				fullWidth
				color="secondary"
			/>

			<TextField
				id="page subtitle"
				multiline
				label="page subtitle"
				variant="outlined"
				placeholder="page subtitle"
				rows="5"
				error={error.subtitle.error}
				helperText={error.subtitle.message}
				value={values.subtitle}
				onChange={e => handleChange('page subtitle', e.target.value)}
				fullWidth
				color="secondary"
			/>

			<TextField
				id="page content"
				multiline
				label="page content"
				variant="outlined"
				placeholder="page content"
				rows="10"
				value={values.content}
				onChange={e => handleChange('page content', e.target.value)}
				fullWidth
				color="secondary"
			/>

			<Button variant="contained" color="secondary" disabled={error.disable} onClick={handleClick}>
				Update
			</Button>
			<SimpleDialog open={open} onClose={handleClose} message={message} />
		</Box>
	);
};

export default About;

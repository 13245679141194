import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import SimpleDialog from '../basic/dialoges/simpleDialog';
import { StateContext } from '../generalFunctions/context';
import { setHeaders } from '../generalFunctions/requestheaders';

const PersonalTracking: React.FC = () => {
	const { storeData } = useContext(StateContext);

	const [emails, setEmails] = useState({ email: '', alternate: '' });
	const [errors, setErrors] = useState({ email: false, alternate: false });
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState('');

	const handleSend = () => {
		if (emails.email.trim() === '') {
			setErrors({ ...errors, email: true });
			return;
		}
		axios
			.post(
				process.env.REACT_APP_API_URL + `users/information`,
				{ email: emails.email, alternate_email: emails.alternate },
				{
					headers: setHeaders(storeData.Token)
				}
			)
			.then(res => {
				setMessage('User data sent to the E-mial you added');
				setOpen(true);
			})
			.catch(e => {
				setMessage('error');
				setOpen(true);
			});
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={6}>
				<Grid container alignItems="center">
					<Grid item xs={4}>
						<InputLabel htmlFor="size">User's Email *</InputLabel>
					</Grid>
					<Grid item xs={8}>
						<TextField
							placeholder="User's Email"
							autoComplete="off"
							id="email"
							name="email"
							variant="outlined"
							color="secondary"
							fullWidth
							style={{ margin: 0 }}
							error={errors.email}
							helperText={errors.email && 'Email is required'}
							value={emails.email}
							onChange={e => {
								setEmails({ ...emails, email: e.target.value });
								setErrors({ ...errors, email: e.target.value.trim() === '' ? true : false });
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={6}>
				<Grid container alignItems="center">
					<Grid item xs={4}>
						<InputLabel htmlFor="size">Alternate Email</InputLabel>
					</Grid>
					<Grid item xs={8}>
						<TextField
							placeholder="Alternate Email"
							autoComplete="off"
							id="alternate"
							name="alternate"
							variant="outlined"
							color="secondary"
							style={{ margin: 0 }}
							fullWidth
							value={emails.alternate}
							onChange={e => setEmails({ ...emails, alternate: e.target.value })}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Grid container justify="center">
					<Button variant="contained" color="primary" onClick={handleSend}>
						send
					</Button>
				</Grid>
			</Grid>
			<SimpleDialog open={open} message={message} onClose={() => setOpen(false)} />
		</Grid>
	);
};

export default PersonalTracking;

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import React from 'react';
import { theme } from '../../../theme/index';
import { useStyles } from './notificationTable';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

export interface ITable {
	rows: Array<{
		id: string;
		data: {
			title: string;
			author: string;
			status: string;
			publish_date: string;
		};
	}>;
	total: number;
	pagedata: (pagenum: number) => void;
	handleView: (id: string) => void;
	deleteBlog: (id: string) => void;
	page: number;
}

interface ITablePaginationActionsProps {
	count: number;
	page: number;
	rowsPerPage: number;
	onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: ITablePaginationActionsProps) {
	const { count, page, rowsPerPage, onChangePage } = props;

	const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, 0);
	};

	const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, page - 1);
	};

	const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, page + 1);
	};

	const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box flexShrink="0" marginLeft={theme.spacing(2.5)}>
			<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
				{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

export default function CustomizedTables(props: ITable) {
	const classes = useStyles();

	const { rows, total, pagedata, page, handleView, deleteBlog } = props;

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		pagedata(newPage + 1);
	};

	return (
		<Box marginTop="10px" width="100%">
			<Table aria-label="customized table">
				<TableHead>
					<TableRow>
						<TableCell align="left">Title</TableCell>
						<TableCell align="left">author</TableCell>
						<TableCell align="left">Status</TableCell>
						<TableCell align="right">Publish Date</TableCell>
						<TableCell align="right">Action</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map(row => (
						<TableRow className={classes.clickableTable} key={row.id}>
							<TableCell align="left" component="th" scope="row">
								{row.data.title}
							</TableCell>
							<TableCell align="left" component="th" scope="row">
								{row.data.author}
							</TableCell>
							<TableCell align="left" component="th" scope="row">
								{row.data.status}
							</TableCell>
							<TableCell align="right" component="th" scope="row">
								{row.data.publish_date}
							</TableCell>
							<TableCell align="right" component="th" scope="row">
								<IconButton onClick={() => handleView(row.id)} aria-label="first page">
									<EditOutlinedIcon />
								</IconButton>

								<IconButton onClick={() => deleteBlog(row.id)} aria-label="first page">
									<DeleteOutlinedIcon />
								</IconButton>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[]}
							count={total}
							rowsPerPage={10}
							page={page}
							SelectProps={{
								inputProps: { 'aria-label': 'rows per page' },
								native: true
							}}
							onChangePage={handleChangePage}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</Box>
	);
}

import DateFnsUtils from '@date-io/date-fns';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import axios from 'axios';
import 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Table from '../basic/tables/requestTable';
import { StateContext } from '../generalFunctions/context';
import { setHeaders } from '../generalFunctions/requestheaders';
import Dialoge from '../basic/dialoges/simpleDialog';

const Payments: React.FC = () => {
	const { storeData } = useContext(StateContext);
	const [totalPayments, setTotalPayments] = useState(0);
	const [totalBands, setTotalBands] = useState(0);
	const [filterBand, setFilterBand] = useState([{ id: '', name: '' }]);
	const [filterpayments, setFilterPayments] = useState([{ id: '', title: '' }]);
	const [textFilters, setTextFilters] = useState({ band: '', event: '' });
	const [filter, setFilter] = useState({
		band: { name: '' },
		date_from: '',
		date_to: '',
		event: { name: '' }
	});
	const [tableContent, setTableContent] = useState([
		{ id: '', data: { booker: '', event: '', talent: '', created_at: '' } }
	]);
	const [page, setPage] = useState(1);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [message, setMessage] = React.useState('');
	const history = useHistory();

	const [selectedDateStart, setSelectedDateStart] = React.useState<Date | null>(null);
	const [selectedDateEnd, setSelectedDateEnd] = React.useState<Date | null>(null);
	const [open, setOpen] = useState({ from: false, to: false });

	useEffect(() => {
		axios
			.post(
				process.env.REACT_APP_API_URL + 'payment/list',
				{ page, filter },
				{
					headers: setHeaders(storeData.Token)
				}
			)
			.then(response => {
				const data = response.data;
				setTotalPayments(data.total_items);
				const tabledata = data.items.map((item: any) => {
					return {
						data: {
							booker: item.request.created_by.fname + ' ' + item.request.created_by.lname,
							created_at: new Date(item.created_at * 1000).toLocaleString(),
							event: item.request.event.title,
							talent: item.request.band.name
						},
						id: item.id
					};
				});
				setTableContent(tabledata);
				return axios.post(
					process.env.REACT_APP_API_URL + 'payment/list',
					{ page: 1, items_per_page: totalPayments },
					{
						headers: setHeaders(storeData.Token)
					}
				);
			})
			.then(response => {
				const data = response.data.items.map((item: any) => {
					return { id: item.id, title: item.request.event.title };
				});
				setFilterPayments(data);
			})
			.catch((e: any) => {
				setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
				setDialogOpen(true);
			});
		axios
			.post(
				process.env.REACT_APP_API_URL + 'bands/list',
				{ page },
				{
					headers: setHeaders(storeData.Token)
				}
			)
			.then(response => {
				const data = response.data;
				setTotalBands(data.total_items);
				return axios.post(
					process.env.REACT_APP_API_URL + 'bands/list',
					{ page: 1, items_per_page: totalBands },
					{
						headers: setHeaders(storeData.Token)
					}
				);
			})
			.then(response => {
				const data = response.data.items.map((item: any) => {
					return { id: item.id, name: item.name };
				});
				setFilterBand(data);
			})
			.catch((e: any) => {
				setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
				setDialogOpen(true);
			});
	}, [page, storeData.Token, totalPayments, totalBands, filter]);

	const handleDateChange = (date: Date | null, type: string) => {
		if (type === 'start') {
			setSelectedDateStart(date);
		} else {
			setSelectedDateEnd(date);
		}
	};

	const handleFilterChange = (val: string, type: string) => {
		let newFilter;
		if (type === 'band') {
			newFilter = { ...textFilters, band: val };
			setTextFilters(newFilter);
		} else {
			newFilter = { ...textFilters, event: val };
			setTextFilters(newFilter);
		}
	};

	const handleView = (id: string) => {
		history.push({
			pathname: history.location.pathname + `/${id}`
		});
	};

	const handleSearch = () => {
		const filterdata = {
			band: { name: textFilters.band },
			date_from: selectedDateStart
				? selectedDateStart.getFullYear() + '-' + (selectedDateStart.getMonth() + 1) + '-' + selectedDateStart.getDate()
				: '',
			date_to: selectedDateEnd
				? selectedDateEnd.getFullYear() + '-' + (selectedDateEnd.getMonth() + 1) + '-' + selectedDateEnd.getDate()
				: '',
			event: { name: textFilters.event }
		};
		setFilter(filterdata);
	};

	const handleClear = () => {
		setTextFilters({ band: '', event: '' });
		setSelectedDateStart(null);
		setSelectedDateEnd(null);
		setFilter({
			band: { name: '' },
			date_from: '',
			date_to: '',
			event: { name: '' }
		});
	};

	return (
		<Box display="flex" flexDirection="column">
			<Typography align="center" variant="h4">
				Payments
			</Typography>
			<Grid container justify="flex-start" wrap="nowrap">
				<Grid container direction="row" style={{ width: '50%' }} justify="space-evenly" wrap="nowrap">
					<Autocomplete
						inputValue={textFilters.band}
						onChange={(event: any, value: any) => handleFilterChange(value, 'band')}
						freeSolo
						id="combo-box-band"
						options={filterBand ? filterBand.map((option: any) => option.name) : ['']}
						style={{ width: 250, marginRight: 20 }}
						renderInput={(params: any) => (
							<TextField
								{...params}
								value={textFilters.band}
								onChange={e => handleFilterChange(e.target.value, 'band')}
								label="Talent Name"
								variant="outlined"
								fullWidth
							/>
						)}
					/>
					<Autocomplete
						inputValue={textFilters.event}
						onChange={(event: any, value: any) => handleFilterChange(value, 'event')}
						freeSolo
						id="combo-box-event"
						options={filterpayments ? filterpayments.map((option: any) => option.title) : ['']}
						style={{ width: 250, marginRight: 20 }}
						renderInput={(params: any) => (
							<TextField
								{...params}
								onChange={e => handleFilterChange(e.target.value, 'event')}
								value={textFilters.event}
								label="Event Name"
								variant="outlined"
								fullWidth
							/>
						)}
					/>
				</Grid>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<Box
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
						width="50%"
						marginRight="40px"
						alignItems="flex-end"
					>
						<Typography paragraph>Payment Date:</Typography>
						<KeyboardDatePicker
							disableToolbar
							variant="inline"
							format="MM/dd/yyyy"
							margin="dense"
							id="date-picker-start"
							label="From"
							value={selectedDateStart}
							maxDate={selectedDateEnd || Date.now()}
							onChange={d => handleDateChange(d, 'start')}
							KeyboardButtonProps={{
								'aria-label': 'change date',
								onClickCapture: () => setOpen({ ...open, from: true })
							}}
							PopoverProps={{
								open: open.from,
								onBackdropClick: () => setOpen({ ...open, from: false }),
								onEscapeKeyDown: () => setOpen({ ...open, from: false })
							}}
							onAccept={() => setOpen({ ...open, from: false })}
						/>
						<KeyboardDatePicker
							disableToolbar
							variant="inline"
							format="MM/dd/yyyy"
							margin="dense"
							id="date-picker-end"
							label="To"
							value={selectedDateEnd}
							minDate={selectedDateStart || Date.now()}
							maxDate={Date.now()}
							onChange={d => handleDateChange(d, 'end')}
							KeyboardButtonProps={{
								'aria-label': 'change date',
								onClickCapture: () => setOpen({ ...open, to: true })
							}}
							PopoverProps={{
								open: open.to,
								onBackdropClick: () => setOpen({ ...open, to: false }),
								onEscapeKeyDown: () => setOpen({ ...open, to: false })
							}}
							onAccept={() => setOpen({ ...open, to: false })}
						/>
					</Box>
				</MuiPickersUtilsProvider>
			</Grid>
			<Box display="flex" justifyContent="flex-end" paddingRight="50px">
				<Button variant="contained" color="secondary" onClick={handleSearch}>
					Filter
				</Button>
				<Button variant="contained" onClick={handleClear}>
					Clear
				</Button>
			</Box>
			{tableContent ? (
				<Table page={page - 1} rows={tableContent} total={totalPayments} pagedata={setPage} handleView={handleView} />
			) : (
				<> </>
			)}
			<Dialoge message={message} onClose={() => setDialogOpen(false)} open={dialogOpen} />
		</Box>
	);
};

export default Payments;

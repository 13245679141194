import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import moment from 'moment';
import React from 'react';
import { theme } from '../../../theme/index';
import ReasonsDialoge from '../dialoges/reasonsDialoge';
import { useStyles } from './notificationTable';

export interface ITable {
	rows: any; //Array<{ id: string; data: string; status: string }>;
	title: string;
	total: number;
	role?: string;
	handleDecline: (id: string, reasons: string) => void;
	handleApprove: (id: string) => void;
	pagedata: (pagenum: number) => void;
	handleView: (id: string) => void;
	page: number;
}

interface ITablePaginationActionsProps {
	count: number;
	page: number;
	rowsPerPage: number;
	onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: ITablePaginationActionsProps) {
	const { count, page, rowsPerPage, onChangePage } = props;

	const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, 0);
	};

	const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, page - 1);
	};

	const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, page + 1);
	};

	const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box flexShrink="0" marginLeft={theme.spacing(2.5)}>
			<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
				{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

export default function CustomizedTables(props: ITable) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [id, setId] = React.useState('');
	const { role, title, rows, handleDecline, handleApprove, total, pagedata, page, handleView } = props;

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		pagedata(newPage + 1);
	};

	const handleDialogClose = (action: { type: string; reasons: string }, rowid: string) => {
		if (action.type === 'delete') {
			handleDecline(rowid, action.reasons);
		}
		setOpen(false);
	};
	const Decline = (idrow: string) => {
		setId(idrow);
		setOpen(true);
	};

	return (
		<Box marginTop="10px" width="100%">
			<Table aria-label="customized table">
				<TableHead>
					<TableRow>
						<TableCell align="left">{title}</TableCell>
						{(role == 'BOOKER' || role == 'AGENT') && (
							<>
								<TableCell align="left">Email</TableCell>
								<TableCell align="left">Created Date</TableCell>
							</>
						)}
						<TableCell align="left">Status</TableCell>
						<TableCell align="right">Controls</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows?.map((row: any) => (
						<TableRow className={classes.clickableTable} key={row.id}>
							<TableCell align="left" component="th" scope="row" onClick={() => handleView(row.id)}>
								{row.data}
							</TableCell>
							{(role == 'BOOKER' || role == 'AGENT') && (
								<>
									<TableCell align="left">{row.email}</TableCell>
									<TableCell align="left">{moment(parseInt(row.created_at)).format('MM/DD/YYYY LT')}</TableCell>
								</>
							)}
							<TableCell align="left">
								{row.status === 'NOT_VIRIFIED' ? (
									<>Pending email verification</>
								) : row.status === 'APPROVED' ? (
									<a style={{ color: 'green' }}>Approved</a>
								) : row.status === 'MISSING_PAYMENT' ? (
									<a style={{ color: '#1A5276' }}>Missing Payment</a>
								) : row.status === 'VIRIFIED' ? (
									<>Email Verified, Pending Profile and/or Billing Info</>
								) : row.status === 'DECLINED' ? (
									<a style={{ color: 'red' }}>Declined</a>
								) : row.status === 'PENDING_APPROVAL' ? (
									<a style={{ color: '#1A5276' }}>Pending Approval</a>
								) : row.status === 'PENDING' ? (
									<a style={{ color: '#1A5276' }}>Pending Approval</a>
								) : (
									<>Unknown</>
								)}
							</TableCell>
							{row.status === 'PENDING_APPROVAL' || row.status === 'PENDING' || row.status === 'APPROVED' ? (
								<TableCell align="right">
									{row.status !== 'APPROVED' && (
										<Button
											className={classes.controls}
											variant="contained"
											color="secondary"
											onClick={() => handleApprove(row.id)}
										>
											Approve
										</Button>
									)}
									<Button className={classes.controls} variant="contained" onClick={() => Decline(row.id)}>
										Decline
									</Button>
								</TableCell>
							) : (
								<TableCell></TableCell>
							)}
						</TableRow>
					))}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[]}
							count={total}
							rowsPerPage={10}
							page={page}
							SelectProps={{
								inputProps: { 'aria-label': 'rows per page' },
								native: true
							}}
							onChangePage={handleChangePage}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Table>
			<ReasonsDialoge open={open} id={id} onClose={handleDialogClose} />
		</Box>
	);
}

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Dialoge from '../basic/dialoges/simpleDialog';
import { StateContext } from '../generalFunctions/context';
import { setHeaders } from '../generalFunctions/requestheaders';

export default function Page() {
	const history = useHistory();
	const { storeData } = useContext(StateContext);
	const [data, setData] = useState({ title: '', description: '', created_by: { fname: '', lname: '' }, created_at: 0 });
	const [open, setOpen] = React.useState(false);
	const [message, setMessage] = React.useState('');
	const { id } = useParams();

	useEffect(() => {
		axios
			.get(process.env.REACT_APP_API_URL + `notifications/promotional-show/${id}`, {
				headers: setHeaders(storeData.Token)
			})
			.then(response => {
				setData(response.data.item);
			})
			.catch(e => {
				setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
				setOpen(true);
			});
	}, [id, storeData.Token]);

	const handleBackButtonClick = () => {
		history.push('/notifications');
	};

	return (
		<Box>
			<Box display="flex" justifyContent="flex-start">
				<IconButton onClick={handleBackButtonClick} aria-label="previous page">
					<KeyboardArrowLeft />
				</IconButton>
			</Box>
			{data ? (
				<Box paddingLeft="20px">
					<Typography variant="h5">Title: {data.title}</Typography>
					<Typography variant="h5">Description: {data.description}</Typography>
					<Typography variant="h5">
						Created By: {data.created_by.fname} {data.created_by.lname}
					</Typography>
					<Typography variant="h5">Created At: {new Date(data.created_at * 1000).toLocaleString()}</Typography>
				</Box>
			) : (
				<> </>
			)}
			<Dialoge message={message} onClose={() => setOpen(false)} open={open} />
		</Box>
	);
}

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import React from 'react';
import { theme } from '../../../theme/index';
import { useStyles } from '../../staticFields/bookerTypes';

export interface ITable {
	rows: Array<{
		id: string;
		name: string;
		account_type: string;
		ticket_type: string;
		details: string;
		status: string;
		date: any;
	}>;
	total: number;
	pagedata: (pagenum: number) => void;
	page: number;
	changeStatus: (status: string, id: string) => void;
	loading: boolean;
}

const statuses = ['new', 'in progress', 'resolved'];

const changeText = (status: string) => {
	switch (status) {
		case 'in progress':
			return 'In-Progress';
		case 'resolved':
			return 'Resolved';
		case 'NEW':
			return 'New';
	}
};

interface ITablePaginationActionsProps {
	count: number;
	page: number;
	rowsPerPage: number;
	onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: ITablePaginationActionsProps) {
	const { count, page, rowsPerPage, onChangePage } = props;

	const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, 0);
	};

	const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, page - 1);
	};

	const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, page + 1);
	};

	const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box flexShrink="0" marginLeft={theme.spacing(7)}>
			<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
				{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

export default function CustomizedTables(props: ITable) {
	const classes = useStyles();

	const { rows, total, pagedata, page, changeStatus, loading } = props;

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		pagedata(newPage + 1);
	};

	return (
		<Box marginTop="10px" width="100%">
			<Table aria-label="customized table">
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell>Account Type</TableCell>
						<TableCell>Ticket Type</TableCell>
						<TableCell>Details</TableCell>
						<TableCell>Status</TableCell>
						<TableCell>Date of Profile Creation</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map(row => {
						const index = statuses.indexOf(row.status);
						const allowed = statuses.slice(index + 1);
						return (
							<TableRow key={row.id}>
								<TableCell component="th" scope="row">
									{row.name}
								</TableCell>
								<TableCell component="th" scope="row">
									{row.account_type}
								</TableCell>
								<TableCell component="th" scope="row">
									{row.ticket_type}
								</TableCell>
								<TableCell component="th" scope="row">
									{row.details}
								</TableCell>
								<TableCell component="th" scope="row">
									<Select
										value={allowed.indexOf(row.status) !== -1 ? allowed.indexOf(row.status) : 99}
										id="state"
										name="state"
										variant="outlined"
										disabled={loading}
										MenuProps={{
											anchorOrigin: {
												horizontal: 'left',
												vertical: 'bottom'
											},
											getContentAnchorEl: null,
											transformOrigin: {
												horizontal: 'left',
												vertical: 'top'
											}
										}}
										onChange={(e: any) => changeStatus(allowed[e.target.value], row.id)}
									>
										<MenuItem disabled value={99}>
											{changeText(row.status)}
										</MenuItem>
										{allowed.map((item: any, i: number) => (
											<MenuItem className={classes.menu} key={item} value={i}>
												{changeText(item)}
											</MenuItem>
										))}
									</Select>
								</TableCell>
								<TableCell component="th" scope="row">
									{new Date(row.date * 1000).toLocaleString()}
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[]}
							count={total}
							rowsPerPage={10}
							page={page}
							SelectProps={{
								inputProps: { 'aria-label': 'rows per page' },
								native: true
							}}
							onChangePage={handleChangePage}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</Box>
	);
}

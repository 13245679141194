import { Avatar, Box, Button, Divider, Grid, IconButton, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useRef } from 'react';
import Linkify from 'react-linkify';
import { useHistory, useParams } from 'react-router-dom';
import ImagePlaceholder from '../../Assets/ImagePlaceholder.png';
import { StateContext } from '../generalFunctions/context';
import { setHeaders } from '../generalFunctions/requestheaders';
import { ReplyForm } from './replyForm';

interface IFilters {
	date_to: number | null;
	date_from: number | null;
	event_request_id: number;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		ADMIN: {
			height: 70,
			margin: 'auto',
			width: 70
		},
		BOOKER: {
			border: `5px solid ${theme.palette.primary.main}`,
			height: 70,
			margin: 'auto',
			width: 70
		},
		Layout: {
			flexDirection: 'row-reverse',
			textAlign: 'end'
		},
		TALENT: {
			border: `5px solid ${theme.palette.secondary.main}`,
			height: 70,
			margin: 'auto',
			width: 70
		},
		divider: {
			backgroundColor: theme.palette.secondary.main,
			opacity: 0.5
		},
		message: {
			width: '413px',
			'word-wrap': 'break-word'
		},
		spacing: {
			margin: '-16px'
		}
	})
);

const intialPagination = {
	page: 1,
	total_items: 1
};

const Conversation: React.FC = () => {
	const classes = useStyles();
	const { id } = useParams();
	const history = useHistory();
	const { storeData } = useContext(StateContext);
	const [loadOlderLoading, setLoadOlderLoading] = React.useState<boolean>(false);

	// Handle Conversations API Data
	const [data, setData] = React.useState<any[]>([]);
	const [replyData, setReplyData] = React.useState<any[]>([]);
	const [loading, setLoading] = React.useState<boolean>(true);
	const [listAll, setListAll] = React.useState<boolean>(true);
	const [error, setError] = React.useState();

	const [itemsPerPage, setItemsPerPage] = React.useState<number>(5);
	// Handle Conversations Pagination
	const [pagination, setPagination] = React.useState<{ page: number; total_items: number }>(intialPagination);

	const [noMoreFound, setNoMoreFound] = React.useState<boolean>(false);

	const timeOut = useRef<any>();

	React.useEffect(() => {
		if (!storeData.user) {
			axios({
				headers: setHeaders(storeData.Token),
				method: 'POST',
				url: process.env.REACT_APP_API_URL + 'users/signout'
			}).then(res => {
				localStorage.clear();
				window.location.reload();
			});
		}
		timeOut.current = setTimeout(() => setListAll(!listAll), 5000);
		axios
			.post(
				process.env.REACT_APP_API_URL + 'messages/requests',
				{
					filter: {
						date_from: null,
						date_to: Date.now(),
						event_request_id: Number(id)
					},
					items_per_page: itemsPerPage,
					page: 1
				},
				{
					headers: setHeaders(storeData.Token)
				}
			)
			.then((res: any) => {
				const filteredData = res.data.items.map((item: any) => {
					return { ...item, sent: true };
				});
				if (filteredData.length === 0) {
					setNoMoreFound(true);
				}
				setData(filteredData.reverse());
				setPagination({ page: res.data.page, total_items: res.data.total_items });
				setLoading(false);
				setLoadOlderLoading(false);
			})
			.catch((err: any) => {
				// Failed to Fetch
				setError(err);
				setLoading(false);
				setLoadOlderLoading(false);
			});
		return () => clearTimeout(timeOut.current);
	}, [id, itemsPerPage, listAll, storeData.Token, storeData.user]);

	if (loading) {
		return <div>laoding...</div>;
	}

	if (error) {
		return <div>error</div>;
	}

	const reply = (message: string) => {
		clearTimeout(timeOut.current);
		const lastID = data[data.length - 1].id + 1;
		const replyObject = {
			// Date.now() get timestamp in millisecondds, divide by 1000 to convert to seconds
			created_at: Date.now() / 1000,
			created_by: {
				email: storeData.user.email,
				fname: storeData.user.fname,
				id: storeData.user.id,
				lname: storeData.user.lname,
				photo: storeData.user.photo,
				role: storeData.user.role
			},
			event_request_id: id,
			id: lastID,
			message_text: message,
			parent_id: 0,
			request_band: {
				band: {
					name: data[0].request_band?.band.name,
					photo: { thumbnail: data[0].request_band?.band.photo?.thumbnail } || null
				},
				event: {
					created_by: {
						fname: data[0].request_band?.event.created_by.fname,
						photo: { thumbnail: data[0].request_band?.band.photo?.thumbnail } || null
					}
				}
			},
			sent: false
		};
		setReplyData((prevState: any[]) => {
			return [...prevState, replyObject];
		});
		// Call Reply API
		axios
			.post(
				process.env.REACT_APP_API_URL + 'messages',
				{ event_request_id: Number(id), message_text: message, parent_id: 0 },
				{
					headers: setHeaders(storeData.Token)
				}
			)
			.then((res: any) => {
				setData((prevState: any[]) => {
					return [
						...prevState,
						{
							...res.data.item,
							request_band: {
								band: {
									name: data[0].request_band?.band.name,
									photo: { thumbnail: data[0].request_band?.band.photo?.thumbnail } || null
								},
								event: {
									created_by: {
										fname: data[0].request_band?.event.created_by.fname,
										photo: { thumbnail: data[0].request_band?.band.photo?.thumbnail } || null
									}
								}
							}
						}
					];
				});
				const rest = replyData.filter(item => item.id !== res.item.id);
				setReplyData(rest);
			})
			.catch((err: any) => {
				console.log(err.response);
				console.log('err', err);
			});
	};

	const componentDecorator = (href: string, text: string, key: number) => (
		<a href={href} key={key} target="_blank" rel="noopener noreferrer">
			{text}
		</a>
	);

	const sendNew = (message: string) => {
		axios
			.post(
				process.env.REACT_APP_API_URL + 'messages',
				{ event_request_id: Number(id), message_text: message, parent_id: 0 },
				{
					headers: setHeaders(storeData.Token)
				}
			)
			.then(res => {
				setListAll(!listAll);
			})
			.catch(e => {
				console.log(e);
			});
	};

	return (
		<>
			<Box>
				<IconButton onClick={() => history.goBack()} aria-label="previous page">
					<KeyboardArrowLeft />
				</IconButton>
			</Box>
			<Grid container direction="column" className={classes.spacing}>
				<Grid item>
					<Grid container alignItems="center" spacing={4}>
						{data && data.length < pagination.total_items && (
							<Grid item>
								<Button
									variant="contained"
									color="secondary"
									disabled={noMoreFound || loadOlderLoading}
									// onClick change date_to field to the oldest message's created_at field
									onClick={() => {
										setLoadOlderLoading(true);
										setItemsPerPage(prevState => prevState + 5);
									}}
									style={{ width: '250px' }}
								>
									Load Older Messages
								</Button>
							</Grid>
						)}
						{data.map((item: any) => {
							return (
								<Grid item xs={11} key={item.id}>
									<Grid
										container
										alignItems="center"
										spacing={3}
										className={item.created_by.id === storeData.user.id ? classes.Layout : ''}
									>
										{item.created_by.id === storeData.user.id ? (
											<>
												<Grid item xs={3} md={2} lg={2}>
													<Avatar
														alt="My thumbnail"
														src={storeData.user.photo?.thumbnail || ImagePlaceholder}
														className={classes.ADMIN}
													/>
												</Grid>
												<Grid item xs={9} md={7} lg={7}>
													<Grid container direction="column" alignItems="stretch">
														<Grid item>
															<Typography variant="subtitle2">{storeData.user.fname + '(Admin)'}</Typography>
														</Grid>
														<Grid item>
															{item.message_text.split('\n').map((line: string, index: number) => {
																return (
																	<div key={index}>
																		<Linkify key={index} componentDecorator={componentDecorator}>
																			{line}
																		</Linkify>
																	</div>
																);
															})}
														</Grid>
													</Grid>
												</Grid>
											</>
										) : (
											<>
												<Grid item xs={3} md={2} lg={2}>
													<Avatar
														alt="Other's thumbnail"
														src={
															item.created_by.role === 'TALENT'
																? item.request_band?.band.photo?.thumbnail || ImagePlaceholder
																: item.created_by.role === 'BOOKER'
																? item.request_band?.event.created_by?.photo?.thumbnail || ImagePlaceholder
																: item.created_by.photo?.thumbnail || ImagePlaceholder
														}
														className={
															item.created_by.role === 'TALENT'
																? classes.TALENT
																: item.created_by.role === 'BOOKER'
																? classes.BOOKER
																: classes.ADMIN
														}
													/>
												</Grid>
												<Grid item xs={9} md={7} lg={7}>
													<Grid container direction="column" alignItems="stretch">
														<Grid item>
															<Typography variant="subtitle2">
																{item.created_by.role === 'TALENT'
																	? item.request_band?.band.name
																	: item.created_by.role === 'BOOKER'
																	? item.request_band?.event.created_by.fname
																	: item.created_by.fname + '(Admin)'}
															</Typography>
														</Grid>
														<Grid item>
															{item.message_text.split('\n').map((line: string, index: number) => {
																return (
																	<div key={index}>
																		<Linkify key={index} componentDecorator={componentDecorator}>
																			{line}
																		</Linkify>
																	</div>
																);
															})}
														</Grid>
													</Grid>
												</Grid>
											</>
										)}
										<Box
											p={2}
											display="flex"
											flexGrow={1}
											justifyContent={item.created_by.id === id ? 'flex-start' : 'flex-end'}
										>
											{item.sent ? moment.unix(item.created_at).fromNow() : <ErrorIcon />}
										</Box>
									</Grid>
								</Grid>
							);
						})}
						{replyData.map(Reply => {
							return (
								<Grid item xs={11} key={Reply.id}>
									<Grid container alignItems="center" spacing={3} className={classes.Layout}>
										<Grid item xs={3} md={2} lg={2}>
											<Avatar
												alt="My thumbnail"
												src={storeData.user.photo?.thumbnail || ImagePlaceholder}
												className={classes.ADMIN}
											/>
										</Grid>
										<Grid item xs={9} md={7} lg={7}>
											<Grid container direction="column" alignItems="stretch">
												<Grid item>
													<Typography variant="subtitle2">{storeData.user.fname + '(Admin)'}</Typography>
												</Grid>
												<Grid item>
													{Reply.message_text.split('\n').map((line: string, index: number) => {
														return (
															<div key={index}>
																<Linkify key={index} componentDecorator={componentDecorator}>
																	{line}
																</Linkify>
															</div>
														);
													})}
												</Grid>
											</Grid>
										</Grid>
										<Box
											p={2}
											display="flex"
											flexGrow={1}
											justifyContent={Reply.created_by.id === id ? 'flex-start' : 'flex-end'}
										>
											{Reply.sent ? moment.unix(Reply.created_at).fromNow() : <ErrorIcon />}
										</Box>
									</Grid>
								</Grid>
							);
						})}
						<Grid item xs={3} md={2} lg={2}></Grid>
						<Grid item xs={9} md={9} lg={9}>
							<Divider variant="fullWidth" classes={{ root: classes.divider }} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<ReplyForm classes={classes} buttonText="Reply" sendMessage={data.length > 0 ? reply : sendNew} />
				</Grid>
			</Grid>
		</>
	);
};

export default Conversation;

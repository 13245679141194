import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

interface ISimpleDialogProps {
	open: boolean;
	onClose: () => void;
	message: string;
}

const SimpleDialog = (props: ISimpleDialogProps) => {
	const { onClose, open, message } = props;
	const handleClose = () => {
		onClose();
	};

	return (
		<Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
			<DialogTitle id="simple-dialog-title">{message}</DialogTitle>
			<DialogActions>
				<Button onClick={handleClose}>OK</Button>
			</DialogActions>
		</Dialog>
	);
};
export default SimpleDialog;

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SimpleDialog from '../basic/dialoges/simpleDialog';
import Table from '../basic/tables/notificationTable';
import { StateContext } from '../generalFunctions/context';
import { setHeaders } from '../generalFunctions/requestheaders';

const States: React.FC = () => {
	const { storeData } = useContext(StateContext);
	const [values, setValues] = useState({
		description: '',
		id: '',
		photo: { id: '', url: '' },
		title: ''
	});
	const [error, setError] = useState({
		description: { error: false, message: '', disable: true },
		title: { error: false, message: '', disable: true }
	});
	const [open, setOpen] = React.useState(false);
	const [message, setMessage] = React.useState('Done');
	const [listall, setListall] = useState(false);
	const [operation, setOperation] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [tableContent, setTableContent] = useState([{ id: '', title: '' }]);
	const [page, setPage] = useState(1);
	const history = useHistory();

	useEffect(() => {
		axios
			.post(
				process.env.REACT_APP_API_URL + 'notifications/promotional-list ',
				{
					page,
					sort: {
						by: 'created_at',
						dir: 'DESC'
					}
				},
				{
					headers: setHeaders(storeData.Token)
				}
			)
			.then(response => {
				if (response.data.items.length === 0 && page > 1) {
					setPage(prevState => prevState - 1);
				}
				const tabledata = response.data.items.map((item: any) => {
					return { id: item.id, title: item.title };
				});
				setTableContent(tabledata);
				setTotalCount(response.data.total_items);
			})
			.catch(e => {
				setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
				handleClickOpen();
			});
	}, [listall, page, storeData.Token]);

	const handladd = () => {
		setOperation(true);
		setTimeout(() => {
			window.scrollTo({
				behavior: 'smooth',
				top: 10000
			});
		}, 1);
	};

	const handleChange = (value: string, type: string) => {
		const TITLE_MAX_LENGTH = 150;
		let newvalues = { ...values };
		let newerror = { ...error };
		switch (type) {
			case 'title':
				newvalues = { ...newvalues, title: value };
				setValues(newvalues);
				newerror = { ...newerror, title: { error: false, message: '', disable: false } };
				setError(newerror);
				if (value.length > TITLE_MAX_LENGTH) {
					newerror = {
						...newerror,
						title: {
							disable: true,
							error: true,
							message: `value is too long (can't be more than ${TITLE_MAX_LENGTH} characters)`
						}
					};
					setError(newerror);
				} else if (value.trim().length === 0) {
					newerror = {
						...newerror,
						title: {
							disable: true,
							error: true,
							message: 'required'
						}
					};
					setError(newerror);
				}
				break;
			case 'sub':
				newvalues = { ...newvalues, description: value };
				setValues(newvalues);
				newerror = { ...newerror, description: { error: false, message: '', disable: false } };
				setError(newerror);
				if (value.trim().length === 0) {
					newerror = {
						...newerror,
						description: { error: true, message: 'required', disable: true }
					};
					setError(newerror);
				}
				break;
		}
	};

	const handleClick = () => {
		const requestData = {
			description: values.description,
			title: values.title
		};
		axios
			.post(process.env.REACT_APP_API_URL + `notification/promotional`, requestData, {
				headers: setHeaders(storeData.Token)
			})
			.then(response => {
				setMessage('Done');
				setOpen(true);
				setListall(!listall);
				setValues({ title: '', id: '', description: '', photo: { id: '', url: '' } });
				setTimeout(() => {
					window.scrollTo({
						behavior: 'smooth',
						top: 0
					});
				}, 100);
				setOperation(false);
			})
			.catch(e => {
				setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
				handleClickOpen();
			});
	};
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCancel = () => {
		setValues({ title: '', id: '', description: '', photo: { id: '', url: '' } });
		setOperation(false);
		setTimeout(() => {
			window.scrollTo({
				behavior: 'smooth',
				top: 0
			});
		}, 100);
	};

	const handleView = (id: string) => {
		history.push({
			pathname: history.location.pathname + `/${id}`
		});
	};

	return (
		<Box>
			<Typography align="center" variant="h4">
				Notifications
			</Typography>
			<Box display="flex" justifyContent="flex-end">
				<Button color="secondary" onClick={handladd}>
					<AddCircleIcon fontSize="large" />
				</Button>
			</Box>
			<Table page={page - 1} rows={tableContent} total={totalCount} pagedata={setPage} handleView={handleView} />
			{operation ? (
				<>
					<Divider />
					<Box display={'flex'} flexDirection={'column'} alignItems={'center'} paddingTop={'10px'} paddingBottom="5px">
						<TextField
							id="Title"
							label="Title"
							variant="outlined"
							placeholder="Title"
							error={error.title.error}
							helperText={error.title.message}
							value={values.title}
							onChange={e => handleChange(e.target.value, 'title')}
							fullWidth
							required
						/>
						<TextField
							id="Description"
							label="Description"
							variant="outlined"
							placeholder="Description"
							error={error.description.error}
							helperText={error.description.message}
							value={values.description}
							onChange={e => handleChange(e.target.value, 'sub')}
							fullWidth
							required
						/>
						<Box display="flex">
							<Button
								variant="contained"
								color="primary"
								disabled={error.title.disable || error.description.disable}
								onClick={handleClick}
							>
								send
							</Button>
							<Button variant="contained" onClick={handleCancel}>
								cancel
							</Button>
						</Box>
					</Box>
				</>
			) : (
				<></>
			)}
			<SimpleDialog open={open} onClose={handleClose} message={message} />
		</Box>
	);
};

export default States;

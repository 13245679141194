import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ReasonsDialoge from '../basic/dialoges/reasonsDialoge';
import Dialoge from '../basic/dialoges/simpleDialog';
import { StateContext } from '../generalFunctions/context';
import { setHeaders } from '../generalFunctions/requestheaders';
import { useStyles } from '../staticFields/bookerTypes';

const changeText = (status: string) => {
	switch (status) {
		case 'OPEN':
			return 'New';
		case 'ACCEPTED_BY_TALENT':
			return 'Pending Confirmation';
		case 'PENDING_DEPOSIT':
			return 'Confirmed';
		case 'PENDING_REMAINDER':
			return 'Paid Deposit';
		case 'APPROVED':
			return 'Paid';
		case 'COMPLETED':
			return 'Completed';
		case 'REJECTED_BY_TALENT':
			return 'Rejected';
		case 'CANCELED_BY_BOOKER':
			return 'Canceled By Booker';
		case 'CANCELED_BY_TALENT':
			return 'Canceled By Talent';
		case 'PENDING_FULL_PAYMENT':
			return 'Pending Full Paymnet';
		default:
			return status;
	}
};

export default function Page() {
	const classes = useStyles();

	const [reasonsopen, setReasonsOpen] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	const [reLoad, setReLoad] = React.useState(false);
	const [disable, setDisable] = React.useState(false);
	const [message, setMessage] = React.useState('');
	const [nextStatuses, setNextStatuses] = useState(['']);
	const { storeData } = useContext(StateContext);
	const [data, setData] = useState({
		band: { name: '' },
		booker_last_seen: 0,
		created_at: 0,
		created_by: { fname: '', lname: '' },
		event: {
			created_at: 0,
			date: '',
			description: '',
			desired_set_length: '',
			end_time: '',
			location: '',
			number_of_attendees: '',
			start_time: '',
			status: '',
			title: '',
			updated_at: 0,
			promo_code: ''
		},
		id: '',
		notes: '',
		payment_amount: '',
		production_budget: '',
		status: '',
		talent_budget: '',
		talent_last_seen: 0,
		updated_at: 0
	});
	const history = useHistory();
	const { id } = useParams();

	useEffect(() => {
		axios
			.get(process.env.REACT_APP_API_URL + `events/request-band/${id}`, {
				headers: setHeaders(storeData.Token)
			})
			.then(response => {
				setData(response.data.item);
				return axios.post(
					process.env.REACT_APP_API_URL + `requests/availableStatuses`,
					{ currentStatus: response.data.item.status },
					{ headers: setHeaders(storeData.Token) }
				);
			})
			.then(res => {
				setNextStatuses(res.data.server_message === 'NO_AVAILABLE_STATUS' ? [''] : res.data.items);
			})
			.catch(e => {
				setMessage(`Error: ${e.response.data.user_message ? e.response.data.user_message : e.response.data.message}`);
				setOpen(true);
			});
	}, [id, storeData.Token, reLoad]);

	const handleDialogClose = (action: { type: string; reasons: string }, rowid: string) => {
		if (action.type === 'delete') {
			const body = {
				reason: action.reasons,
				request_id: data.id
			};
			axios
				.post(process.env.REACT_APP_API_URL + `report/request`, body, {
					headers: setHeaders(storeData.Token)
				})
				.then(response => {
					setMessage('Done');
					setOpen(true);
				})
				.catch(e => {
					setMessage(`Error: ${e.response.data.user_message ? e.response.data.user_message : e.response.data.message}`);
					setOpen(true);
				});
		}
		setReasonsOpen(false);
	};

	const handleSimpleClose = () => {
		setOpen(false);
	};

	const HandleReport = () => {
		setReasonsOpen(true);
	};

	const HandleConfirm = () => {
		setDisable(true);
		axios
			.put(
				process.env.REACT_APP_API_URL + `events/requests/${id}`,
				{ status: data.status },
				{
					headers: setHeaders(storeData.Token)
				}
			)
			.then((response: any) => {
				setReLoad(!reLoad);
			})
			.catch((err: any) => {
				setMessage(
					`Error: ${err.response.data.user_message ? err.response.data.user_message : err.response.data.message}`
				);
				setOpen(true);
				setDisable(false);
			});
	};

	return (
		<Box paddingLeft="10px">
			<Box display="flex" justifyContent="space-between">
				<Box>
					<IconButton onClick={() => history.goBack()} aria-label="previous page">
						<KeyboardArrowLeft />
					</IconButton>
				</Box>
				<Box>
					<a
						href={process.env.REACT_APP_API_URL + `event/request-details/${id}/${storeData.Token}`}
						target="_blank"
						rel="noopener noreferrer"
						download
					>
						Export Request details
					</a>
					<Button variant="contained" onClick={HandleReport}>
						Report Issue
					</Button>
					<Button
						style={{ width: '130px' }}
						variant="contained"
						onClick={() => history.push(history.location.pathname + '/messages')}
					>
						Join Conversation
					</Button>
				</Box>
			</Box>
			{data ? (
				<Box paddingLeft="20px" paddingBottom="20px">
					<Typography variant="h5">
						Booker:<Typography paragraph>{data.created_by.fname + ' ' + data.created_by.lname}</Typography>
					</Typography>
					<Typography variant="h5">
						Talent:<Typography paragraph>{data.band.name}</Typography>
					</Typography>
					<Typography variant="h5">
						Created At:<Typography paragraph>{new Date(data.created_at * 1000).toLocaleString()}</Typography>
					</Typography>
					<Typography variant="h5">
						Updated At:<Typography paragraph>{new Date(data.updated_at * 1000).toLocaleString()}</Typography>
					</Typography>
					<Typography variant="h5">
						Status:
						<Select
							value={nextStatuses.indexOf(data.status) !== -1 ? nextStatuses.indexOf(data.status) : 99}
							id="state"
							name="state"
							variant="outlined"
							MenuProps={{
								anchorOrigin: {
									horizontal: 'left',
									vertical: 'bottom'
								},
								getContentAnchorEl: null,
								transformOrigin: {
									horizontal: 'left',
									vertical: 'top'
								}
							}}
							onChange={(e: any) => {
								e.persist();
								setData({ ...data, status: nextStatuses[e.target.value] });
							}}
						>
							<MenuItem disabled value={99}>
								{changeText(data.status)}
							</MenuItem>
							{nextStatuses.map((item: any, index: number) => (
								<MenuItem className={classes.menu} key={item} value={index}>
									{changeText(item)}
								</MenuItem>
							))}
						</Select>
						<Button variant="text" color="secondary" disabled={disable} onClick={HandleConfirm}>
							Change
						</Button>
					</Typography>
					<Typography variant="h5">
						Talent Budget:<Typography paragraph>${data.talent_budget}</Typography>
					</Typography>
					<Typography variant="h5">
						Production Budget:<Typography paragraph>${data.production_budget}</Typography>
					</Typography>
					<Typography variant="h5">
						Notes:<Typography paragraph>{data.notes}</Typography>
					</Typography>
					<Typography variant="h5">
						Talent Last Seen:<Typography paragraph>{data.talent_last_seen}</Typography>
					</Typography>
					<Typography variant="h5">
						Booker Last Seen:<Typography paragraph>{data.booker_last_seen}</Typography>
					</Typography>
					<Typography variant="h5">
						Payment Amount:<Typography paragraph>${data.payment_amount}</Typography>
					</Typography>
					<Typography variant="h5">
						Event Title:<Typography paragraph>{data.event.title}</Typography>
					</Typography>
					<Typography variant="h5">
						Event Date:<Typography paragraph>{data.event.date}</Typography>
					</Typography>
					<Typography variant="h5">
						Event Description:<Typography paragraph>{data.event.description}</Typography>
					</Typography>
					<Typography variant="h5">
						Event Status:<Typography paragraph>{data.event.status}</Typography>
					</Typography>
					<Typography variant="h5">
						Event Created At:
						<Typography paragraph>{new Date(data.event.created_at * 1000).toLocaleString()}</Typography>
					</Typography>
					<Typography variant="h5">
						Event Updated At:
						<Typography paragraph>{new Date(data.event.updated_at * 1000).toLocaleString()}</Typography>
					</Typography>
					<Typography variant="h5">
						Event Location:<Typography paragraph>{data.event.location}</Typography>
					</Typography>
					<Typography variant="h5">
						Event Number Of Attendees:<Typography paragraph>{data.event.number_of_attendees}</Typography>
					</Typography>
					<Typography variant="h5">
						Event Desired Set Length:<Typography paragraph>{data.event.desired_set_length}</Typography>
					</Typography>
					<Typography variant="h5">
						Event Start Time:<Typography paragraph>{data.event.start_time}</Typography>
					</Typography>
					<Typography variant="h5">
						Event End Time:<Typography paragraph>{data.event.end_time}</Typography>
					</Typography>
					<Typography variant="h5">
						Promo Code:<Typography paragraph>{data.event.promo_code}</Typography>
					</Typography>
					<ReasonsDialoge open={reasonsopen} id={data.id} onClose={handleDialogClose} />
					<Dialoge message={message} onClose={handleSimpleClose} open={open} />
				</Box>
			) : (
				<> </>
			)}
		</Box>
	);
}

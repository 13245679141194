import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import React from 'react';
import { theme } from '../../../theme/index';
import DeleteDialog from '../dialoges/deleteDialoge';
import { useStyles } from './notificationTable';

export interface ITable {
	rows: Array<{ id: string; data: string }>;
	title: string;
	total: number;
	handleDelete: (id: string) => void;
	handleEdit: (id: string) => void;
	pagedata: (pagenum: number) => void;
	page: number;
	message?: string;
}

interface ITablePaginationActionsProps {
	count: number;
	page: number;
	rowsPerPage: number;
	onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: ITablePaginationActionsProps) {
	const { count, page, rowsPerPage, onChangePage } = props;

	const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, 0);
	};

	const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, page - 1);
	};

	const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, page + 1);
	};

	const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box flexShrink="0" marginLeft={theme.spacing(7)}>
			<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
				{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

export default function CustomizedTables(props: ITable) {
	const classes = useStyles();

	const [open, setOpen] = React.useState(false);
	const [id, setId] = React.useState('');
	const { title, rows, handleDelete, handleEdit, total, pagedata, page, message } = props;

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		pagedata(newPage + 1);
	};

	const handleDialogClose = (action: string, rowid: string) => {
		if (action === 'delete') {
			handleDelete(rowid);
		}
		setOpen(false);
	};
	const deletee = (idrow: string) => {
		setId(idrow);
		setOpen(true);
	};

	return (
		<Box marginTop="10px" width="100%">
			<Table aria-label="customized table">
				<TableHead>
					<TableRow>
						<TableCell align="left">{title}</TableCell>
						<TableCell align="right">Controls</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map(row => (
						<TableRow key={row.id}>
							<TableCell align="left" component="th" scope="row">
								{row.data}
							</TableCell>
							<TableCell align="right">
								<Button
									className={classes.controls}
									variant="contained"
									color="secondary"
									onClick={() => handleEdit(row.id)}
								>
									Edit
								</Button>
								<Button className={classes.controls} variant="contained" onClick={() => deletee(row.id)}>
									delete
								</Button>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[]}
							count={total}
							rowsPerPage={10}
							page={page}
							SelectProps={{
								inputProps: { 'aria-label': 'rows per page' },
								native: true
							}}
							onChangePage={handleChangePage}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Table>
			<DeleteDialog message={message} open={open} id={id} onClose={handleDialogClose} />
		</Box>
	);
}

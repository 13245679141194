import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import SimpleDialog from '../basic/dialoges/simpleDialog';
import Table from '../basic/tables/table';
import { StateContext } from '../generalFunctions/context';
import { setHeaders } from '../generalFunctions/requestheaders';

const TEXT_MAX_LENGTH = 255;
const OWNER_MAX_LENGTH = 60;

const Quotes: React.FC = () => {
	const { storeData } = useContext(StateContext);
	const [addDisable, setAddDisable] = useState(false);
	const [operation, setOperation] = useState(false);
	const [open, setOpen] = React.useState(false);
	const [message, setMessage] = React.useState('Done');
	const [buttonLabel, setButtonLabel] = useState('create');
	const [submitrequest, setSubmitRequest] = useState('post');
	const [listall, setListall] = useState(false);
	const [data, setData] = useState([{ id: '0', text: '', owner: '' }]);
	const [totalCount, setTotalCount] = useState(0);
	const [tableContent, setTableContent] = useState([{ id: '', data: '' }]);
	const [page, setPage] = useState(1);
	const [error, setError] = useState({
		owner: {
			disable: true,
			error: false,
			message: ''
		},
		text: {
			disable: true,
			error: false,
			message: ''
		}
	});
	const [values, setValues] = useState({
		id: '0',
		owner: '',
		text: ''
	});

	useEffect(() => {
		axios
			.post(
				process.env.REACT_APP_API_URL + 'quotes/list',
				{ page },
				{
					headers: setHeaders(storeData.Token)
				}
			)
			.then(response => {
				setData(response.data.items);
				if (response.data.items.length >= 3) {
					setAddDisable(true);
				}
				const tabledata = response.data.items.map((item: any) => {
					return { id: item.id, data: item.text.slice(0, 100) };
				});
				setTableContent(tabledata);
				setTotalCount(response.data.total_items);
			})
			.catch(e => {
				setMessage('Something went wrong.');
				setOpen(true);
			});
	}, [listall, page, storeData.Token]);

	const handladd = () => {
		setOperation(true);
		setButtonLabel('create');
		setSubmitRequest('post');
	};

	const handleEdite = (id: string) => {
		setOperation(true);
		setButtonLabel('edit');
		const index = data.findIndex(item => item.id === id);
		const newval = {
			id: data[index].id,
			owner: data[index].owner,
			text: data[index].text
		};
		setValues(newval);
		const newerror = {
			owner: {
				disable: false,
				error: false,
				message: ''
			},
			text: {
				disable: false,
				error: false,
				message: ''
			}
		};
		setError(newerror);
		setSubmitRequest('put');
	};

	const handleChange = (id: string, value: string) => {
		let newvalues = { ...values };
		let newerror = { ...error };
		switch (id) {
			case 'text':
				newvalues = { ...newvalues, text: value };
				setValues(newvalues);
				newerror = { ...newerror, text: { error: false, message: '', disable: false } };
				setError(newerror);
				if (value.length > TEXT_MAX_LENGTH) {
					newerror = {
						...newerror,
						text: {
							disable: true,
							error: true,
							message: `value is too long (can't be more than ${TEXT_MAX_LENGTH} characters)`
						}
					};
					setError(newerror);
				} else if (value.trim().length === 0) {
					newerror = {
						...newerror,
						text: { error: true, message: 'required', disable: true }
					};
					setError(newerror);
				}
				break;
			case 'owner':
				newvalues = { ...newvalues, owner: value };
				setValues(newvalues);
				newerror = { ...newerror, owner: { error: false, message: '', disable: false } };
				setError(newerror);
				if (value.length > OWNER_MAX_LENGTH) {
					newerror = {
						...newerror,
						owner: {
							disable: true,
							error: true,
							message: `value is too long (can't be more than ${OWNER_MAX_LENGTH} characters)`
						}
					};
					setError(newerror);
				} else if (value.trim().length === 0) {
					newerror = {
						...newerror,
						owner: { error: true, message: 'required', disable: true }
					};
					setError(newerror);
				}
				break;
		}
	};

	const handldelete = (id: string) => {
		axios
			.delete(process.env.REACT_APP_API_URL + `quotes/${id}`, {
				headers: setHeaders(storeData.Token)
			})
			.then(response => {
				setListall(!listall);
				setAddDisable(false);
			})
			.catch(e => {
				setMessage('Something went wrong.');
				setOpen(true);
			});
	};

	const handleClick = () => {
		const requestData = {
			owner: values.owner,
			text: values.text
		};
		if (submitrequest === 'post') {
			axios
				.post(process.env.REACT_APP_API_URL + `quotes`, requestData, {
					headers: setHeaders(storeData.Token)
				})
				.then(response => {
					setListall(!listall);
				})
				.catch(e => {
					setMessage('Something went wrong.');
					setOpen(true);
				});
		} else {
			axios
				.put(process.env.REACT_APP_API_URL + `quotes/${values.id}`, requestData, {
					headers: setHeaders(storeData.Token)
				})
				.then(response => {
					setListall(!listall);
				})
				.catch(e => {
					setMessage('Something went wrong.');
					setOpen(true);
				});
		}
		setOperation(false);
		setValues({ owner: '', text: '', id: '' });
		setTimeout(() => {
			window.scrollTo({
				behavior: 'smooth',
				top: 0
			});
		}, 100);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCancel = () => {
		setOperation(false);
		setValues({ owner: '', text: '', id: '' });
		setTimeout(() => {
			window.scrollTo({
				behavior: 'smooth',
				top: 0
			});
		}, 100);
		const newerror = {
			owner: {
				disable: true,
				error: false,
				message: ''
			},
			text: {
				disable: true,
				error: false,
				message: ''
			}
		};
		setError(newerror);
	};

	return (
		<Box>
			<Typography align="center" variant="h4">
				Quotes
			</Typography>
			<Box display="flex" justifyContent="flex-end">
				<Button disabled={addDisable} color="secondary" onClick={handladd}>
					<AddCircleIcon fontSize="large" />
				</Button>
			</Box>
			<Table
				title="Quotes"
				page={page - 1}
				rows={tableContent}
				handleDelete={handldelete}
				handleEdit={handleEdite}
				total={totalCount}
				pagedata={setPage}
			/>
			{operation ? (
				<>
					<Divider />
					<Box display={'flex'} flexDirection={'column'} alignItems={'center'} paddingTop={'10px'} paddingLeft={'10px'}>
						<TextField
							id="text"
							multiline
							label="text"
							variant="outlined"
							placeholder="text"
							rows="2"
							error={error.text.error}
							helperText={error.text.message}
							value={values.text}
							onChange={e => handleChange('text', e.target.value)}
							fullWidth
							required
						/>
						<TextField
							id="owner"
							multiline
							label="owner"
							variant="outlined"
							placeholder="owner"
							rows="2"
							error={error.owner.error}
							helperText={error.owner.message}
							value={values.owner}
							onChange={e => handleChange('owner', e.target.value)}
							fullWidth
							required
						/>
						<Box display="flex">
							<Button
								variant="contained"
								color="primary"
								disabled={error.text.disable || error.owner.disable}
								onClick={handleClick}
							>
								{buttonLabel}
							</Button>
							<Button variant="contained" onClick={handleCancel}>
								cancel
							</Button>
						</Box>
					</Box>
				</>
			) : (
				<></>
			)}
			<SimpleDialog open={open} onClose={handleClose} message={message} />
		</Box>
	);
};

export default Quotes;

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import SimpleDialog from '../basic/dialoges/simpleDialog';
import Table from '../basic/tables/table';
import { StateContext } from '../generalFunctions/context';
import { setHeaders } from '../generalFunctions/requestheaders';

const QESTION_MAX_LENGTH = 1000;
const ANSWER_MAX_LENGTH = 1000;

const FAQs: React.FC = () => {
	const [buttonLabel, setButtonLabel] = useState('create');
	const [listall, setListall] = useState(false);
	const { storeData } = useContext(StateContext);
	const [totalCount, setTotalCount] = useState(0);
	const [tableContent, setTableContent] = useState([{ id: '', data: '' }]);
	const [page, setPage] = useState(1);
	const [submitrequest, setSubmitRequest] = useState('post');
	const [data, setData] = useState([{ id: '0', question: '', answer: '', faqtype: '' }]);
	const [operation, setOperation] = useState(false);
	const [open, setOpen] = React.useState(false);
	const [message, setMessage] = React.useState('Done');

	useEffect(() => {
		axios
			.post(
				process.env.REACT_APP_API_URL + 'faqs/list',
				{ page },
				{
					headers: setHeaders(storeData.Token)
				}
			)
			.then(response => {
				if (response.data.items.length === 0 && page > 1) {
					setPage(prevState => prevState - 1);
				}
				setData(response.data.items);
				const tabledata = response.data.items.map((item: any) => {
					return { id: item.id, data: item.question.slice(0, 100) };
				});
				setTableContent(tabledata);
				setTotalCount(response.data.total_items);
			})
			.catch(e => {
				setMessage('Something went wrong.');
				handleClickOpen();
			});
	}, [listall, page, storeData.Token]);

	const handladd = () => {
		setOperation(true);
		setButtonLabel('create');
		setSubmitRequest('post');
		setTimeout(() => {
			window.scrollTo({
				behavior: 'smooth',
				top: 10000
			});
		}, 1);
	};

	const [error, setError] = useState({
		answer: {
			disable: true,
			error: false,
			message: ''
		},
		question: {
			disable: true,
			error: false,
			message: ''
		},
		faqtype: {
			disable: true,
			error: false,
			message: ''
		}
	});
	const [values, setValues] = useState({
		id: '0',
		answer: '',
		question: '',
		faqtype: ''
	});

	// const handleSelectChange = (event) => {
	// 	setAge(event.target.value);
	// };

	const handleChange = (id: string, value: string) => {
		let newvalues = { ...values };
		let newerror = { ...error };
		console.log(id, value);
		switch (id) {
			case 'Faqtype':
				newvalues = { ...newvalues, faqtype: value };
				setValues(newvalues);
				newerror = { ...newerror, faqtype: { error: false, message: '', disable: false } };
				setError(newerror);

				break;
			case 'Question':
				newvalues = { ...newvalues, question: value };
				setValues(newvalues);
				newerror = { ...newerror, question: { error: false, message: '', disable: false } };
				setError(newerror);
				if (value.length > QESTION_MAX_LENGTH) {
					newerror = {
						...newerror,
						question: {
							disable: true,
							error: true,
							message: `value is too long (can't be more than ${QESTION_MAX_LENGTH} characters)`
						}
					};
					setError(newerror);
				} else if (value.length === 0) {
					newerror = {
						...newerror,
						question: { error: true, message: 'required', disable: true }
					};
					setError(newerror);
				}
				break;
			case 'Answer':
				newvalues = { ...newvalues, answer: value };
				setValues(newvalues);
				newerror = { ...newerror, answer: { error: false, message: '', disable: false } };
				setError(newerror);
				if (value.length > ANSWER_MAX_LENGTH) {
					newerror = {
						...newerror,
						answer: {
							disable: true,
							error: true,
							message: `value is too long (can't be more than ${ANSWER_MAX_LENGTH} characters)`
						}
					};
					setError(newerror);
				} else if (value.length === 0) {
					newerror = {
						...newerror,
						answer: { error: true, message: 'required', disable: true }
					};
					setError(newerror);
				}
				break;
		}
	};
	const handleClick = () => {
		const requestdata = {
			answer: values.answer,
			question: values.question,
			faqtype: values.faqtype
		};
		if (submitrequest === 'post') {
			axios
				.post(process.env.REACT_APP_API_URL + `faqs`, requestdata, {
					headers: setHeaders(storeData.Token)
				})
				.then(response => {
					setListall(!listall);
				})
				.catch(e => setMessage(e));
		} else {
			axios
				.put(process.env.REACT_APP_API_URL + `faqs/${values.id}`, requestdata, {
					headers: setHeaders(storeData.Token)
				})
				.then(response => {
					setListall(!listall);
				})
				.catch(e => setMessage(e));
		}
		setValues({ question: '', answer: '', id: '', faqtype: '' });
		setButtonLabel('create');
		setSubmitRequest('post');
	};
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const handleEdite = (id: string) => {
		setOperation(true);
		setSubmitRequest('put');
		const index = data.findIndex(item => item.id === id);
		const faq = {
			answer: data[index].answer,
			id: data[index].id,
			question: data[index].question,
			faqtype: data[index].faqtype
		};
		setValues(faq);
		const newerror = {
			answer: {
				disable: false,
				error: false,
				message: ''
			},
			question: {
				disable: false,
				error: false,
				message: ''
			},
			faqtype: {
				disable: false,
				error: false,
				message: ''
			}
		};
		setButtonLabel('update');
		setError(newerror);
		setTimeout(() => {
			window.scrollTo({
				behavior: 'smooth',
				top: 10000
			});
		}, 1);
	};

	const handldelete = (id: string) => {
		axios
			.delete(process.env.REACT_APP_API_URL + `faqs/${id}`, {
				headers: setHeaders(storeData.Token)
			})
			.then(response => {
				setListall(!listall);
			})
			.catch(e => {
				setMessage('Something went wrong.');
				handleClickOpen();
			});
	};

	const handleCancel = () => {
		setOperation(false);
		setValues({ question: '', answer: '', faqtype: '', id: '' });
		setTimeout(() => {
			window.scrollTo({
				behavior: 'smooth',
				top: 0
			});
		}, 100);
		const newerror = {
			answer: {
				disable: true,
				error: false,
				message: ''
			},
			question: {
				disable: true,
				error: false,
				message: ''
			},
			faqtype: {
				disable: true,
				error: false,
				message: ''
			}
		};
		setError(newerror);
	};

	return (
		<Box paddingBottom="5px">
			<Typography align="center" variant="h4">
				FAQs
			</Typography>
			<Box display="flex" justifyContent="flex-end">
				<Button color="secondary" onClick={handladd}>
					<AddCircleIcon fontSize="large" />
				</Button>
			</Box>
			<Table
				title="FAQs"
				page={page - 1}
				rows={tableContent}
				handleDelete={handldelete}
				handleEdit={handleEdite}
				total={totalCount}
				pagedata={setPage}
			/>
			{operation ? (
				<>
					<Divider />

					<Box m={3}>
						<InputLabel id="label">Type</InputLabel>
						<Select value={values.faqtype} onChange={e => handleChange('Faqtype', String(e.target.value))}>
							<MenuItem value="booker">Booker</MenuItem>
							<MenuItem value="talent">Talent</MenuItem>
						</Select>
					</Box>

					<Box
						height={'43%'}
						width={'60'}
						display={'flex'}
						flexDirection={'column'}
						justifyContent={'flex-start'}
						alignItems={'center'}
						paddingTop="10px"
					>
						<TextField
							id="question"
							label="question"
							variant="outlined"
							placeholder="question"
							error={error.question.error}
							helperText={error.question.message}
							value={values.question}
							onChange={e => handleChange('Question', e.target.value)}
							fullWidth
							required
						/>

						<TextField
							id="answer"
							multiline
							label="answer"
							variant="outlined"
							placeholder="answer"
							rows="6"
							error={error.answer.error}
							helperText={error.answer.message}
							value={values.answer}
							onChange={e => handleChange('Answer', e.target.value)}
							fullWidth
							required
						/>

						<Box display="flex">
							<Button
								variant="contained"
								color="secondary"
								disabled={error.question.disable || error.answer.disable}
								onClick={handleClick}
							>
								{buttonLabel}
							</Button>
							<Button variant="contained" onClick={handleCancel}>
								cancel
							</Button>
						</Box>
					</Box>
				</>
			) : (
				<></>
			)}
			<SimpleDialog open={open} onClose={handleClose} message={message} />
		</Box>
	);
};

export default FAQs;

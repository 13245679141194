import { createStyles, makeStyles, TableSortLabel, Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import React from 'react';
import { theme } from '../../../theme/index';
import SuggestionDialoge from '../dialoges/suggestionDialoge';
//import { useStyles } from './notificationTable';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		clickableTable: {
			'&:hover': {
				cursor: 'pointer'
			}
		},
		controls: {
			marginRight: 0
		},
		root: {
			width: '100%'
		},
		paper: {
			width: '100%',
			marginBottom: theme.spacing(2)
		},
		table: {
			minWidth: 750,
			'& thead': {
				'& th': {
					'& span': {
						color: '#fff !important'
					},
					'& svg': {
						color: '#fff !important'
					}
				}
			}
		},
		visuallyHidden: {
			border: 0,
			clip: 'rect(0 0 0 0)',
			height: 1,
			margin: -1,
			overflow: 'hidden',
			padding: 0,
			position: 'absolute',
			top: 20,
			width: 1
		},
		TableSortLabel: {
			color: '#fff'
		}
	})
);

export interface ITable {
	rows: Array<{
		id: string;
		data: {
			first_name: string;
			last_name: string;
			email: string;
			type: string;
			artist_name: string;
			artist_contact: string;
			event_date: string;
			created_at: string;
			description: string;
		};
	}>;
	total: number;
	pagedata: (pagenum: number) => void;
	sortdata: (sortdata: { orderBy: string; order: string }) => void;
	handleView: (id: string) => void;
	page: number;
}

interface ITablePaginationActionsProps {
	count: number;
	page: number;
	rowsPerPage: number;
	onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: ITablePaginationActionsProps) {
	const { count, page, rowsPerPage, onChangePage } = props;

	const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, 0);
	};

	const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, page - 1);
	};

	const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, page + 1);
	};

	const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box flexShrink="0" marginLeft={theme.spacing(2.5)}>
			<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
				{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

interface HeadCell {
	disablePadding: boolean;
	id: keyof Data;
	label: string;
	numeric: boolean;
}

const headCells: HeadCell[] = [
	{ id: 'name', numeric: false, disablePadding: false, label: 'Name' },
	{ id: 'email', numeric: false, disablePadding: false, label: 'Email' },
	{ id: 'type', numeric: false, disablePadding: false, label: 'Type' },
	{ id: 'artist_name', numeric: false, disablePadding: false, label: 'Artist Name' },
	{ id: 'artist_contact', numeric: false, disablePadding: false, label: 'Artist Contact' },
	{ id: 'event_date', numeric: false, disablePadding: false, label: 'Event Date' },
	{ id: 'created_at', numeric: false, disablePadding: false, label: 'Created Date' }
];

interface Data {
	name: string;
	email: string;
	type: string;
	artist_name: string;
	artist_contact: string;
	event_date: string;
	created_at: string;
}

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
	classes: ReturnType<typeof useStyles>;
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
	order: Order;
	orderBy: string;
	rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const { classes, order, orderBy, rowCount, onRequestSort } = props;
	const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map(headCell => (
					<TableCell
						key={headCell.id}
						align="center"
						padding={headCell.disablePadding ? 'none' : 'default'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

export default function CustomizedTables(props: ITable) {
	const classes = useStyles();

	const [open, setOpen] = React.useState(false);
	const [dialogData, setDialogData] = React.useState();

	const [order, setOrder] = React.useState<Order>('desc');
	const [orderBy, setOrderBy] = React.useState<keyof Data>('event_date');

	const { rows, total, pagedata, sortdata, page, handleView } = props;

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		pagedata(newPage + 1);
	};

	const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
		console.log('property: ', property);
		//onRequestSort(event, property);
	};
	const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
		sortdata({
			orderBy: property,
			order: isAsc ? 'desc' : 'asc'
		});
		console.log('property: ', property);
	};

	const handleDialogClose = () => {
		setOpen(false);
	};

	const showDialog = (data: any) => {
		setDialogData(data);
		setOpen(true);
	};

	return (
		<Box marginTop="10px" width="100%">
			<Table aria-label="customized table" className={classes.table}>
				<EnhancedTableHead
					classes={classes}
					order={order}
					orderBy={orderBy}
					onRequestSort={handleRequestSort}
					rowCount={rows.length}
				/>
				<TableBody>
					{rows.map(row => (
						<TableRow className={classes.clickableTable} key={row.id} onClick={() => showDialog(row.data)}>
							<TableCell align="left" component="th" scope="row">
								{row.data.first_name} {row.data.last_name}
							</TableCell>
							<TableCell align="left" component="th" scope="row">
								{row.data.email}
							</TableCell>
							<TableCell align="right" component="th" scope="row">
								{row.data.type}
							</TableCell>
							<TableCell align="right" component="th" scope="row">
								{row.data.artist_name}
							</TableCell>
							<TableCell align="right" component="th" scope="row">
								{row.data.artist_contact}
							</TableCell>
							<TableCell align="right" component="th" scope="row">
								{row.data.event_date}
							</TableCell>

							<TableCell align="right" component="th" scope="row">
								{row.data.created_at}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[]}
							count={total}
							rowsPerPage={10}
							page={page}
							SelectProps={{
								inputProps: { 'aria-label': 'rows per page' },
								native: true
							}}
							onChangePage={handleChangePage}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Table>
			<SuggestionDialoge message="message" open={open} data={dialogData} onClose={handleDialogClose} />
		</Box>
	);
}

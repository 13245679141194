import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

import colors from './colors.scss';

export const theme = createMuiTheme({
	overrides: {
		MuiAppBar: {
			root: {
				alignItems: 'center',
				backgroundColor: colors.primary,
				borderBottom: `2px solid ${colors.light}`,
				color: colors.text,
				display: 'flex',
				flexDirection: 'row',
				height: '12vh',
				justifyContent: 'space-between',
				paddingLeft: '1em'
			}
		},
		MuiAvatar: {
			circle: {
				backgroundColor: colors.primary,
				height: 110,
				width: 110
			}
		},
		MuiButton: {
			root: {
				margin: 10,
				width: '80px'
			}
		},
		MuiCardHeader: {
			root: {
				width: '100%'
			}
		},
		MuiDialog: {
			root: {
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start'
			}
		},
		MuiDialogActions: {
			root: {
				display: 'flex',
				justifyContent: 'center',
				textAlign: 'center'
			}
		},
		MuiDialogContent: {
			root: {
				width: 570
			}
		},
		MuiDivider: {
			root: {
				backgroundColor: '#111111'
			}
		},
		MuiDrawer: {
			paper: {
				backgroundColor: colors.primary,
				color: colors.text,
				height: '88vh',
				paddingTop: '30px',
				top: '12vh',
				width: '20%'
			}
		},
		MuiExpansionPanel: {
			root: {
				backgroundColor: 'inherit',
				padding: 0
			}
		},
		MuiList: {
			root: {
				overflow: 'auto'
			}
		},
		MuiMenuItem: {
			root: {
				'&$selected': {
					backgroundColor: colors.primary,
					color: '#64fecc',
					paddingLeft: 40
				},
				backgroundColor: colors.primary,
				color: colors.text,
				paddingLeft: 24
			}
		},
		MuiOutlinedInput: {
			root: {
				margin: 5
			}
		},
		MuiTableCell: {
			body: {
				fontSize: 14,
				maxWidth: 550,
				'word-wrap': 'break-word'
			},
			head: {
				backgroundColor: colors.black,
				color: colors.text,
				whiteSpace: 'nowrap'
			}
		},
		MuiTextField: {
			root: {
				marginBottom: 15
			}
		},
		MuiTypography: {
			h4: {
				margin: '5px'
			}
		}
	},
	palette: {
		background: {
			default: colors.bodyBackground
		},
		error: {
			main: colors.error
		},
		primary: {
			main: colors.primary
		},
		secondary: {
			main: colors.secondary
		}
	}
});

import { Button, CircularProgress, Grid, makeStyles, TextField } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SimpleDialog from '../../basic/dialoges/simpleDialog';
import { StateContext } from '../../generalFunctions/context';
import { setHeaders } from '../../generalFunctions/requestheaders';

const useStyles = makeStyles(theme => ({
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '45%',
		marginTop: -12,
		marginLeft: 40,
		color: '#fff'
	},
	submit: {
		minWidth: '110px'
	}
}));

export default function Page() {
	const classes = useStyles();
	const history = useHistory();
	const { storeData } = useContext(StateContext);
	const [disabled, setSubmitDisabled] = useState(false);

	const [metadataModel, setMetadataModel] = useState({
		band_id: null,
		meta_title: '',
		meta_description: '',
		meta_image: null,
		meta_image_display_url: ''
	});
	const [metaImagePreview, setMetaImagePreview] = useState<any>();

	const [data, setData] = useState({
		created_by: { email: '' },
		photo: { thumbnail: '' },
		name: '',
		location: '',
		bio: '',
		size: '',
		price_from: '',
		price_to: '',
		price_show: true,
		hospitality_and_production_rider: { url: '' },
		state: { title: '' },
		rating: '',
		genres: [{ title: '' }],
		willing_to_travel: [{ title: '' }],
		preferred_gig_types: [{ title: '' }],
		featured_songs: [{ name: '', url: '' }],
		social_links: [{ type: '', link: '' }],
		order_number: 0,
		promo_code: '',
		meta_title: '', // (1)
		meta_description: '', // (2)
		meta_image_path: '' // (3)
	});

	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState('');
	const { id } = useParams();

	useEffect(() => {
		axios
			.get(process.env.REACT_APP_API_URL + `bands/${id}`, {
				headers: setHeaders(storeData.Token)
			})
			.then(response => {
				setData(response.data.item);
				if (response.data.item) {
					setMetadataModel({
						...metadataModel,
						meta_title: response.data.item.meta_title || response.data.item.name,
						meta_description: response.data.item.meta_description || response.data.item.bio,
						meta_image_display_url: response.data.item.meta_image_path || response.data?.item?.photo?.thumbnail
					});
				}
			})
			.catch((e: any) => {
				setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
				setOpen(true);
			});
	}, [id, storeData.Token]);

	const handleBackButtonClick = () => {
		history.push('/approve_band');
	};

	const showHidePriceSave = (e: any) => {
		e.preventDefault();
		axios
			.post(
				process.env.REACT_APP_API_URL + 'set_band_price_range_flag',
				{ show_price_flag: !data.price_show, band_id: id },
				{
					headers: setHeaders(storeData.Token)
				}
			)
			.then(response => {
				setData({ ...data, price_show: !data.price_show });
			})
			.catch((e: any) => {
				setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
				setOpen(true);
			});
	};

	const handleChange = (id: string, value: any) => {
		switch (id) {
			case 'meta_title':
				setMetadataModel({ ...metadataModel, meta_title: value });
				break;
			case 'meta_description':
				setMetadataModel({ ...metadataModel, meta_description: value });
				break;
			case 'meta_image':
				const reader = new FileReader();
				const file = value[0];
				reader.onloadend = () => {
					setMetaImagePreview(reader.result);
				};
				reader.readAsDataURL(file);
				setMetadataModel({ ...metadataModel, meta_image: value[0] });
				break;
		}
	};

	const handleMetadataSubmit = async () => {
		//(7)
		setSubmitDisabled(true);
		let reqParams = metadataModel;
		reqParams.band_id = id;
		const bodyFormData = new FormData();
		bodyFormData.append('band_id', reqParams.band_id || '');
		bodyFormData.append('meta_title', reqParams.meta_title);
		bodyFormData.append('meta_description', reqParams.meta_description);
		if (reqParams.meta_image != null) {
			bodyFormData.append('meta_image', reqParams.meta_image || '');
		}

		axios
			.post(process.env.REACT_APP_API_URL + 'add-band-meta', bodyFormData, {
				headers: setHeaders(storeData.Token)
			})
			.then(response => {
				if (response.status == 200) {
					setSubmitDisabled(false);
				} else {
					setSubmitDisabled(false);
					//setMessage('Error: ' + response.data.message);
					setOpen(true);
				}
			})
			.catch(err => {
				console.log('err: ', err);
				setSubmitDisabled(false);
				//setMessage('Error: ' + err.response.data.message);
				setOpen(true);
			});
	};

	// const uploadMetaImgfile = async (file: any) => {								//(11)
	// 	const bodyFormData = new FormData();
	// 	bodyFormData.append('media', file);
	// 	let res = await axios.post(process.env.REACT_APP_API_URL + `media/upload`, bodyFormData, {
	// 		headers: setHeaders(storeData.Token)
	// 	});
	// 	return res.data.item.url;
	// };

	return (
		<Box paddingLeft="10px">
			<Box>
				<IconButton onClick={handleBackButtonClick} aria-label="previous page">
					<KeyboardArrowLeft />
				</IconButton>
			</Box>
			{data ? (
				<Box paddingLeft="20px">
					<CardHeader
						avatar={<Avatar src={data.photo ? data.photo.thumbnail : ''}>{data.name[0]}</Avatar>}
						title={data.name}
						subheader={data.location}
					/>
					<Typography variant="h5">
						Bio:<Typography paragraph>{data.bio}</Typography>
					</Typography>
					<Typography variant="h5">
						Email:<Typography paragraph>{data.created_by.email}</Typography>
					</Typography>
					<Typography variant="h5">
						size:<Typography paragraph>{data.size}</Typography>
					</Typography>
					<Typography variant="h5">
						Price range:
						<Typography paragraph>
							{data.price_from} - {data.price_to}
						</Typography>
					</Typography>
					<Typography variant="h5">
						Show/Hide Price:
						<Switch
							// onChange={showHidePriceSave}
							onChange={showHidePriceSave}
							name="price_show"
							checked={data.price_show}
						/>
					</Typography>
					<Typography variant="h5">
						Hospitality & Production Rider:
						<Typography paragraph>
							{data.hospitality_and_production_rider && data.hospitality_and_production_rider.url !== '' && (
								<a
									href={data.hospitality_and_production_rider ? data.hospitality_and_production_rider.url : ''}
									target="_blank"
									rel="noopener noreferrer"
								>
									File
								</a>
							)}
						</Typography>
					</Typography>
					<Typography variant="h5">
						State:<Typography paragraph>{data.state ? data.state.title : ''}</Typography>
					</Typography>
					<Typography variant="h5">
						Rating:<Typography paragraph>{data.rating}</Typography>
					</Typography>
					<Typography variant="h5">
						Willing to travel to:
						<Typography paragraph>
							{data.willing_to_travel
								.map((place: any) => {
									return place.title;
								})
								.join(' - ')}
						</Typography>
					</Typography>
					<Typography variant="h5">
						Genres:
						<Typography paragraph>
							{data.genres
								.map((genre: any) => {
									return genre.title;
								})
								.join(' - ')}
						</Typography>
					</Typography>
					<Typography variant="h5">
						Preferred gig types:
						<Typography paragraph>
							{data.preferred_gig_types
								.map((type: any) => {
									return type.title;
								})
								.join(' - ')}
						</Typography>
					</Typography>
					<Typography variant="h5">
						Featured Songs:
						<Typography paragraph>
							{data.featured_songs.map((song: any) => {
								return (
									<a key={song.name} href={song.url}>
										{song.name}
										{' , '}
									</a>
								);
							})}
						</Typography>
					</Typography>
					<Typography variant="h5">
						Social Links:
						<Typography paragraph>
							{data.social_links.map((link: any) => {
								return (
									<a key={link.type} href={link.url} target="_blank" rel="noopener noreferrer">
										{link.type}
										{' , '}
									</a>
								);
							})}
						</Typography>
					</Typography>
					<Typography variant="h5">
						Promo Code:<Typography paragraph>{data.promo_code}</Typography>
					</Typography>

					<Grid container item xs={12} md={12}>
						<TextField //(4)
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="meta_title"
							label="Metadata Title"
							name="meta_title"
							value={metadataModel.meta_title}
							onChange={e => handleChange('meta_title', e.target.value)}
						/>
					</Grid>

					<Grid container item xs={12} md={12}>
						<TextField //(5)
							id="meta_description"
							name="meta_description"
							required
							fullWidth
							multiline
							label="Metadata Description"
							variant="outlined"
							value={metadataModel.meta_description}
							rows="5"
							onChange={e => handleChange('meta_description', e.target.value)}
						/>
					</Grid>
					<Grid container item xs={12} md={12}>
						<Box
							display="flex"
							justifyContent="flex-end"
							alignItems="center"
							paddingRight="15px"
							marginTop="15px"
							height="110px"
						>
							<div style={{ paddingRight: '10px', color: 'rgba(0, 0, 0, 0.54)' }}>Metadata Image:</div>

							<input type="file" accept="image/*" onChange={e => handleChange('meta_image', e.target.files)} />
							<Avatar src={metaImagePreview || metadataModel?.meta_image_display_url} alt="" />
						</Box>
					</Grid>

					<Grid container item xs={12} md={12}>
						{' '}
						{/*6*/}
						<Button variant="contained" color="primary" onClick={handleMetadataSubmit} className={classes.submit}>
							Submit
							{disabled && <CircularProgress color="primary" size={20} className={classes.buttonProgress} />}
						</Button>
					</Grid>
				</Box>
			) : (
				<> </>
			)}
			<SimpleDialog open={open} onClose={() => setOpen(false)} message={message} />
		</Box>
	);
}

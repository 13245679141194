import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SimpleDialog from '../../basic/dialoges/simpleDialog';
import { StateContext } from '../../generalFunctions/context';
import { setHeaders } from '../../generalFunctions/requestheaders';

const Page: React.FC = () => {
	const history = useHistory();
	const { storeData } = useContext(StateContext);
	const [profile, setProfile] = useState({
		photo: { thumbnail: '' },
		fname: '',
		lname: '',
		username: '',
		email: '',
		organization_name: '',
		events_to: '',
		events_from: '',
		phone_number: '',
		booker_type: { type: '' },
		promo_code: ''
	});
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState('');
	const { id } = useParams();

	useEffect(() => {
		axios
			.get(process.env.REACT_APP_API_URL + `users/show/${id}`, {
				headers: setHeaders(storeData.Token)
			})
			.then(response => {
				setProfile(response.data.item);
			})
			.catch((e: any) => {
				setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
				setOpen(true);
			});
	}, [id, storeData.Token]);

	const handleBackButtonClick = () => {
		history.push('/approve_booker');
	};

	return (
		<Box paddingLeft="10px">
			<Box>
				<IconButton onClick={handleBackButtonClick} aria-label="previous page">
					<KeyboardArrowLeft />
				</IconButton>
			</Box>
			{profile ? (
				<Box paddingLeft="20px" paddingBottom="20px">
					<CardHeader
						avatar={
							<Avatar src={profile.photo ? profile.photo.thumbnail : ''}>{profile.fname[0] + profile.lname[0]}</Avatar>
						}
						title={profile.fname}
						subheader={profile.lname}
					/>
					<Typography variant="h5">
						User Name:<Typography paragraph>{profile.username}</Typography>
					</Typography>
					<Typography variant="h5">
						Email:<Typography paragraph>{profile.email}</Typography>
					</Typography>
					<Typography variant="h5">
						Organization or Business Name:<Typography paragraph>{profile.organization_name}</Typography>
					</Typography>
					<Typography variant="h5">
						Events From:<Typography paragraph>{profile.events_from}</Typography>
					</Typography>
					<Typography variant="h5">
						Events To:<Typography paragraph>{profile.events_to}</Typography>
					</Typography>
					<Typography variant="h5">
						Phone Number:<Typography paragraph>{profile.phone_number}</Typography>
					</Typography>
					<Typography variant="h5">
						Booker Type:<Typography paragraph>{profile.booker_type ? profile.booker_type.type : ''}</Typography>
					</Typography>
					<Typography variant="h5">
						Promo Code:<Typography paragraph>{profile.promo_code}</Typography>
					</Typography>
				</Box>
			) : (
				<> </>
			)}
			<SimpleDialog open={open} onClose={() => setOpen(false)} message={message} />
		</Box>
	);
};

export default Page;

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import SimpleDialog from '../basic/dialoges/simpleDialog';
import { InputLabel, Select, FormControl, CircularProgress, Box, Grid, Avatar } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import MainMenu from '../MainMenu';
import { setHeaders } from '../generalFunctions/requestheaders';
import { StateContext } from '../generalFunctions/context';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
	form: {
		marginTop: theme.spacing(1),
		width: '100%' // Fix IE 11 issue.
	},
	paper: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		marginTop: theme.spacing(8)
	},
	submit: {
		margin: '0 auto',
		padding: '11px',
		minWidth: '200px'
	},
	footer: {
		background: '#f7f7f7',
		textAlign: 'center',
		padding: '30px',
		marginTop: '15%',
		'& div': {
			textAlign: 'center',
			justifyContent: 'center'
		}
	},
	gridform: {
		margin: '0 auto',
		'& div': {
			marginBottom: '0'
		}
	},
	termsconditions: {
		marginTop: '15px',
		'& span': {
			padding: '0',
			paddingRight: '5px'
		}
	},
	fileinput: {
		marginTop: '15px',
		marginLeft: '6px'
	},
	textcenter: {
		textAlign: 'center'
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	validateError: {
		color: 'red',
		'font-size': '0.75rem'
	},

	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -15,
		marginLeft: -12
	}
}));

export default function Page() {
	const classes = useStyles();

	const [values, setValues] = useState({
		title: '',
		metadata_title: '', //(1)
		slug: '',
		status: 'PUBLISHED',
		author: '',
		publish_date: moment()
			.format('YYYY-MM-DD')
			.toString(),
		summary: '',
		metadata_description: '', //(2)
		image: ''
	});

	const [descriptionContent, setDescriptionContent] = React.useState('');
	const [displayTitle, setDisplayTitle] = React.useState('Add Blog');
	const [message, setMessage] = React.useState('Done');
	const [open, setOpen] = React.useState(false);
	const [disabled, setSubmitDisabled] = React.useState(false);
	const { storeData } = useContext(StateContext);
	const history = useHistory();
	const [loading, setLoading] = useState(false);

	const { id } = useParams();

	const [validateError, setValidateErrors] = useState({
		title: { message: '', isError: id ? false : true },
		slug: { message: '', isError: id ? false : true },
		author: { message: '', isError: id ? false : true },
		status: { message: '', isError: false },
		publish_date: { message: '', isError: false },
		summary: { message: '', isError: id ? false : true },
		metadata_title: { message: '', isError: id ? false : true }, //(3)
		metadata_description: { message: '', isError: id ? false : true }, //(4)
		isError: false
	});

	const [photo, setPhoto] = useState<{ file: any; imagePreviewUrl: any }>();

	const submitForm = async () => {
		console.log('values: ', validateError);
		setValidateErrors({ ...validateError, isError: true });
		if (
			validateError.title.isError ||
			validateError.slug.isError ||
			validateError.status.isError ||
			validateError.publish_date.isError ||
			validateError.author.isError ||
			validateError.summary.isError ||
			validateError.metadata_title.isError || // (5)
			validateError.metadata_description.isError // (6)
		) {
			return false;
		} else {
			setValidateErrors({ ...validateError, isError: false });
			setSubmitDisabled(true);
		}

		let methodName = 'blogs';
		if (id) {
			methodName = `blogs/update/${id}`;
		}

		console.log('Values: ', values);
		let headerPhotoUrl = values.image ? values.image : '';
		if (photo?.file) {
			headerPhotoUrl = await uploadHeaderfile(photo?.file);
			values.image = headerPhotoUrl;
			console.log('headerPhotoUrl: ', headerPhotoUrl);
		}

		let reqValues = {
			title: values.title,
			slug: values.slug,
			metadata_title: values.metadata_title, //(7)
			status: values.status,
			author: values.author,
			publish_date: values.publish_date,
			description: descriptionContent,
			summary: values.summary,
			metadata_description: values.metadata_description, //(8)
			image: values.image
		};

		axios
			.post(process.env.REACT_APP_API_URL + methodName, reqValues, {
				headers: setHeaders(storeData.Token)
			})
			.then(response => {
				console.log('response: ', response);
				if (response.status == 200) {
					setSubmitDisabled(false);
					history.push('/blogs');
				} else {
					setSubmitDisabled(false);
					setMessage('Error: ' + response.data.message);
					setOpen(true);
				}
			})
			.catch(err => {
				console.log('err: ', err);
				setSubmitDisabled(false);
				setMessage('Error: ' + err.response.data.message);
				setOpen(true);
			});

		console.log('headerPhotoUrl: ', headerPhotoUrl);
	};

	const handleDateChange = (date: any) => {
		setValues({ ...values, publish_date: date });
		if (!date) {
			setValidateErrors({ ...validateError, publish_date: { message: '', isError: true } });
		} else {
			setValidateErrors({ ...validateError, publish_date: { message: '', isError: false } });
		}
	};

	const handleImageUploadBefore = (files: any, info: any, uploadHandler: any) => {
		// uploadHandler is a function
		console.log('handleImageUploadBefore: ', files, info);

		const bodyFormData = new FormData();
		bodyFormData.append('media', files[0]);
		axios
			.post(process.env.REACT_APP_API_URL + `media/upload`, bodyFormData, {
				headers: setHeaders(storeData.Token)
			})
			.then(res => {
				console.log('file Upload res: ', res);

				let response = {
					result: [
						{
							url: res.data.item.url,
							name: 'ImagemTest',
							size: '500'
						}
					]
				};

				uploadHandler(response);
			})
			.catch(e => {
				console.log('Error: ', e);
				setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
			});
	};

	const handleDescriptionChange = (id: string, value: any) => {
		setDescriptionContent(value);
		//setValues({ ...values, description: value });
		/*if (!value) {
                setValidateErrors({ ...validateError, description: { message: '', isError: true } });
            } else {
                setValidateErrors({ ...validateError, description: { message: '', isError: false } });
            }*/
	};

	const toSeoUrl = (pageTitle: string) => {
		return pageTitle
			.toString() // Convert to string
			.normalize('NFD') // Change diacritics
			.replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
			.replace(/\s+/g, '-') // Change whitespace to dashes
			.toLowerCase() // Change to lowercase
			.replace(/&/g, '-and-') // Replace ampersand
			.replace(/[^a-z0-9\-]/g, '') // Remove anything that is not a letter, number or dash
			.replace(/-+/g, '-'); // Remove duplicate dashes
		// .replace(/^-*/, '') // Remove starting dashes
		// .replace(/-*$/, ''); // Remove trailing dashes
	};

	const toSeoUrlPerma = (pageTitle: string) => {
		return pageTitle
			.toString() // Convert to string
			.normalize('NFD') // Change diacritics
			.replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
			.replace(/\s+/g, '-') // Change whitespace to dashes
			.toLowerCase() // Change to lowercase
			.replace(/&/g, '-and-') // Replace ampersand
			.replace(/[^a-z0-9\-]/g, '') // Remove anything that is not a letter, number or dash
			.replace(/-+/g, '-'); // Remove duplicate dashes
	};

	const handleChange = (id: string, value: any) => {
		switch (id) {
			case 'title':
				setValues({ ...values, title: value, slug: toSeoUrl(value), metadata_title: value });
				if (!value) {
					setValidateErrors({ ...validateError, title: { message: '', isError: true } });
				} else {
					setValidateErrors({
						...validateError,
						title: { message: '', isError: false },
						slug: { message: '', isError: false },
						metadata_title: { message: '', isError: false }
					});
				}
				break;

			case 'slug':
				setValues({ ...values, slug: toSeoUrlPerma(value) });
				if (!value) {
					setValidateErrors({ ...validateError, slug: { message: '', isError: true } });
				} else {
					setValidateErrors({ ...validateError, slug: { message: '', isError: false } });
				}
				break;

			case 'author':
				setValues({ ...values, author: value });
				if (!value) {
					setValidateErrors({ ...validateError, author: { message: '', isError: true } });
				} else {
					setValidateErrors({ ...validateError, author: { message: '', isError: false } });
				}
				break;

			case 'status':
				setValues({ ...values, status: value });
				if (!value) {
					setValidateErrors({ ...validateError, status: { message: '', isError: true } });
				} else {
					setValidateErrors({ ...validateError, status: { message: '', isError: false } });
				}
				break;

			case 'summary':
				setValues({ ...values, summary: value, metadata_description: value });
				{
					/*12*/
				}
				if (!value || value.length > 300) {
					if (value.length > 300) {
						setValidateErrors({
							...validateError,
							summary: { message: 'Add summary upto 300 characters!', isError: true }
						});
					} else {
						setValidateErrors({ ...validateError, summary: { message: '', isError: true } });
					}
				} else {
					setValidateErrors({
						...validateError,
						summary: { message: '', isError: false },
						metadata_description: { message: '', isError: false }
					});
				}
				break;

			case 'metadata_title':
				{
					/*9*/
				}
				setValues({ ...values, metadata_title: value });
				if (!value) {
					setValidateErrors({ ...validateError, metadata_title: { message: '', isError: true } });
				} else {
					setValidateErrors({ ...validateError, metadata_title: { message: '', isError: false } });
				}
				break;

			case 'metadata_description':
				{
					/*11*/
				}
				setValues({ ...values, metadata_description: value });
				if (!value) {
					setValidateErrors({ ...validateError, metadata_description: { message: '', isError: true } });
				} else {
					setValidateErrors({ ...validateError, metadata_description: { message: '', isError: false } });
				}
				break;
		}
	};

	const handlePaste = (e: any, cleanData: any, maxCharCount: any) => {
		return cleanData;
	};

	// const uploadHeaderfile

	const uploadHeaderfile = async (file: any) => {
		// uploadHandler is a function
		console.log('handleImageUploadBefore: ', file);
		const bodyFormData = new FormData();
		bodyFormData.append('media', file);
		let res = await axios.post(process.env.REACT_APP_API_URL + `media/upload`, bodyFormData, {
			headers: setHeaders(storeData.Token)
		});
		// .then(res => {
		// 	console.log('file Upload res: ', res);

		// 	//return res.data.item.url;
		// })
		// .catch(e => {
		// 	console.log('Error: ', e);
		// 	setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
		// });

		return res.data.item.url;
	};

	const filechange = (e: any) => {
		const reader = new FileReader();
		const file = e.target.files[0];
		reader.onloadend = () => {
			setPhoto({
				file,
				imagePreviewUrl: reader.result
			});
		};
		reader.readAsDataURL(file);
	};

	useEffect(() => {
		console.log('useEffect: ', id);
		if (id) {
			setLoading(true);

			setDisplayTitle('Edit Blog');
			axios
				.get(process.env.REACT_APP_API_URL + `blogs/get_single/${id}`, {
					headers: setHeaders(storeData.Token)
				})
				.then(response => {
					setLoading(false);
					let temp_publish_date = moment(response.data.item.publish_date).local();
					response.data.item.publish_date = temp_publish_date;
					setValues(response.data.item);
					setDescriptionContent(response.data.item.description);
				})
				.catch(e => {
					setLoading(false);
					setMessage(`Error: ${e.response.data.message ? e.response.data.message : e.response.data.message}`);
					setOpen(true);
				});
		}
	}, [id, storeData.Token, setValues]);
	return (
		<>
			<MainMenu />

			{loading && (
				<Grid container spacing={3}>
					<Grid item xs={12} style={{ textAlign: 'center' }}>
						<CircularProgress />
					</Grid>
				</Grid>
			)}

			<Box display="flex" flexDirection="column">
				<CssBaseline />
				<div className={classes.paper}>
					<Typography component="h1" variant="h4">
						{displayTitle}
					</Typography>
					<form className={classes.form} noValidate>
						<Grid container spacing={1} item xs={12} md={10} className={classes.gridform}>
							<Grid container item xs={12} md={12}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="title"
									label="Title"
									name="title"
									value={values.title}
									error={validateError.title.isError && validateError.isError}
									onChange={e => handleChange('title', e.target.value)}
								/>
							</Grid>
							<Grid container item xs={12} md={12}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="slug"
									label="Perma Link"
									name="slug"
									value={values.slug}
									error={validateError.slug.isError && validateError.isError}
									onChange={e => handleChange('slug', e.target.value)}
								/>
							</Grid>
							<Grid container item xs={12} md={4}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="author"
									label="Author"
									name="author"
									value={values.author}
									error={validateError.author.isError && validateError.isError}
									onChange={e => handleChange('author', e.target.value)}
								/>
							</Grid>
							<Grid container item xs={12} md={4}>
								<FormControl
									variant="outlined"
									className={classes.formControl}
									style={{ minWidth: '100%', marginTop: '16px' }}
								>
									<InputLabel htmlFor="outlined-age-native-simple">Status *</InputLabel>
									<Select
										native
										required
										value={values.status}
										error={validateError.status.isError && validateError.isError}
										onChange={e => handleChange('status', e.target.value)}
										label="Organization Type"
										inputProps={{
											name: 'status',
											id: 'outlined-age-native-simple'
										}}
									>
										<option aria-label="None" value="" />
										<option value="PUBLISHED">Published</option>
										<option value="DRAFT">Draft</option>
									</Select>
								</FormControl>
							</Grid>
							<Grid container item xs={12} md={4}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<Grid container justify="space-around">
										<KeyboardDatePicker
											disableToolbar
											variant="inline"
											format="MM/dd/yyyy"
											margin="normal"
											id="date-picker-inline"
											label="Date picker inline"
											value={values.publish_date}
											onChange={handleDateChange}
											KeyboardButtonProps={{
												'aria-label': 'change date'
											}}
										/>
									</Grid>
								</MuiPickersUtilsProvider>
							</Grid>

							<Grid container item xs={12} md={12}>
								<Box
									display="flex"
									justifyContent="flex-end"
									alignItems="center"
									paddingRight="15px"
									marginTop="15px"
									height="110px"
								>
									<input type="file" accept="image/*" onChange={filechange} />
									<Avatar src={photo?.imagePreviewUrl ? photo.imagePreviewUrl : values.image} alt="" />
								</Box>
							</Grid>
							<Grid container item xs={12} md={12}>
								<TextField
									id="summary"
									name="summary"
									multiline
									label="Summary"
									variant="outlined"
									rows="5"
									defaultValue={values.summary}
									helperText={validateError.summary.isError && validateError.isError && validateError.summary.message}
									error={validateError.summary.isError && validateError.isError}
									onChange={e => handleChange('summary', e.target.value)}
									fullWidth
									color="secondary"
								/>
							</Grid>
							<Grid container item xs={12} md={12}>
								<SunEditor
									name="description"
									height="350"
									setContents={descriptionContent}
									onChange={e => handleDescriptionChange('description', e)}
									onImageUploadBefore={handleImageUploadBefore}
									onPaste={handlePaste}
									//setDefaultStyle="color: #fff; font-size: 10px;"
									setOptions={{
										maxWidth: '100%',
										iframe: false,
										height: 200,
										buttonList: [
											['undo', 'redo'],
											['formatBlock'],
											['bold', 'underline', 'italic', 'removeFormat'],
											['link'],
											['list'],
											['showBlocks', 'codeView'],
											['image', 'video'], // Or Array of button list, eg. [['font', 'align'], ['image']]
											['fullScreen']
										]
										// Other option
									}}
								/>
							</Grid>

							<Grid container item xs={12} md={12}>
								<TextField //(13)
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="metadata_title"
									label="Metadata Title"
									name="metadata_title"
									value={values.metadata_title}
									error={validateError.metadata_title.isError && validateError.isError}
									onChange={e => handleChange('metadata_title', e.target.value)}
								/>
							</Grid>

							<Grid container item xs={12} md={12}>
								<TextField //(14)
									id="metadata_description"
									name="metadata_description"
									multiline
									label="Metadata Description"
									variant="outlined"
									rows="5"
									defaultValue={values.metadata_description}
									helperText={
										validateError.metadata_description.isError &&
										validateError.isError &&
										validateError.metadata_description.message
									}
									error={validateError.metadata_description.isError && validateError.isError}
									onChange={e => handleChange('metadata_description', e.target.value)}
									fullWidth
									color="secondary"
								/>
							</Grid>

							<Grid container item xs={12} md={12} className={classes.textcenter}>
								<Button
									variant="contained"
									color="primary"
									disabled={disabled}
									className={classes.submit}
									onClick={submitForm}
								>
									Submit
									{disabled && <CircularProgress color="primary" size={30} className={classes.buttonProgress} />}
								</Button>
							</Grid>
						</Grid>
					</form>
				</div>
				<SimpleDialog open={open} onClose={() => setOpen(false)} message={message} />
			</Box>
		</>
	);
}

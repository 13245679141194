import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import NewReleasesOutlinedIcon from '@material-ui/icons/NewReleasesOutlined';
import React, { useState } from 'react';
import { theme } from '../../../theme/index';
import Dialoge from '../dialoges/deleteDialoge';
import { useStyles } from './notificationTable';

export interface ITable {
	rows: Array<{
		id: string;
		data: {
			from: string;
			event: string;
			to: string;
			seen: boolean;
			review: string;
			rating: string;
		};
	}>;
	total: number;
	pagedata: (pagenum: number) => void;
	page: number;
	deletee: (id: string) => void;
}

interface ITablePaginationActionsProps {
	count: number;
	page: number;
	rowsPerPage: number;
	onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: ITablePaginationActionsProps) {
	const { count, page, rowsPerPage, onChangePage } = props;

	const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, 0);
	};

	const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, page - 1);
	};

	const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, page + 1);
	};

	const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box flexShrink="0" marginLeft={theme.spacing(7)}>
			<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
				{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

export default function CustomizedTables(props: ITable) {
	const classes = useStyles();

	const { rows, total, pagedata, page, deletee } = props;
	const [open, setOpen] = useState(false);
	const [id, setId] = useState('');

	const dele = (action: string, idRow: string) => {
		setOpen(false);
		if (action === 'delete') {
			deletee(idRow);
		}
	};

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		pagedata(newPage + 1);
	};

	return (
		<Box marginTop="10px" width="100%">
			<Table aria-label="customized table">
				<TableHead>
					<TableRow>
						<TableCell align="left">From</TableCell>
						<TableCell align="left">To</TableCell>
						<TableCell align="left">Event</TableCell>
						<TableCell align="left">Review</TableCell>
						<TableCell align="left">Rating</TableCell>
						<TableCell align="right">Controls</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map(row => {
						return (
							<TableRow key={row.id}>
								<TableCell align="left" component="th" scope="row">
									{!row.data.seen ? (
										<NewReleasesOutlinedIcon color="primary" fontSize="small" onClick={() => console.log('check')} />
									) : null}
									{row.data.from}
								</TableCell>
								<TableCell align="left" component="th" scope="row">
									{row.data.to}
								</TableCell>
								<TableCell align="left" component="th" scope="row">
									{row.data.event}
								</TableCell>
								<TableCell align="left" component="th" scope="row">
									{row.data.review}
								</TableCell>
								<TableCell align="left" component="th" scope="row">
									{row.data.rating}
								</TableCell>
								<TableCell align="right">
									<Button
										variant="contained"
										className={classes.controls}
										onClick={() => {
											setOpen(true);
											setId(row.id);
										}}
									>
										Delete
									</Button>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[]}
							count={total}
							rowsPerPage={10}
							page={page}
							SelectProps={{
								inputProps: { 'aria-label': 'rows per page' },
								native: true
							}}
							onChangePage={handleChangePage}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Table>
			<Dialoge open={open} onClose={dele} id={id} />
		</Box>
	);
}

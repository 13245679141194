export const setHeaders = (Token: string | null) => {
	const fcmToken = localStorage.getItem('FCM_Admin_Token');
	if (Token) {
		return {
			'Content-Type': 'application/json',
			Token,
			'X-Requested-With': 'XMLHttpRequest',
			'Push-Token': fcmToken ? fcmToken : ''
		};
	}
	return {
		'Content-Type': 'application/json',
		'Push-Token': fcmToken ? fcmToken : ''
	};
};

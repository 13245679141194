import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import React, { useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { messaging } from './component/generalFunctions/firebase/firebase';

import Home from './component/docs/home';
import About from './component/docs/about';
import Benefits from './component/docs/benefits';
import FAQs from './component/docs/faqs';
import HowItWorks from './component/docs/HowItWorks';
import Quotes from './component/docs/quotes';
import TalentBenefits from './component/docs/talentBenefits';
import TermsAndConditions from './component/docs/tremsAndConditions';
import { IAuth, initializeData, StateContext } from './component/generalFunctions/context';
import Login from './component/login';
import MainMenu from './component/MainMenu';
import Conversation from './component/messages/Conversations';
import NotificationsPage from './component/notification/notificationpage';
import Notifications from './component/notification/notifications';
import Payments from './component/payments/payments';
import PaymentsPage from './component/payments/paymentsPage';
import BandPage from './component/profiles/bandProfiles/bandPage';
import Band from './component/profiles/bandProfiles/bandProfile';
import BookerPage from './component/profiles/bookerProfiles/bookerPage';
import Booker from './component/profiles/bookerProfiles/bookerProfile';
import AgentPage from './component/profiles/agentProfiles/agentPage';
import Agent from './component/profiles/agentProfiles/agentProfile';
import Requests from './component/requests/manageRequests';
import RequestPage from './component/requests/requestpage';
import BookerReviews from './component/review/bookerreview';
import RepsyReviews from './component/review/repsyreviews';
import TalentReviews from './component/review/talentreviews';
import Tutorial from './component/staticFields/bookerTutorial';
import BookerTypes from './component/staticFields/bookerTypes';
import Testimonials from './component/testimonials/manageTestimonial';
import Genres from './component/staticFields/genres';
import Gigs from './component/staticFields/gig';
import States from './component/staticFields/States';
import VenueDescriptions from './component/staticFields/venues';
import Tracking from './component/support/personalTracking';
import Support from './component/support/support';
import Blogs from './component/blogs/blogs';
import AddBlog from './component/blogs/add-blog';
import Suggestions from './component/suggestions/suggestions';

import { theme } from './theme/index';

const App: React.FC = () => {
	const [storeData, setStoreData] = useState<IAuth>(initializeData);
	Notification.requestPermission(status => {
		messaging
			.getToken()
			.then((token: any) => {
				localStorage.setItem('FCM_Admin_Token', token);
			})
			.catch((e: any) => {
				console.log(e);
			});
	});

	return (
		<StateContext.Provider value={{ setStoreData, storeData }}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{storeData.Authenticated ? (
					<>
						<MainMenu />
						<Box
							component="main"
							width={'79%'}
							marginTop={'13vh'}
							marginLeft={'20%'}
							height={'85vh'}
							paddingTop="10px"
							paddingLeft="15px"
						>
							<Route path="/" exact>
								<Redirect to={{ pathname: '/approve_band' }} />
							</Route>
							<Route path="/blogs/add-blog" exact component={AddBlog} />
							<Route path="/blogs/edit-blog/:id" exact component={AddBlog} />
							<Route path="/blogs" exact component={Blogs} />
							<Route path="/requests" exact component={Requests} />
							<Route path="/requests/:id" exact component={RequestPage} />
							<Route path="/requests/:id/messages" exact component={Conversation} />
							<Route path="/payments" exact component={Payments} />
							<Route path="/payments/:id" exact component={PaymentsPage} />
							<Route path="/notifications" exact component={Notifications} />
							<Route path="/notifications/:id" exact component={NotificationsPage} />
							<Route path="/approve_band" exact component={Band} />
							<Route path="/testimonial" exact component={Testimonials} />
							<Route path="/approve_band/:id" exact component={BandPage} />
							<Route path="/approve_booker" exact component={Booker} />
							<Route path="/approve_booker/:id" exact component={BookerPage} />
							<Route path="/approve_agent" exact component={Agent} />
							<Route path="/approve_agent/:id" exact component={AgentPage} />
							<Route path="/bookerreviews" exact component={BookerReviews} />
							<Route path="/talentreviews" exact component={TalentReviews} />
							<Route path="/repsyreviews" exact component={RepsyReviews} />
							<Route path="/genres" exact component={Genres} />
							<Route path="/booker_types" exact component={BookerTypes} />
							<Route path="/states" exact component={States} />
							<Route path="/gigs" exact component={Gigs} />
							<Route path="/venues" exact component={VenueDescriptions} />
							<Route path="/tutorial" exact component={Tutorial} />
							<Route path="/home" exact component={Home} />
							<Route path="/about" exact component={About} />
							<Route path="/faqs" exact component={FAQs} />
							<Route path="/booker_benefits" exact component={Benefits} />
							<Route path="/talent_benefits" exact component={TalentBenefits} />
							<Route path="/quotes" exact component={Quotes} />
							<Route path="/terms" exact component={TermsAndConditions} />
							<Route path="/tracking" exact component={Tracking} />
							<Route path="/howitworks" exact component={HowItWorks} />
							<Route path="/support" exact component={Support} />
							<Route path="/suggestions" exact component={Suggestions} />
						</Box>
					</>
				) : (
					<Login />
				)}
			</ThemeProvider>
		</StateContext.Provider>
	);
};

export default App;

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import SimpleDialog from './basic/dialoges/simpleDialog';
import { StateContext, storeState } from './generalFunctions/context';
import { setHeaders } from './generalFunctions/requestheaders';

const useStyles = makeStyles(theme => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
		margin: theme.spacing(1)
	},
	form: {
		marginTop: theme.spacing(1),
		width: '100%' // Fix IE 11 issue.
	},
	paper: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		marginTop: theme.spacing(8)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	}
}));

const LogIn: React.FC = () => {
	const classes = useStyles();
	const { setStoreData } = useContext(StateContext);
	const [values, setValues] = useState({
		isAdmin: true,
		login: '',
		password: ''
	});
	const [message, setMessage] = React.useState('Done');
	const [open, setOpen] = React.useState(false);

	const handleSignin = () => {
		axios
			.post(process.env.REACT_APP_API_URL + 'users/login', values, {
				headers: setHeaders(null)
			})
			.then(response => {
				const auth = {
					Authenticated: true,
					Token: response.data.item.api_token,
					Selected: 'approve_band',
					user: response.data.item
				};
				setStoreData(auth);
				storeState(auth);
				return axios({
					method: 'POST',
					url: process.env.REACT_APP_API_URL + 'notification/push-token',
					headers: setHeaders(response.data.item.api_token)
				});
			})
			.then(res => {
				const channel = new BroadcastChannel('sw-messages');
				channel.addEventListener('message', event => {
					console.log('Received', event.data);
					if (event.data.data.type === 'NEW_MESSAGE') {
						console.log('mesh 3arf a3ml eh??!!!!!!');
					}
				});
			})
			.catch(err => {
				setMessage('Error: ' + err);
				setOpen(true);
			});
	};
	const handleChange = (id: string, value: string) => {
		switch (id) {
			case 'email':
				setValues({ ...values, login: value });
				break;
			case 'Password':
				setValues({ ...values, password: value });
				break;
		}
	};
	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Sign in
				</Typography>
				<form className={classes.form} noValidate>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email Address"
						name="email"
						autoComplete="email"
						value={values.login}
						onChange={e => handleChange('email', e.target.value)}
						autoFocus
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						value={values.password}
						onChange={e => handleChange('Password', e.target.value)}
					/>
					<Button fullWidth variant="contained" color="primary" className={classes.submit} onClick={handleSignin}>
						Sign In
					</Button>
					<Grid container>
						<Grid item xs>
							<Link href="#" variant="body2">
								Forgot password?
							</Link>
						</Grid>
					</Grid>
				</form>
			</div>
			<SimpleDialog open={open} onClose={() => setOpen(false)} message={message} />
		</Container>
	);
};

export default LogIn;

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import SimpleDialog from '../basic/dialoges/simpleDialog';
import Table from '../basic/tables/tutorialTable';
import { StateContext } from '../generalFunctions/context';
import { setHeaders } from '../generalFunctions/requestheaders';
import { useStyles } from './bookerTypes';

const TITLE_MAX_LENGTH = 30;
const SUB_TITLE_MAX_LENGTH = 30;
const TEXT_MAX_LENGTH = 255;

const States: React.FC = () => {
	const classes = useStyles();

	const { storeData } = useContext(StateContext);
	const [listall, setListall] = useState(false);
	const [operation, setOperation] = useState(false);
	const [values, setValues] = useState({
		id: '',
		photo: { id: '', url: '' },
		role: '',
		subtitle: '',
		text: '',
		title: ''
	});
	const [error, setError] = useState({
		photo: { disable: true },
		subtitle: { error: false, message: '', disable: true },
		text: { error: false, message: '', disable: true },
		title: { error: false, message: '', disable: true }
	});
	const [count, setCount] = useState({ talent: 0, booker: 0 });
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState('Done');
	const [data, setData] = useState([
		{ id: '0', title: '', subtitle: '', role: '', text: '', photo: { id: '', url: '' } }
	]);
	const [tableContent, setTableContent] = useState([{ id: '', data: '' }]);
	const [photo, setPhoto] = useState<{ file: any; imagePreviewUrl: any }>();
	const [submitrequest, setSubmitRequest] = useState('post');
	const [buttonLabel, setButtonLabel] = useState('create');

	useEffect(() => {
		axios
			.get(process.env.REACT_APP_API_URL + 'welcome-screen/list?role=talent', {
				headers: setHeaders(storeData.Token)
			})
			.then(response => {
				setData(response.data.items);
				const talent = response.data.items.map((item: any) => {
					return { id: item.id, data: item.title + ' talent' };
				});
				setTableContent(talent);
				setCount({ talent: response.data.items.length, booker: 0 });
				return axios.get(process.env.REACT_APP_API_URL + 'welcome-screen/list?role=booker', {
					headers: setHeaders(storeData.Token)
				});
			})
			.then(res => {
				setData(prevState => prevState.concat(res.data.items));
				const booker = res.data.items.map((item: any) => {
					return { id: item.id, data: item.title + ' booker' };
				});
				setTableContent(prevState => prevState.concat(booker));
				setCount(prevState => {
					return { talent: prevState.talent, booker: res.data.items.length };
				});
			})
			.catch(e => {
				setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
				handleClickOpen();
			});
	}, [listall, storeData.Token]);

	const handladd = () => {
		setOperation(true);
		setButtonLabel('create');
		setSubmitRequest('post');
		const newval = {
			id: '',
			photo: { id: '', url: '' },
			role: '',
			subtitle: '',
			text: '',
			title: ''
		};
		setValues(newval);
		setPhoto({ file: '', imagePreviewUrl: '' });
		setError({
			photo: { disable: true },
			subtitle: { error: false, message: '', disable: true },
			text: { error: false, message: '', disable: true },
			title: { error: false, message: '', disable: true }
		});
		setTimeout(() => {
			window.scrollTo({
				behavior: 'smooth',
				top: 10000
			});
		}, 1);
	};

	const handleEdite = (id: string) => {
		setOperation(true);
		setButtonLabel('edit');
		setSubmitRequest('put');
		const index = data.findIndex(item => item.id === id);
		const newval = {
			id: data[index].id,
			photo: { id: data[index].photo.id, url: data[index].photo.url },
			role: data[index].role,
			subtitle: data[index].subtitle,
			text: data[index].text,
			title: data[index].title
		};
		setValues(newval);
		setPhoto({ file: '', imagePreviewUrl: '' });
		setError({
			photo: { disable: false },
			subtitle: { error: false, message: '', disable: false },
			text: { error: false, message: '', disable: false },
			title: { error: false, message: '', disable: false }
		});
		setTimeout(() => {
			window.scrollTo({
				behavior: 'smooth',
				top: 10000
			});
		}, 1);
	};
	const handleChange = (value: string, type: string) => {
		let newvalues = { ...values };
		let newerror = { ...error };
		switch (type) {
			case 'title':
				newvalues = { ...newvalues, title: value };
				setValues(newvalues);
				newerror = { ...newerror, title: { error: false, message: '', disable: false } };
				setError(newerror);
				if (value.length > TITLE_MAX_LENGTH) {
					newerror = {
						...newerror,
						title: {
							disable: true,
							error: true,
							message: `value is too long (can't be more than ${TITLE_MAX_LENGTH} characters)`
						}
					};
					setError(newerror);
				} else if (value.trim().length === 0) {
					newerror = {
						...newerror,
						title: {
							disable: true,
							error: true,
							message: 'required'
						}
					};
					setError(newerror);
				}
				break;
			case 'sub':
				newvalues = { ...newvalues, subtitle: value };
				setValues(newvalues);
				newerror = { ...newerror, subtitle: { error: false, message: '', disable: false } };
				setError(newerror);
				if (value.length > SUB_TITLE_MAX_LENGTH) {
					newerror = {
						...newerror,
						subtitle: {
							disable: true,
							error: true,
							message: `value is too long (can't be more than ${SUB_TITLE_MAX_LENGTH} characters)`
						}
					};
					setError(newerror);
				} else if (value.trim().length === 0) {
					newerror = {
						...newerror,
						subtitle: { error: true, message: 'required', disable: true }
					};
					setError(newerror);
				}
				break;
			case 'text':
				newvalues = { ...newvalues, text: value };
				setValues(newvalues);
				newerror = { ...newerror, text: { error: false, message: '', disable: false } };
				setError(newerror);
				if (value.length > TEXT_MAX_LENGTH) {
					newerror = {
						...newerror,
						text: {
							disable: true,
							error: true,
							message: `value is too long (can't be more than ${TEXT_MAX_LENGTH} characters)`
						}
					};
					setError(newerror);
				} else if (value.trim().length === 0) {
					newerror = {
						...newerror,
						text: { error: true, message: 'required', disable: true }
					};
					setError(newerror);
				}
				break;
		}
	};

	const handleClick = () => {
		if (submitrequest === 'post') {
			const bodyFormData = new FormData();
			bodyFormData.append('media', photo?.file);
			axios
				.post(process.env.REACT_APP_API_URL + `media/upload`, bodyFormData, {
					headers: setHeaders(storeData.Token)
				})
				.then(res => {
					const requestData = {
						photo: { id: res.data.item.id },
						role: values.role,
						subtitle: values.subtitle,
						text: values.text,
						title: values.title
					};
					return axios.post(process.env.REACT_APP_API_URL + `welcome-screen`, requestData, {
						headers: setHeaders(storeData.Token)
					});
				})
				.then(response => {
					setListall(!listall);
				})
				.catch(e => {
					setMessage('Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message);
					handleClickOpen();
				});
		} else {
			if (photo && photo.file) {
				const bodyFormData = new FormData();
				bodyFormData.append('media', photo.file);
				axios
					.post(process.env.REACT_APP_API_URL + `media/upload`, bodyFormData, {
						headers: setHeaders(storeData.Token)
					})
					.then(res => {
						const requestData = {
							photo: { id: res.data.item.id },
							role: values.role,
							subtitle: values.subtitle,
							text: values.text,
							title: values.title
						};
						return axios.put(process.env.REACT_APP_API_URL + `welcome-screen/${values.id}`, requestData, {
							headers: setHeaders(storeData.Token)
						});
					})
					.then(response => {
						setListall(!listall);
					})
					.catch(e => {
						setMessage(
							'Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message
						);
						handleClickOpen();
					});
			} else {
				const requestData = {
					photo: { id: values.photo.id },
					role: values.role,
					subtitle: values.subtitle,
					text: values.text,
					title: values.title
				};
				axios
					.put(process.env.REACT_APP_API_URL + `welcome-screen/${values.id}`, requestData, {
						headers: setHeaders(storeData.Token)
					})
					.then(response => {
						setListall(!listall);
					})
					.catch(e => {
						setMessage(
							'Error: ' + e.response.data.user_message ? e.response.data.user_message : e.response.data.message
						);
						handleClickOpen();
					});
			}
		}
		setOperation(false);
		setValues({ title: '', id: '', subtitle: '', photo: { id: '', url: '' }, text: '', role: '' });
		setTimeout(() => {
			window.scrollTo({
				behavior: 'smooth',
				top: 0
			});
		}, 100);
	};
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const filechange = (e: any) => {
		const reader = new FileReader();
		const file = e.target.files[0];
		reader.onloadend = () => {
			setPhoto({
				file,
				imagePreviewUrl: reader.result
			});
		};
		reader.readAsDataURL(file);
		setError({ ...error, photo: { ...error.photo, disable: false } });
	};

	const handleCancel = () => {
		setOperation(false);
		setValues({ title: '', id: '', subtitle: '', photo: { id: '', url: '' }, text: '', role: '' });
		setError({
			photo: { disable: true },
			subtitle: { error: false, message: '', disable: true },
			text: { error: false, message: '', disable: true },
			title: { error: false, message: '', disable: true }
		});
		setTimeout(() => {
			window.scrollTo({
				behavior: 'smooth',
				top: 0
			});
		}, 100);
	};

	return (
		<Box>
			<Typography align="center" variant="h4">
				Tutorials
			</Typography>
			<Box display="flex" justifyContent="flex-end">
				<Button color="secondary" onClick={handladd} disabled={count.talent === 3 && count.booker === 3}>
					<AddCircleIcon fontSize="large" />
				</Button>
			</Box>
			<Table title="Tutorials" rows={tableContent} handleEdit={handleEdite} />
			{operation ? (
				<>
					<Divider />
					<Box
						display="flex"
						justifyContent="flex-end"
						alignItems="center"
						paddingRight="15px"
						marginTop="15px"
						height="110px"
					>
						<input type="file" accept="image/*" onChange={filechange} />
						<Avatar src={photo?.imagePreviewUrl ? photo.imagePreviewUrl : values.photo.url} alt="" />
					</Box>
					<Box display={'flex'} flexDirection={'column'} alignItems={'center'} paddingTop={'10px'} paddingBottom="5px">
						<TextField
							id="Title"
							label="Title"
							variant="outlined"
							placeholder="Title"
							error={error.title.error}
							helperText={error.title.message}
							value={values.title}
							onChange={e => handleChange(e.target.value, 'title')}
							fullWidth
							required
						/>
						<TextField
							id="SubTitle"
							label="SubTitle"
							variant="outlined"
							placeholder="SubTitle"
							error={error.subtitle.error}
							helperText={error.subtitle.message}
							value={values.subtitle}
							onChange={e => handleChange(e.target.value, 'sub')}
							fullWidth
							required
						/>
						<TextField
							id="Text"
							multiline
							label="Text"
							variant="outlined"
							placeholder="Text"
							rows="3"
							error={error.text.error}
							helperText={error.text.message}
							value={values.text}
							onChange={e => handleChange(e.target.value, 'text')}
							fullWidth
							required
						/>
						{submitrequest === 'post' && (
							<Box display="flex" flexDirection="column" width="100%" alignItems="flex-start">
								<InputLabel htmlFor="university">Role *</InputLabel>
								<Select
									value={values.role === 'BOOKER' ? 1 : values.role === 'TALENT' ? 0 : ''}
									fullWidth={true}
									variant="outlined"
									MenuProps={{
										anchorOrigin: {
											horizontal: 'left',
											vertical: 'bottom'
										},
										getContentAnchorEl: null,
										transformOrigin: {
											horizontal: 'left',
											vertical: 'top'
										}
									}}
									onChange={e => {
										if (Boolean(e.target.value)) {
											setValues({ ...values, role: 'BOOKER' });
										} else {
											setValues({ ...values, role: 'TALENT' });
										}
									}}
								>
									{count.booker < 3 && (
										<MenuItem className={classes.menu} key="1" value={1}>
											Booker
										</MenuItem>
									)}
									{count.talent < 3 && (
										<MenuItem className={classes.menu} key="2" value={0}>
											Talent
										</MenuItem>
									)}
								</Select>
							</Box>
						)}
						<Box display="flex">
							<Button
								variant="contained"
								color="primary"
								disabled={error.title.disable || error.subtitle.disable || error.text.disable || error.photo.disable}
								onClick={handleClick}
							>
								{buttonLabel}
							</Button>
							<Button variant="contained" onClick={handleCancel}>
								cancel
							</Button>
						</Box>
					</Box>
				</>
			) : (
				<></>
			)}
			<SimpleDialog open={open} onClose={handleClose} message={message} />
		</Box>
	);
};

export default States;
